import React, { FC } from 'react'

import { SecondaryButton, LinkButton } from '@components'
import screenActionStrings from './model.en.json'
import styles from './index.module.scss'
import { ReactComponent as LogoT01C } from './assets/logo_t01c.svg'
import { ReactComponent as LogoT01A } from './assets/logo_t01a.svg'

type TransferUserActionButtonsProps = {
  displayLogin?: boolean
  displayAkiliAssist?: boolean
  displayPasswordReset?: boolean
  displayOtcSupport?: boolean
  context: string
  brandVersion?: string
  passwordResetURL?: string
}

const TransferUserActionButtons: FC<TransferUserActionButtonsProps> = ({
  displayLogin = false,
  displayAkiliAssist = false,
  displayPasswordReset = false,
  displayOtcSupport = false,
  context,
  brandVersion = 'v1',
  passwordResetURL = '/password-reset?client_name_id=webreg'
}) => {
  const screenId = context || 'transferUserActionButtons'
  const brandStyleName = `brand_${brandVersion}`

  const handleTransferUser = (type): void => {
    if (type === 'AkiliAssist') {
      window.open(process.env.REACT_APP_ENDEAVORRX_ASSIST, '_blank')
    }
  }

  const renderPasswordResetContent = () => {
    const { passwordResetLink } = screenActionStrings

    if (!displayPasswordReset) return null
    return (
      <div className={styles.transfer_password_reset_button_container}>
        <SecondaryButton
          context="transfer_button"
          caption={passwordResetLink}
          className={`${styles.secondary_link} ${
            styles[brandVersion === 'v1' ? 'brand_v1' : 'brand_v2']
          }`}
          onClick={() => {
            window.open(passwordResetURL, '_self')
          }}
        />
      </div>
    )
  }

  const renderLoginContent = () => {
    const { login } = screenActionStrings

    if (!displayLogin) return null
    return (
      <div className={styles.transfer_login_button_container}>
        <SecondaryButton
          context={screenId}
          caption={login}
          onClick={() => window.open('/login', '_self')}
          className={styles.secondary_link}
        />
      </div>
    )
  }

  const renderAkiliAssistContent = () => {
    const { akiliAssistInfo, akiliAssistLink, assistInfo } = screenActionStrings

    if (displayAkiliAssist) {
      return (
        <div className={styles.transfer_akili_assist_button_container}>
          {(displayOtcSupport && <LogoT01C />) || (
            <div className={styles.header}>{assistInfo}</div>
          )}
          <div>
            {displayOtcSupport && <span>{akiliAssistInfo}</span>}
            <LinkButton
              context="akili_assist"
              className={`${styles[brandStyleName]} ${
                !displayOtcSupport && styles.nounderline
              }`}
              caption={akiliAssistLink}
              onClick={() => handleTransferUser('AkiliAssist')}
              isLoading={false}
            />
          </div>
        </div>
      )
    }

    return null
  }

  const renderOtcContent = () => {
    const { otcSupportInfo } = screenActionStrings

    if (displayOtcSupport) {
      return (
        <div className={styles.transfer_otc_support_button_container}>
          <LogoT01A />
          <div className={styles.transfer_otc_support_button_text_container}>
            <span>{otcSupportInfo}</span>
            <span className={styles.contact}>
              {process.env.REACT_APP_ENDEAVOROTC_SUPPORT}
            </span>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <div className={styles.transfer_buttons_container}>
      {renderPasswordResetContent()}
      {renderLoginContent()}
      {renderAkiliAssistContent()}
      {renderOtcContent()}
    </div>
  )
}

export default TransferUserActionButtons
