import { createAkiliApi } from '../../utils/api/api-methods'
import normalizer from './get-careteam-normalized'
import {
  FormatedSuccessType,
  FormatedErrorType
} from './get-careteam-normalized-types'

/* 401	- unauthorized */
type ErrorResponse = FormatedErrorType

type SuccessResponse = FormatedSuccessType

const url = '/careteam?extra_fields=avatar'
const name = 'getCareteam'

const getCareteamAPI = (
  authToken: string
): Promise<ErrorResponse | SuccessResponse> => {
  return createAkiliApi(authToken, url, 'GET', name, null, normalizer)
}

export default getCareteamAPI
