import moment from 'moment'
import {
  RawCareteamErrorType,
  RawCareteamSuccessType,
  FormatedErrorType,
  FormatedSuccessType,
  FormatedCareteamType,
  RawCaregiverType,
  FormatedCaregiverType,
  RawTreatmentsType,
  FormatedTreatmentType,
  FilteredRawTreatmentsType
} from './get-careteam-normalized-types'

const getT01Treatments = (
  data: RawTreatmentsType,
  product: string
): FormatedTreatmentType => {
  const rawTreatments = data

  const activatedTreatments = rawTreatments.filter(
    (treatment) => treatment.productCode === product
  ) as FilteredRawTreatmentsType

  const formatedTreatments = activatedTreatments.map((d) => {
    let active = false

    const now = moment().toDate()
    const startOfToday = moment(now).startOf('day').toDate()
    const endOfTreatment = moment(d.treatmentEndDate).startOf('day').toDate()

    // active rx has a positive number of days diff, expired rx has negative number of days diff
    // 0 days diff means rx is on last day of treatment
    active = moment(endOfTreatment).diff(startOfToday, 'days') >= 0

    return { ...d, isActive: active }
  }) as FormatedTreatmentType

  return formatedTreatments
}

const getCaregiversData = (
  caregivers: RawCaregiverType
): FormatedCaregiverType => {
  return caregivers.map((item) => {
    const image = item.avatarImageBase64
      ? {
          contentType: item.avatarContentType, // eslint-disable-line indent
          baseUri: item.avatarImageBase64 // eslint-disable-line indent
        } // eslint-disable-line indent
      : null
    return {
      profileId: item.profileId,
      email: item.email,
      status: item.status,
      superpower: item.superpower || '',
      firstName: item.givenName || '',
      lastName: item.familyName || '',
      relationshipToPatient: item.relationshipToPatient || '',
      fullName: item.givenName
        ? `${item.givenName} ${item.familyName}`
        : item.email,
      avatarImage: image,
      createdAtUtc: item.createdAtUtc
    }
  })
}

const getCareTeamNormalized = (
  apiResponse: RawCareteamErrorType | RawCareteamSuccessType
): FormatedSuccessType | FormatedErrorType => {
  if (!apiResponse.success) {
    return { ...apiResponse }
  }

  const result = {
    success: apiResponse.success,
    status: apiResponse.status,
    requestParams: apiResponse.requestParams,
    responsePayload: { careTeams: [] as FormatedCareteamType }
  }

  const {
    responsePayload: { careTeams: apiCareTeams }
  } = apiResponse as RawCareteamSuccessType

  if (apiCareTeams && apiCareTeams.length > 0) {
    apiCareTeams.map((item) => {
      const careTeam = {
        careTeamId: item.careTeamId,
        patient: {
          patientId: item.patient.profileId,
          firstName: item.patient.givenName || '',
          lastName: item.patient.familyName || '',
          nickname: item.patient.nickname || '',
          birthdate: item.patient.birthdate || '',
          gender: item.patient.gender || '',
          displayName: item.patient.nickname || item.patient.givenName,
          avatarImage: item.patient.avatarImageBase64
            ? {
                contentType: item.patient.avatarContentType, // eslint-disable-line indent
                baseUri: item.patient.avatarImageBase64 // eslint-disable-line indent
              } // eslint-disable-line indent
            : null,
          treatments: {
            [process.env.REACT_APP_TO1_PRODUCT_CODE]: [
              ...getT01Treatments(
                item.patient.treatments,
                process.env.REACT_APP_TO1_PRODUCT_CODE
              )
            ]
          }
        },
        caregivers: getCaregiversData(item.caregivers)
      }

      result.responsePayload.careTeams.push(careTeam)
    })
  }
  return result
}

export default getCareTeamNormalized
