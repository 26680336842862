import React, { FC, MouseEventHandler } from 'react'
import styles from './styles.module.scss'

type SecondaryButtonProps = {
  caption: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  className?: string
  context?: string
  isLoading?: boolean
  automationButtonId?: string
  automationSpinnerButtonId?: string
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
  caption,
  onClick,
  isLoading = false,
  disabled = false,
  className = '',
  context,
  automationButtonId,
  automationSpinnerButtonId
}) => {
  const id = context || 'secondaryButton'

  return (
    <button
      id={id}
      className={[styles.secondaryButton, className].join(' ')}
      data-testid="secondary-button"
      type="button"
      disabled={isLoading || disabled}
      onClick={onClick}
    >
      {isLoading && (
        <div className={styles.buttonContentLoading}>
          <div
            data-aid={automationSpinnerButtonId}
            id={`${id}-spinner`}
            className={styles.loadingIcon}
          />
        </div>
      )}
      {!isLoading && (
        <span data-aid={automationButtonId} id={`${id}-label`}>
          {caption}
        </span>
      )}
    </button>
  )
}

export default SecondaryButton
