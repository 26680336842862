import React, { FC, useState, useEffect } from 'react'
import { useLocation, RouteComponentProps } from 'react-router-dom'

import {
  Layout,
  Loading,
  Iconography,
  TransferUserActionButtons
} from '@components'
import { Analytics } from '@utils'

import styles from './styles.module.scss'
import _model from './model.en.json'
import _configs, { ScreensMessagesContent } from './configs'

const configs = _configs as ScreensMessagesContent
type Localization = {
  [status: string]: {
    header: string
    subheader: string
  }
}

const model = _model as Localization

const LegacyVerifyEmailStatus: FC<RouteComponentProps> = () => {
  const screenId = 'legacy-email-verification-screen'
  const SCREEN_NAME = 'Legacy Verify Email'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(true)
  const [uiConfigs, setUiConfigs] = useState({
    hasIcon: false,
    success: false,
    hasAkiliAssist: false,
    hasLogin: false
  })
  const [uiText, setUiText] = useState({
    header: '',
    subheader: ''
  })

  /** ************* */
  /* UI Content */
  const location = useLocation()

  const getPageVariant = (success: string, message: string) => {
    let type = ''

    if (message === configs.uRLQueryStrings.success) {
      type = configs.typesOfScreens.success
    } else if (message === configs.uRLQueryStrings.expired) {
      type = configs.typesOfScreens.expired
    } else if (message === configs.uRLQueryStrings.already) {
      type = configs.typesOfScreens.already
    } else if (message === configs.uRLQueryStrings.used_once) {
      type = configs.typesOfScreens.used_once
    } else {
      type = configs.typesOfScreens.generic
    }
    return type
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const successStatus = query.get('success') as string
    const queryMessage = query.get('message') as string

    const pageVariant = getPageVariant(successStatus, queryMessage)

    setUiConfigs(configs.status[pageVariant])
    setUiText(model[pageVariant])
    setLoading(false)

    Analytics.recordEvent(configs.analytics.viewed_event, null, 'MIXPANEL', {
      name: SCREEN_NAME
    })

    Analytics.recordEvent(
      configs.analytics.error_event.name,
      null,
      'MIXPANEL',
      {
        [configs.analytics.error_event.segment.success]:
          successStatus === 'true',
        [configs.analytics.error_event.segment.code]: null,
        [configs.analytics.error_event.segment.message]: queryMessage
      }
    )
  })

  const renderErrorDetails = () => {
    const query = new URLSearchParams(location.search)
    const errorMessage = query.get('message') as string

    if (!errorMessage) {
      return null
    }

    const { header, subheader } = model.error_details

    return (
      <div className={styles.errorDetails}>
        <div className={styles.title}>&#x1F41E; {header}</div>
        {errorMessage && <div>{`${subheader} ${errorMessage}`}</div>}
      </div>
    )
  }

  const renderBody = () => {
    return (
      <div className={styles.body}>
        {uiConfigs.hasIcon && (
          <div className={styles.icon_container}>
            <Iconography
              width={64}
              height={64}
              name={uiConfigs.success ? 'checkInCircle' : 'warning'}
              fill="dark"
            />
          </div>
        )}
        <span className={styles.header}>{uiText.header}</span>

        <span className={styles.subheader}>{uiText.subheader}</span>
        {(uiConfigs.hasLogin || uiConfigs.hasAkiliAssist) && (
          <TransferUserActionButtons
            context={screenId}
            displayLogin={uiConfigs.hasLogin}
            displayAkiliAssist={uiConfigs.hasAkiliAssist}
          />
        )}
        {!uiConfigs.success && renderErrorDetails()}
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter>
      <div className={styles.container}>
        {isLoading && <Loading fullScreen />}
        {!isLoading && renderBody()}
      </div>
    </Layout>
  )
}

export default LegacyVerifyEmailStatus
