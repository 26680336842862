import React, { FC } from 'react'

import Iconography from '../Iconography'
import styles from './styles.module.scss'

type CheckboxInputProps = {
  label?: string
  context?: string
  dataId?: string
  checked: boolean
  handleChange: (value: boolean, name: string) => void
  automationFieldId?: string
  error?: boolean
  errorMessage?: string
}

const CheckboxInput: FC<CheckboxInputProps> = ({
  label = '',
  context,
  dataId,
  checked,
  handleChange,
  automationFieldId,
  error = false,
  errorMessage = ''
}) => {
  const id = context || 'checkbox'

  const hasError = () => {
    return error && !checked ? 'error' : ''
  }

  const checkedClassName = checked ? styles.checked : ''

  const handleValueChanged = () => {
    return handleChange(!checked, dataId || '')
  }

  return (
    <div className="field">
      <div
        id={`${id}-option-container`}
        className={`${styles.checkbox_container} ${hasError()}`}
        role="checkbox"
        aria-checked="false"
        onClick={handleValueChanged}
        onKeyPress={handleValueChanged}
        tabIndex={0}
      >
        <div
          id={`${id}-input-container`}
          className={`${styles.checkbox_component} ${checkedClassName}`}
        >
          <input
            id={`${id}-input`}
            type="checkbox"
            checked={checked}
            data-aid={automationFieldId}
            readOnly
            disabled
            hidden
          />
          {checked ? (
            <Iconography
              context={`${id}-checked`}
              name="check"
              fill="white"
              width={14}
              height={14}
            />
          ) : null}
        </div>
        <span id={`${id}-input-label`} className={styles.label}>
          {label}
        </span>
      </div>
      {error && errorMessage && (
        <div id={`${id}-error-label`} className={styles.error_message}>
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default CheckboxInput
