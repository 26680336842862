import React, { FC } from 'react'
import styles from './styles.module.scss'

type RadioButtonInputProps = {
  label?: string
  context: string
  dataId: string
  checked: boolean
  handleChange: (value: boolean, name: string) => void
  automationFieldId?: string
  error?: boolean
}

const RadioButtonInput: FC<RadioButtonInputProps> = ({
  label = '',
  context,
  dataId,
  checked,
  handleChange,
  automationFieldId,
  error = false
}) => {
  const id = context

  const hasError = () => {
    return error && !checked ? 'error' : ''
  }

  const checkedClassName = checked ? styles.checked : ''

  const handleValueChanged = () => {
    return handleChange(!checked, dataId)
  }

  return (
    <div
      id={`${id}-option-container`}
      className={`field ${styles.radio_container} ${hasError()}`}
      role="radio"
      aria-checked="false"
      onClick={handleValueChanged}
      onKeyPress={handleValueChanged}
      tabIndex={0}
    >
      <div
        id={`${id}-input-container`}
        className={`${styles.radio_component} ${checkedClassName}`}
      >
        <input
          id={`${id}-input`}
          type="radio"
          checked={checked}
          data-aid={automationFieldId}
          readOnly
          disabled
          hidden
        />
        {checked ? (
          <div id={`${id}-selected-icon`} className={styles.bullet} />
        ) : null}
      </div>
      <span id={`${id}-label`} className={styles.label}>
        {label}
      </span>
    </div>
  )
}

export default RadioButtonInput
