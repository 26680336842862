type Configs = {
  analytics: {
    viewed: string
    clicked: string
  }
}

const configs = {
  analytics: {
    viewed: 'Screen_viewed',
    clicked: 'DownloadApp_clicked'
  }
} as Configs

export default configs
