type Config = {
  form: {
    analytics: {
      viewed: string
      submitted: string
      focus: string
    }
    sections: {
      context: string
      questions: {
        context: string
        id: string
        type: string
        analytics: {
          focus: string
          submit: string
        }
        choices?: {
          value: string
        }[]
      }[]
    }[]
  }
}

const config = {
  form: {
    analytics: {
      viewed: 'Screen_viewed',
      submitted: 'Demographics_submitted',
      focus: 'Demographics_interacted'
    },
    sections: [
      {
        context: 'patient',
        questions: [
          {
            id: 'patient_ethnicity',
            context: 'patientEthnicity',
            type: 'radio',
            analytics: {
              focus: 'ethnicity',
              submit: 'ethnicity_answered'
            },
            choices: [
              {
                value: 'HISP'
              },
              {
                value: 'NHISP'
              },
              {
                value: ''
              }
            ]
          },
          {
            id: 'patient_race',
            context: 'patientRace',
            type: 'checkbox',
            analytics: {
              focus: 'race',
              submit: 'race_answered'
            },
            choices: [
              {
                value: 'AMINAN'
              },
              {
                value: 'ASIAN'
              },
              {
                value: 'BLACK'
              },
              {
                value: 'HAWPI'
              },
              {
                value: 'WHITE'
              }
            ]
          }
        ]
      },
      {
        context: 'household',
        questions: [
          {
            id: 'household_income',
            context: 'householdIncome',
            type: 'currency',
            analytics: {
              focus: 'income',
              submit: 'income_answered'
            }
          },
          {
            id: 'household_size',
            context: 'householdSize',
            type: 'number',
            analytics: {
              focus: 'household_size',
              submit: 'household_size_answered'
            }
          }
        ]
      }
    ]
  }
} as Config

export default config
