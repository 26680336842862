import React, { FC } from 'react'

import { Footer } from '@components'
import styles from './styles.module.scss'

type LayoutProps = {
  context?: string
  leftContent?: JSX.Element | string | null
  children?: JSX.Element | string | null
  hasFooter?: boolean
  placeholderFooterURLs?: boolean
  brandVersion?: string
}

const Layout: FC<LayoutProps> = ({
  leftContent,
  children,
  hasFooter,
  placeholderFooterURLs = false,
  context,
  brandVersion = 'v1'
}) => {
  const id = context || 'layout'
  const brandStyleName = `brand_${brandVersion}`

  return (
    <div id={`${id}-container`} className={styles.page_layout}>
      <div className={styles.page_container}>
        <div
          id={`${id}-left-container`}
          className={`${styles.side} ${styles.left_side} ${styles[brandStyleName]}`}
        >
          <div className={styles.left_content}>{leftContent}</div>
        </div>
        <div
          id={`${id}-right-container`}
          className={`${styles.side} ${styles.right_side}`}
        >
          <div className={styles.right_content}>{children}</div>
          {hasFooter && (
            <Footer
              placeholderURLs={placeholderFooterURLs}
              brandVersion={brandVersion}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Layout
