import React, { FC } from 'react'
import { activationCodeRegex } from '@utils'

type ActivationCodeProps = {
  context?: string
  placeholder: string
  value: string
  onChange: (value: string) => void
  onFocus: () => void
  automationFieldId?: string
}

const ActivationCodeInput: FC<ActivationCodeProps> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  context,
  automationFieldId
}) => {
  const id = context || 'activationCode'

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const nextValue = activationCodeRegex(val).toUpperCase().slice(0, 25)

    onChange(nextValue)
  }

  return (
    <input
      data-aid={automationFieldId}
      id={`${id}-input`}
      type="text"
      placeholder={placeholder}
      value={activationCodeRegex(value)}
      onChange={handleChange}
      maxLength={29}
      onFocus={onFocus}
    />
  )
}

export default ActivationCodeInput
