/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react'

import { RadioButtonInput } from '@components'
import styles from './styles.module.scss'

type SingleSelectProps = {
  label?: string
  context?: string
  dataId?: string
  options: string[]
  values: string[]
  selections: string[]
  handleChange: (value?: string, id?: string) => void
  error?: boolean
  errorMessage?: string
  onFocus: () => void
  automationFieldId?: string
}

const SingleSelect: FC<SingleSelectProps> = ({
  label = '',
  context,
  dataId,
  options,
  values = [],
  selections = [],
  handleChange,
  onFocus,
  automationFieldId,
  error,
  errorMessage
}) => {
  const id = context || 'singleSelect'

  const hasError = () => {
    return error ? 'error' : ''
  }

  const handleSelectionChange = (selected: boolean, val: string) => {
    return handleChange(selected ? val : undefined, dataId || '')
  }

  const renderOptions = () => {
    return (
      <div className={`${styles.options_container}`} onFocus={onFocus}>
        {options.map((item, index) => {
          const value = values[index]
          return (
            <div key={index} className={styles.check_option}>
              <RadioButtonInput
                context={value.toLowerCase() || 'na'}
                dataId={value}
                label={item}
                checked={selections.includes(value)}
                handleChange={handleSelectionChange}
                error={error}
                automationFieldId={[
                  automationFieldId,
                  'Option',
                  index + 1
                ].join('')}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      id={`${id}-toggle-container`}
      className={`field ${styles.check_group_container} ${hasError()}`}
    >
      <label htmlFor="input">{label}</label>
      {renderOptions()}
      {error && errorMessage && (
        <div id={`${id}-error`} className={styles.error_message}>
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default SingleSelect
