import { mapKeys } from 'lodash'

export const setValue = function (storage, key, value) {
  storage.setItem(key, JSON.stringify(value))
}

export const getValue = function getValue(storage, key) {
  const value = storage.getItem(key)

  if (!value) {
    return null
  }

  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const removeValue = function removeValue(storage, key) {
  storage.removeItem(key)
}

export const setWithObjectProperties = function (storage, paramObject) {
  mapKeys(paramObject, (value, key) => {
    setValue(storage, key, value)
  })
}
