import React, { FC } from 'react'
import styles from './styles.module.scss'

export enum ParagraphContentTypeEnum {
  String,
  NewLine,
  Link
}

type ContentType = {
  type: ParagraphContentTypeEnum
  context?: string
  content?: string
  href?: string
  target?: string
  className?: string
  automationLegendId?: string
}

type ParagraphProps = {
  content: string | ContentType[]
  context?: string
  automationLegendId?: string
}

const Content = (contentType: ContentType, index: number) => {
  let result
  switch (contentType.type) {
    case ParagraphContentTypeEnum.Link:
      result = (
        <a
          data-aid={contentType.automationLegendId}
          key={index}
          id={contentType.context || 'link'}
          href={contentType.href}
          target={contentType.target}
          className={contentType.className}
        >
          {contentType.content}
        </a>
      )
      break
    case ParagraphContentTypeEnum.NewLine:
      result = <br key={index} className={contentType.className} />
      break
    default:
      result = (
        <span
          data-aid={contentType.automationLegendId}
          key={index}
          id={contentType.context || 'paragraph-content'}
          className={contentType.className}
        >
          {contentType.content}
        </span>
      )
      break
  }
  return result
}

const Paragraph: FC<ParagraphProps> = ({
  content,
  context,
  automationLegendId
}) => {
  const id = context || 'paragraph'

  return (
    <div className="field">
      <div
        id={`${id}-paragraph-container`}
        className={styles.paragraph_container}
      >
        <span
          data-aid={automationLegendId}
          id={`${id}-paragraph`}
          className={styles.paragraph}
        >
          {typeof content === 'string'
            ? content
            : content.map((type, index) => Content(type, index))}
        </span>
      </div>
    </div>
  )
}

export default Paragraph
