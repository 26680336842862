/* eslint-disable react/jsx-props-no-spreading */
import { useAuth0 } from '@auth0/auth0-react'
import React, { Fragment, useEffect, FC, useState } from 'react'
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  RouteComponentProps
} from 'react-router-dom'
import 'semantic-ui-css/semantic.min.css'
import { History } from 'history'
import {
  createInstance,
  OptimizelyProvider,
  setLogger
} from '@optimizely/react-sdk'
import jwt_decode from 'jwt-decode'
import {
  routes,
  useAuthToken,
  SessionStorage,
  SessionStorageKeys,
  Analytics
} from '@utils'

import './App.scss'
import ProtectedRoute from './auth/protected-route'
import { Layout, Loading } from './components'
import {
  ActivateTreatment,
  OnboardingComplete,
  OnboardUser,
  SetupCaregiverProfile,
  SetupPatientProfile,
  EmailVerification,
  DemographicsForm,
  // Auth0Template,
  PasswordReset,
  Logout,
  Login,
  SMSOptIn,
  RouteNotFound,
  InvalidAuthentication,
  UnblockAccountStatus,
  PasswordUpdateStatus,
  LegacyVerifyEmailStatus,
  VerifyEmailOtc
} from './views'

/** ********** */
type ScrollToTopProps = {
  history: History
}
interface IIndexable {
  [key: string]: any
}

const ScrollToTop: FC<ScrollToTopProps> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <Fragment>{children}</Fragment>
}

const ScrollToTopOnRoute = withRouter(ScrollToTop)

/** ********** */
const AppRouter = () => {
  const { logout } = useAuth0()
  const handleInvalidUser = (err?: Error) => {
    if (err && err.message === 'INVALID_USER') {
      logout({
        returnTo: `${window.location.origin}/${routes.login}`
      })
    }
  }

  return (
    <Fragment>
      <BrowserRouter>
        <ScrollToTopOnRoute>
          <Switch>
            <Route
              path="/"
              exact
              render={(props: RouteComponentProps) => (
                <OnboardUser {...props} handleInvalidUser={handleInvalidUser} />
              )}
            />
            <Route
              path="/onboard-user"
              exact
              render={(props: RouteComponentProps) => (
                <OnboardUser {...props} handleInvalidUser={handleInvalidUser} />
              )}
            />
            <Route
              path="/account-unblock"
              exact
              component={UnblockAccountStatus}
            />
            <Route path="/password-reset" exact component={PasswordReset} />
            <Route
              path="/password-update"
              exact
              component={PasswordUpdateStatus}
            />
            <Route path="/logout" exact component={Logout} />
            <Route path="/login" exact component={Login} />
            <Route path="/invalid" exact component={InvalidAuthentication} />
            <Route
              path="/email-verification"
              exact
              component={LegacyVerifyEmailStatus}
            />
            <Route
              path="/email-verification-otc"
              exact
              component={VerifyEmailOtc}
            />
            <Route
              path="/user-email-verification"
              exact
              render={(props: RouteComponentProps) => (
                <EmailVerification
                  {...props}
                  handleInvalidUser={handleInvalidUser}
                />
              )}
            />
            <ProtectedRoute
              path="/setup-caregiver-profile"
              exact
              handleInvalidUser={handleInvalidUser}
              component={SetupCaregiverProfile}
            />
            <ProtectedRoute
              path="/setup-patient-profile"
              exact
              handleInvalidUser={handleInvalidUser}
              component={SetupPatientProfile}
            />
            <ProtectedRoute
              path="/demographics"
              exact
              handleInvalidUser={handleInvalidUser}
              component={DemographicsForm}
            />
            {/* <ProtectedRoute
              path="/Auth0Template"
              exact
              handleInvalidUser={handleInvalidUser}
              component={Auth0Template}
            /> */}
            <ProtectedRoute
              path="/activate-treatment"
              exact
              handleInvalidUser={handleInvalidUser}
              component={ActivateTreatment}
            />
            <ProtectedRoute
              path="/onboarding-complete"
              exact
              handleInvalidUser={handleInvalidUser}
              component={OnboardingComplete}
            />
            <ProtectedRoute
              path="/sms"
              exact
              handleInvalidUser={handleInvalidUser}
              component={SMSOptIn}
            />
            <Route path="*" exact component={RouteNotFound} />
          </Switch>
        </ScrollToTopOnRoute>
      </BrowserRouter>
    </Fragment>
  )
}

/** ********** */
const getExperimentationId = (authToken: string | null) => {
  if (!authToken) return null

  const decodedToken = jwt_decode(authToken)
  const uuid = (decodedToken as IIndexable)[
    'https://my.akili.care/app_metadata'
  ]?.alias?.mp

  return uuid
}

const AuthLoadedApp = () => {
  const stepsFormatForceKey =
    SessionStorageKeys.onboardingCompleteStepsFormatForceFlag
  const reverseDownloadOrderForceKey =
    SessionStorageKeys.onboardingCompleteDownloadOrderForceFlag
  const [forceState, setForceState] = useState({
    [stepsFormatForceKey]: null,
    [reverseDownloadOrderForceKey]: null
  })

  useEffect(() => {
    const stepsFormatForceValue = SessionStorage.get(stepsFormatForceKey)
    const reverseDownloadOrderForceValue = SessionStorage.get(
      reverseDownloadOrderForceKey
    )

    if (
      stepsFormatForceValue !== undefined ||
      reverseDownloadOrderForceValue !== undefined
    ) {
      setForceState({
        ...forceState,
        [stepsFormatForceKey]: stepsFormatForceValue || null,
        [reverseDownloadOrderForceKey]: reverseDownloadOrderForceValue || null
      })
    }

    function storageEventHandler(event) {
      if (
        event.key === stepsFormatForceKey ||
        event.key === reverseDownloadOrderForceKey
      ) {
        setForceState({
          ...forceState,
          [stepsFormatForceKey]:
            SessionStorage.get(stepsFormatForceKey) || null,
          [reverseDownloadOrderForceKey]: SessionStorage.get(
            reverseDownloadOrderForceKey || null
          )
        })
      }
    }
    window.addEventListener('storage', storageEventHandler)
    return () => {
      window.removeEventListener('storage', storageEventHandler)
    }
  }, [])

  const { authToken, tokenPending } = useAuthToken()
  if (tokenPending) {
    return (
      <Layout>
        <Loading fullScreen />
      </Layout>
    )
  }

  const optimizelyUuid = getExperimentationId(authToken)
  if (!optimizelyUuid) {
    return <AppRouter />
  }

  setLogger(null)
  const optimizelyClient = createInstance({
    sdkKey: process.env.REACT_APP_OPTIMIZELY_KEY
  })

  if (process.env.REACT_APP_EXPERIMENTATION_ENABLE_FORCE_FLAG) {
    optimizelyClient.onReady().then(() => {
      const stepsFormatExperimentKey = `${process.env.REACT_APP_EXPERIMENTATION_ONBOARDING_COMPLETE_STEPS_FORMAT}_experiment`
      const stepsFormatForceValue = forceState[stepsFormatForceKey]
      if (
        stepsFormatExperimentKey &&
        optimizelyUuid &&
        stepsFormatForceValue !== undefined
      ) {
        optimizelyClient.setForcedVariation(
          stepsFormatExperimentKey,
          optimizelyUuid,
          stepsFormatForceValue
        )
      }

      const reverseDownloadOrderExperimentKey = `${process.env.REACT_APP_EXPERIMENTATION_ONBOARDING_COMPLETE_APP_ORDER}_experiment`
      const reverseDownloadOrderForceValue =
        forceState[reverseDownloadOrderForceKey]
      if (
        reverseDownloadOrderExperimentKey &&
        optimizelyUuid &&
        reverseDownloadOrderForceValue !== undefined
      ) {
        optimizelyClient.setForcedVariation(
          reverseDownloadOrderExperimentKey,
          optimizelyUuid,
          reverseDownloadOrderForceValue
        )
      }
    })
  }

  return (
    <OptimizelyProvider
      optimizely={optimizelyClient}
      user={{ id: optimizelyUuid }}
    >
      <AppRouter />
    </OptimizelyProvider>
  )
}

/** ********** */
const App: FC = () => {
  const { isLoading } = useAuth0()

  if (isLoading) {
    return (
      <Layout>
        <Loading fullScreen />
      </Layout>
    )
  }

  Analytics.superProperty('product', process.env.REACT_APP_WEB_PRODUCT_CODE)

  return <AuthLoadedApp />
}

export default App
