import React, { FC, useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { useLocation } from 'react-router-dom'

import {
  Layout,
  Header,
  TextInput,
  FloatingButton,
  ErrorBanner
} from '@components'
import { validateFormField, Analytics } from '@utils'
import { passwordResetApi } from '@apiRoutes'
import styles from './styles.module.scss'
import _model from './model.en.json'
import configs from './configs'

const PRODUCT_CODE = process.env.REACT_APP_WEB_PRODUCT_CODE
const TREATMENT_ID = process.env.REACT_APP_TO1_PRODUCT_CODE

type Localization = {
  title: string
  description: string
  form: {
    [field: string]: {
      label: string
      placeholder: string
      placeholderT01A: string
      error: string
      success: string
    }
  }
}

type TextState = {
  value: string
  hasError: boolean
  error: string
}

const model = _model as Localization

const PasswordReset: FC = () => {
  const screenId = 'password-reset-screen'
  const SCREEN_NAME = 'Password Reset'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(false)
  const [screenError, setScreenError] = useState('' as string)
  const [responseSuccess, setResponseSuccess] = useState(false)

  const [appClientId, setAppClientId] = useState(
    process.env.REACT_APP_AUTH0_CLIENT_ID
  )
  const [brandVersion, setBrandVersion] = useState(
    process.env.REACT_APP_BRAND_DEFAULT
  )
  const [clientNameId, setClientNameId] = useState('webreg')

  const location = useLocation()
  useEffect(() => {
    const query = new URLSearchParams(location.search)

    const clientName = query.get('client_name_id')?.toUpperCase() || ''
    setClientNameId(clientName)

    const brandingVersion = process.env[`REACT_APP_BRAND_${clientName}`]

    if (brandingVersion) {
      setBrandVersion(brandingVersion)
    }

    const clientKey = `REACT_APP_AUTH0_CLIENT_ID_${clientName}`
    const clientId =
      process.env[clientKey] || process.env.REACT_APP_AUTH0_CLIENT_ID

    setAppClientId(clientId)
  }, [])

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  /** ************* */
  /* Record the viewed event to mixpanel the first time */
  useEffect(() => {
    Analytics.recordEvent(configs.analytics.viewed, null, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  /** ************* */
  /* Form data handlers */

  const clearError = (
    state: TextState,
    updateState: (state: TextState) => void
  ) => {
    const { hasError } = state

    if (!hasError) return

    updateState({ ...state, hasError: false, error: '' })
  }

  /** ************* */
  const [email, setEmail] = useState({
    value: '',
    hasError: false,
    error: ''
  } as TextState)

  const updateEmail = (val: string): void => {
    setEmail({ ...email, value: val })
  }

  const clearEmailError = (): void => {
    clearError(email, setEmail)
  }

  /** ************* */
  /* Form password reset handlers */

  let validForm = true

  const verifyFormField = (
    name: string,
    state: TextState,
    updateState: (state: TextState) => void
  ): void => {
    const hasValidationError = validateFormField(name, configs, state)
    if (!hasValidationError) return

    validForm = false
    const {
      form: {
        [name]: { error }
      }
    } = model

    const newState = { ...state, hasError: true }
    if (error) {
      newState.error = error
    }
    updateState(newState)
  }

  const validateForm = () => {
    validForm = true
    const fields = [
      {
        name: 'email',
        state: email,
        updateState: setEmail
      }
    ]

    fields.forEach(({ name, state, updateState }) => {
      verifyFormField(name, state, updateState)
    })
  }

  const passwordReset = async () => {
    const data = {
      email: email.value
    }

    const response = await passwordResetApi(data, appClientId as string)
    if (response?.success) {
      setResponseSuccess(true)
      /* Record the submitted event to mixpanel */
      Analytics.recordEvent(configs.analytics.submitted, null, 'MIXPANEL', {
        product: PRODUCT_CODE,
        app_code: TREATMENT_ID
      })
    } else {
      setResponseSuccess(false)
      setScreenError(response.localizedErrorMessage)
      setLoading(false)
    }
  }

  const handleSubmit = () => {
    if (isLoading) {
      return
    }

    setScreenError('')
    setLoading(true)

    validateForm()

    if (!validForm) {
      window.scrollTo(0, 0)
      setLoading(false)
      return
    }

    passwordReset()
  }

  /** ************* */
  /* UI Content */

  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  const renderForm = () => {
    const {
      form: {
        email: {
          label: emailLabel,
          placeholder: emailPlaceholderGeneral,
          placeholderT01A: emailPlaceholderT01A
        }
      }
    } = model
    const emailPlaceholder =
      brandVersion === process.env.REACT_APP_BRAND_T01A
        ? emailPlaceholderT01A
        : emailPlaceholderGeneral
    const {
      form: {
        email: { maxLength: emailMaxLength }
      }
    } = configs

    return (
      <>
        <div className={styles.form}>
          <Form>
            <Form.Group widths="equal">
              <TextInput
                automationFieldId="EmailField"
                automationLabelId="EmailLabel"
                automationIconId="EmailIcon"
                automationValidationId="EmailValidation"
                context="email-reset-password"
                type="email"
                label={emailLabel}
                placeholder={emailPlaceholder}
                maxLength={emailMaxLength}
                value={email.value}
                handleChange={updateEmail}
                error={email.hasError}
                errorMessage={email.error}
                onFocus={clearEmailError}
                brandVersion={brandVersion}
              />
            </Form.Group>
          </Form>
        </div>
        <div className={styles.buttonContainer}>
          <FloatingButton
            automationButtonId="SubmitButton"
            context="submit"
            onClick={handleSubmit}
            isLoading={isLoading}
            brandVersion={brandVersion}
          />
        </div>
      </>
    )
  }

  if (responseSuccess) {
    return (
      <Layout context={screenId} hasFooter brandVersion={brandVersion}>
        <div className={styles.container}>
          <Header
            title={model.title}
            description={model.description}
            automationTitleId="ResetTitle"
            automationLegendId="ResetLegend"
            brandVersion={brandVersion}
            clientNameId={clientNameId}
          />
          <p data-aid="ResetLegend" className={styles.success}>
            {model.form.email.success}
          </p>
        </div>
      </Layout>
    )
  }
  return (
    <Layout context={screenId} hasFooter brandVersion={brandVersion}>
      <div className={styles.container}>
        <Header
          title={model.title}
          description={model.description}
          automationTitleId="ResetTitle"
          automationLegendId="ResetLegend"
          brandVersion={brandVersion}
          clientNameId={clientNameId}
        />
        {renderErrorBanner()}
        {renderForm()}
      </div>
    </Layout>
  )
}

export default PasswordReset
