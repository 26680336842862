import React, { FC, useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import _ from 'lodash'
import { navigateToUrl, routes, Analytics, useAuthToken, Branch } from '@utils'
import { RouteComponentProps } from 'react-router-dom'
import {
  Header,
  MultiSelect,
  SingleSelect,
  TextInput,
  Layout,
  FloatingButton,
  ErrorBanner
} from '@components'
import { getCareteamAPI, saveDemographicsAPI } from '@apiRoutes'
import { HeaderDescriptionTypeEnum } from '../../components/Header'
import styles from './styles.module.scss'
import config from './config'
import strings from './strings.en.json'

type FormType = {
  analytics: {
    viewed: string
    submitted: string
    focus: string
  }
  sections: [FormSectionType]
}
type FormSectionType = {
  title: string
  titleHighlight: string
  context: string
  questions: [FormQuestionType]
}
type FormQuestionType = {
  title: string
  type: string
  context: string
  analytics: {
    focus: string
    submit: string
  }
  id?: string
  placeholder?: string
  choices?: [FormQuestionChoiceType]
}
type FormQuestionChoiceType = {
  type: string
  label: string
  value: string
}
type FormStateType = {
  [field: string]: {
    values: string[]
    hasError: boolean
    error: string
    visible: boolean
  }
}
type SteppedFormState = {
  isStepped: boolean
  currentSection: number
  currentQuestion: number
  transitioning: boolean
}
type DemographicsPayloadType = {
  patient_id?: string
  patient_ethnicity?: string
  patient_race?: string[]
  household_income?: number
  household_size?: number
}

const initializeModel = () => {
  return _.merge(strings, config)
}

const model = initializeModel()
const privacyNoticeUrl = process.env.REACT_APP_AKILI_PP_LINK

const initializeFormState = () => {
  const state: FormStateType = {}
  const form = model.form as FormType
  const sections = form.sections as [FormSectionType]
  sections.map((section: FormSectionType) => {
    section.questions.map((question: FormQuestionType) => {
      if (question.id) {
        state[question.id] = {
          values: [],
          hasError: false,
          error: '',
          visible: true
        }
      }
    })
  })
  return state
}

const initializeFormStepState = () => {
  const isStepped = true
  const state: SteppedFormState = {
    isStepped,
    currentSection: 0,
    currentQuestion: 0,
    transitioning: false
  }
  return state
}

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const DemographicsForm: FC<RouteComponentProps & CustomProps> = ({
  history,
  handleInvalidUser
}) => {
  const screenId = 'demographics-screen'
  const SCREEN_NAME = 'Demographics'

  const [formState, setFormState] = useState(initializeFormState())
  const [formStepState, setFormStepState] = useState(initializeFormStepState())

  const [screenError, setScreenError] = useState('' as string)

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  const showErrorForResponseStatus = (errorMessage: string) => {
    setScreenError(errorMessage || '')
  }

  const [isLoading, setLoading] = useState(false)
  const [{ patientId, patientIdFetching }, setPatient] = useState({
    patientId: '',
    patientIdFetching: false
  })
  const [caregiver, setCaregiver] = useState({ profileId: '' })
  const { authToken, tokenPending } = useAuthToken()

  useEffect(() => {
    if (formStepState.transitioning) {
      setFormStepState({
        ...formStepState,
        transitioning: false
      })
    }

    if (!patientId) {
      const fetchPatientData = async () => {
        if (patientIdFetching || tokenPending || !authToken || patientId) {
          return
        }

        const response = await getCareteamAPI(authToken).catch((e) =>
          handleInvalidUser(e)
        )

        if (!response) {
          return
        }

        if (!response.success) {
          showErrorForResponseStatus(response.localizedErrorMessage)
          return
        }

        const pttId = response.responsePayload.careTeams[0]?.patient?.patientId
        setPatient({
          patientId: pttId || '',
          patientIdFetching: false
        })
        if (
          response.responsePayload.careTeams[0] &&
          response.responsePayload.careTeams[0].caregivers &&
          response.responsePayload.careTeams[0].caregivers.length > 0
        ) {
          setCaregiver({
            profileId:
              response.responsePayload.careTeams[0].caregivers[0].profileId
          })
        }
      }
      fetchPatientData()
    }
  }, [
    tokenPending,
    authToken,
    patientIdFetching,
    patientId,
    formStepState,
    handleInvalidUser,
    setCaregiver
  ])

  useEffect(() => {
    Analytics.recordEvent(model.form.analytics.viewed, authToken, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  const renderHeader = () => {
    const { description } = model
    return (
      <Header
        description={[
          {
            type: HeaderDescriptionTypeEnum.String,
            content: description[0],
            context: 'header-description-start',
            automationLegendId: 'DemographicsLegend1'
          },
          { type: HeaderDescriptionTypeEnum.NewLine },
          { type: HeaderDescriptionTypeEnum.NewLine },
          {
            type: HeaderDescriptionTypeEnum.String,
            content: description[1],
            context: 'header-description-middle',
            automationLegendId: 'DemographicsLegend2'
          },
          {
            type: HeaderDescriptionTypeEnum.Link,
            href: privacyNoticeUrl,
            target: '_blank',
            content: description[2],
            context: 'pn-link',
            automationLegendId: 'PrivacyLegendLink'
          },
          {
            type: HeaderDescriptionTypeEnum.String,
            content: description[3],
            context: 'header-description-end'
          }
        ]}
      />
    )
  }

  const recordFocusAnalytics = (question: FormQuestionType) => {
    Analytics.recordEvent(model.form.analytics.focus, authToken, 'MIXPANEL', {
      question_choice: question.analytics.focus
    })
  }

  const recordSubmittedEvent = (
    payload: DemographicsPayloadType,
    questionChoice = 'all_submitted'
  ) => {
    Analytics.recordEvent(
      model.form.analytics.submitted,
      authToken,
      'MIXPANEL',
      {
        question_choice: questionChoice,
        ethnicity_answered: payload.patient_ethnicity !== undefined,
        race_answered: payload.patient_race !== undefined,
        income_answered: payload.household_income !== undefined,
        household_size_answered: payload.household_size !== undefined
      }
    )
  }

  const recordSubmitAnalytics = (payload: DemographicsPayloadType) => {
    if (formStepState.isStepped) {
      const { currentSection, currentQuestion } = formStepState
      const form = model.form as FormType
      const sections = form.sections as [FormSectionType]
      const section = sections[currentSection]
      const questions = section.questions as [FormQuestionType]
      const question = questions[currentQuestion]
      recordSubmittedEvent(payload, question.analytics.focus)
    } else {
      recordSubmittedEvent(payload)
    }
  }

  const handleRadioUpdate = (val?: string, id?: string): void => {
    if (id) {
      let values: string[] = ['']
      if (val) {
        values = [val]
      }
      setFormState({
        ...formState,
        [id]: { values, hasError: false, error: '', visible: true }
      })
    }
  }

  const handleCheckboxUpdate = (selections: string[], id?: string): void => {
    if (id) {
      setFormState({
        ...formState,
        [id]: { values: selections, hasError: false, error: '', visible: true }
      })
    }
  }

  const handleTextFieldUpdate = (val: string, id?: string): void => {
    if (id) {
      setFormState({
        ...formState,
        [id]: { values: [val], hasError: false, error: '', visible: true }
      })
    }
  }

  const advanceToNextStep = (payload: DemographicsPayloadType) => {
    if (formStepState.isStepped) {
      let { currentSection, currentQuestion } = formStepState
      const form = model.form as FormType
      const sections = form.sections as [FormSectionType]
      const section = sections[currentSection]
      const questions = section.questions as [FormQuestionType]
      currentQuestion += 1
      if (currentQuestion === questions.length) {
        currentSection += 1
        currentQuestion = 0
      }
      if (currentSection < sections.length) {
        setFormStepState({
          ...formStepState,
          currentSection,
          currentQuestion,
          transitioning: true
        })
      } else {
        recordSubmittedEvent(payload) // record a final, `all_submitted` event for Variant B
        Branch.recordEvent('COMPLETE_REGISTRATION', {
          profileId: caregiver.profileId
        })
        navigateToUrl(history, routes.activateTreatment)
      }
    } else {
      Branch.recordEvent('COMPLETE_REGISTRATION', {
        profileId: caregiver.profileId
      })
      navigateToUrl(history, routes.activateTreatment)
    }
  }

  const submitDemographics = async (payload: DemographicsPayloadType) => {
    const response = await saveDemographicsAPI(
      authToken as string,
      payload
    ).catch((e) => handleInvalidUser(e))

    if (!response) {
      return
    }

    setLoading(false)

    if (!response.success) {
      showErrorForResponseStatus(response.localizedErrorMessage)
    } else {
      recordSubmitAnalytics(payload)
      advanceToNextStep(payload)
    }
  }

  const handleSubmit = () => {
    if (isLoading || !authToken || !patientId) {
      return
    }

    setScreenError('')
    setLoading(true)

    const payload: DemographicsPayloadType = { patient_id: patientId }
    const ethnicity: string[] = formState.patient_ethnicity.values
    const race = formState.patient_race.values
    const income = formState.household_income.values
    const size = formState.household_size.values

    if (ethnicity && ethnicity.length > 0) {
      const value = ethnicity[0]
      if (value.length > 0) {
        payload.patient_ethnicity = value
      }
    }
    if (race && race.length > 0) {
      payload.patient_race = race
    }
    if (income && income.length) {
      const value = income[0]
      if (value.trim().length > 0 && !Number.isNaN(Number(value))) {
        payload.household_income = parseInt(value, 10)
      }
    }
    if (size && size.length > 0) {
      const value = size[0]
      if (value.trim().length > 0 && !Number.isNaN(Number(value))) {
        payload.household_size = parseInt(value, 10)
      }
    }

    submitDemographics(payload)
  }

  const renderQuestion = (question: FormQuestionType, index: number) => {
    const questionState = formState[question.id ?? '-']

    if (question.id) {
      if (question.type === 'radio') {
        if (question.choices !== null) {
          const options: string[] = []
          const values: string[] = []
          question.choices?.forEach((choice: FormQuestionChoiceType) => {
            options.push(choice.label)
            values.push(choice.value)
          })
          return (
            <div>
              <div
                id={`${question.context}-label`}
                className={styles.question}
                data-aid={['Demographics', index + 1, 'Legend'].join('')}
              >
                {question.title}
              </div>
              <SingleSelect
                context={question.context}
                dataId={question.id}
                options={options}
                values={values}
                selections={questionState.values}
                handleChange={handleRadioUpdate}
                error={questionState.hasError}
                onFocus={() => {
                  recordFocusAnalytics(question)
                }}
                errorMessage={questionState.error}
                automationFieldId={['Answer', index + 1].join('')}
              />
            </div>
          )
        }
      } else if (question.type === 'checkbox') {
        if (question.choices !== null) {
          const options: string[] = []
          const values: string[] = []
          question.choices?.forEach((choice: FormQuestionChoiceType) => {
            options.push(choice.label)
            values.push(choice.value)
          })
          return (
            <div>
              <div id={`${question.context}-label`} className={styles.question}>
                {question.title}
              </div>
              <MultiSelect
                context={question.context}
                dataId={question.id}
                options={options}
                values={values}
                selections={questionState.values}
                handleChange={handleCheckboxUpdate}
                onFocus={() => {
                  recordFocusAnalytics(question)
                }}
                error={questionState.hasError}
                errorMessage={questionState.error}
                automationFieldId={['Answer', index + 1].join('')}
              />
            </div>
          )
        }
      } else if (question.type === 'number' || question.type === 'currency') {
        let value = ''
        if (questionState.values.length > 0) {
          value = questionState.values[0] as string
        }
        return (
          <div>
            <div id={`${question.context}-label`} className={styles.question}>
              {question.title}
            </div>
            <TextInput
              type={question.type}
              dataId={question.id}
              context={question.context}
              placeholder={question.placeholder ?? ''}
              value={value}
              handleChange={handleTextFieldUpdate}
              error={questionState.hasError}
              errorMessage={questionState.error}
              maxLength={question.type === 'number' ? 2 : 12}
              onFocus={() => {
                recordFocusAnalytics(question)
              }}
              automationFieldId={['Answer', index + 1, 'Field'].join('')}
            />
          </div>
        )
      }
    }

    return null
  }

  const renderQuestions = (section: FormSectionType, index: number) => {
    const questions = section.questions as [FormQuestionType]
    if (formStepState.isStepped) {
      return (
        <div>
          {renderQuestion(questions[formStepState.currentQuestion], index)}
        </div>
      )
    }
    return (
      <div>
        {section.questions.map((question: FormQuestionType) => (
          <div key={question.title}>{renderQuestion(question, index)}</div>
        ))}
      </div>
    )
  }

  const renderSection = (
    section: FormSectionType,
    index: number,
    total: number
  ) => {
    return (
      <div>
        <div
          id={`${section.context}-section-label`}
          data-aid={['Demographics', index + 1, 'Title'].join('')}
          className={styles.section}
        >
          {section.title}&nbsp;
          <span className={styles.highlight}>{section.titleHighlight}</span>
        </div>
        {renderQuestions(section, index)}
        <div
          className={
            index < total - 1 && !formStepState.isStepped
              ? styles.separator
              : styles.noSeparator
          }
        />
      </div>
    )
  }

  const renderSubmitButton = () => {
    return (
      <div className={styles.buttonContainer}>
        <FloatingButton
          context="submit"
          onClick={handleSubmit}
          disabled={!authToken || !patientId}
          isLoading={isLoading}
        />
      </div>
    )
  }

  const renderSections = () => {
    const form = model.form as FormType
    const sections = form.sections as [FormSectionType]
    const total = sections.length

    if (formStepState.isStepped) {
      return (
        <div>
          {renderSection(
            sections[formStepState.currentSection] as FormSectionType,
            formStepState.currentSection,
            total
          )}
        </div>
      )
    }
    return (
      <div>
        {sections.map((section: FormSectionType, index: number) => (
          <div key={section.title}>{renderSection(section, index, total)}</div>
        ))}
      </div>
    )
  }

  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  const renderForm = () => {
    if (formStepState.isStepped && formStepState.transitioning) {
      return null
    }
    return (
      <div className={styles.animated}>
        <Form className={styles.form}>{renderSections()}</Form>
        {renderSubmitButton()}
      </div>
    )
  }

  const renderMainContent = () => {
    return (
      <div className={styles.container}>
        {renderHeader()}
        {renderErrorBanner()}
        {renderForm()}
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter>
      {renderMainContent()}
    </Layout>
  )
}

export default DemographicsForm
