import { createAkiliApi } from '../../utils/api/api-methods'

type Data = {
  firstName: string
  lastName: string
  zipcode: string
}

type RequestData = {
  given_name: string
  family_name: string
  zip_code: string
}

/*
  400	- bad request errors / request validation error
  401	- unauthorized
*/

type ErrorResponse = {
  success: false
  status: 400 | 401
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
  responsePayload: {
    profileId: string
    auth_id: string
    email: string
  }
}

const url = '/signup/caregiver'
const name = 'registerCaregiver'

const registerCaregiverAPI = (
  authToken: string,
  data: Data
): Promise<ErrorResponse | SuccessResponse> => {
  const payload = {
    given_name: data.firstName,
    family_name: data.lastName,
    zip_code: data.zipcode
  } as RequestData

  return createAkiliApi(authToken, url, 'POST', name, payload)
}

export default registerCaregiverAPI
