export type ScreensMessagesContent = {
  analytics: {
    viewed_event: string
    error_event: {
      name: string
      segment: {
        [err: string]: string
      }
    }
  }
  status: {
    [types: string]: {
      hasIcon: boolean
      success: boolean
      hasLogin: boolean
      hasSupportLink: boolean
      hasPasswordReset: boolean
    }
  }
}

const screensMessagesContent = {
  analytics: {
    viewed_event: 'Screen_viewed',
    error_event: {
      name: 'Auth0Status',
      segment: {
        code: 'auth0_code',
        message: 'auth0_message',
        success: 'auth0_success'
      }
    }
  },
  status: {
    success: {
      hasIcon: true,
      success: true,
      hasPasswordReset: false,
      hasSupportLink: false,
      hasLogin: false
    },
    fail: {
      hasIcon: true,
      success: false,
      hasPasswordReset: true,
      hasSupportLink: true,
      hasLogin: false
    }
  }
}
export default screensMessagesContent
