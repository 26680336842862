import React, { FC, useRef, useState, useLayoutEffect } from 'react'
import { Analytics } from '@utils'
import styles from './styles.module.scss'
import YoutubeEmbed from '../YoutubeEmbed'

type MediaVideoEmbedProps = {
  embedId: string
  placeholderImg: string
  automationVideoId: string
  onClick?: () => void
}

const MediaVideoEmbed: FC<MediaVideoEmbedProps> = ({
  embedId,
  placeholderImg,
  automationVideoId,
  onClick
}) => {
  const placeholderImageRef = useRef<HTMLDivElement>(null)
  const videoContainerRef = useRef<HTMLDivElement>(null)

  const [play, setPlay] = useState(false)

  useLayoutEffect(() => {
    setPlay(false)
    return () => {
      setPlay(false)
    }
  }, [])

  if (!embedId || !placeholderImg) return null

  const imgPressed = (ev) => {
    if (!play) {
      placeholderImageRef.current!.classList.add(styles.hide)
      videoContainerRef.current!.classList.add(styles.show)

      setPlay(true)
      Analytics.recordEvent('VideoPlaceholder_clicked', null, 'MIXPANEL', {})
      ev.preventDefault()

      if (onClick) {
        onClick()
      }
    }
  }

  return (
    <div>
      <div
        ref={placeholderImageRef as React.RefObject<HTMLDivElement>}
        id="video-placeholder"
        tabIndex={0}
        className={styles.placeholderImgContainer}
        role="button"
        onClick={imgPressed}
        data-aid={automationVideoId}
        onKeyDown={(e) => {
          if (e.keyCode === 13) imgPressed(e)
        }}
      >
        <img className={styles.placeholderImg} src={placeholderImg} />
      </div>
      <div
        ref={videoContainerRef as React.RefObject<HTMLDivElement>}
        className={styles.video_responsive}
        id="video-container"
      >
        <YoutubeEmbed videoId={embedId} startPlay={play} />
      </div>
    </div>
  )
}

export default MediaVideoEmbed
