/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react'
import InputMask from 'react-input-mask'
import styles from '../styles.module.scss'

type PhoneNumberProps = {
  context?: string
  placeholder: string
  value: string
  onChange: (value: string, id?: string) => void
  onFocus?: () => void
  automationFieldId?: string
}

const PhoneNumberInput: FC<PhoneNumberProps> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  context,
  automationFieldId
}) => {
  const id = context || 'phone-number'
  const refs: {
    [key: string]: InputMask | null
  } = {
    main: null
  }

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(val)
    refs.main?.setValue(val)
  }

  return (
    <InputMask
      data-aid={automationFieldId}
      id={`${id}-input`}
      className={styles.text_input}
      type="text"
      value={value}
      mask="(999) 999-9999"
      placeholder={placeholder}
      maskChar="_"
      onChange={handleChange}
      onFocus={onFocus}
    />
  )
}

export default PhoneNumberInput
