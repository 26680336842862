const cleanBirthDate = (value: string, withPipe = false): string => {
  if (withPipe) {
    let month = value.split('|')[0]
    let year = value.split('|')[1]
    month = month?.replace(/[^0-9]/g, '')
    year = year?.replace(/[^0-9]/g, '')
    return `${month}|${year}`
  }
  return value.replace(/[^0-9]/g, '')
}

export default cleanBirthDate
