/* eslint-disable no-underscore-dangle */
import { create } from 'apisauce'

import { getLocalizedRequestError } from './requestErrors/helpers'
import ServerStatus from './server-status'

class APIService {
  constructor(baseURL, authToken) {
    const requestParams = {
      baseURL,
      timeout: 10000,
      headers: {
        'Content-Type': 'application/json'
      }
    }

    if (authToken) {
      requestParams.headers.Authorization = `Bearer ${authToken}`
    }

    this.apiSauce = create(requestParams)
  }

  get = async (url, name, payload = {}, config = {}) => {
    const res = await this._handleResponse(
      this.apiSauce.get,
      { url, payload },
      config
    )
    return res
  }

  put = async (url, name, payload = {}) => {
    const res = await this._handleResponse(this.apiSauce.put, { url, payload })
    return res
  }

  patch = async (url, name, payload = {}) => {
    const res = await this._handleResponse(this.apiSauce.patch, {
      url,
      payload
    })
    return res
  }

  post = async (url, name, payload = {}) => {
    const res = await this._handleResponse(
      this.apiSauce.post,
      { url, payload },
      { name }
    )
    return res
  }

  delete = async (url, name, payload = {}) => {
    const res = await this._handleResponse(this.apiSauce.delete, {
      url,
      payload
    })
    return res
  }

  _handleResponse = async (apiRequest, params, config) => {
    const response = await apiRequest(params.url, params.payload)

    return this._handleError(response, params, config)
  }

  _handleError = (response, params, config = {}) => {
    if (response.status === ServerStatus.unauthorized) {
      // TODO: Handle TokenExpired
    }

    const { ok, problem, status } = response

    let responsePayload = response

    if (response && response.data) {
      responsePayload = response.data
    }

    if (status === 401) {
      return Promise.reject(new Error('INVALID_USER'))
    }

    const formattedResponse = {
      success: ok && !problem, // TODO: improve conditional handling or 404
      status,
      requestParams: params,
      responsePayload,
      localizedErrorMessage: null
    }

    if (!formattedResponse.success) {
      const localizedErrorMessage = getLocalizedRequestError(
        response,
        config?.name
      )

      if (localizedErrorMessage) {
        formattedResponse.localizedErrorMessage = localizedErrorMessage
      }
    }

    return formattedResponse
  }
}

export default APIService
