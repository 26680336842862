import React, { FC } from 'react'

type CheckProps = {
  fill?: string
  width?: number
  height?: number
  stroke?: string
  scale?: number
  extraClassName?: string
}

const Check: FC<CheckProps> = ({ fill = 'white', width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <path
          d="M8.8,15.9 L18.7,6 C19.0865993,5.61340068 19.7134007,5.61340068 20.1,6 C20.4865993,6.38659932 20.4865993,7.01340068 20.1,7.4 L9.50710678,17.9928932 C9.11658249,18.3834175 8.48341751,18.3834175 8.09289322,17.9928932 L3.9,13.8 C3.51340068,13.4134007 3.51340068,12.7865993 3.9,12.4 C4.28659932,12.0134007 4.91340068,12.0134007 5.3,12.4 L8.8,15.9 Z"
          id="path-check-icon"
        />
      </defs>
      <g id="g_icons_check" fill="none" fillRule="evenodd">
        <use
          id="u_icon_check"
          fill={fill}
          fillRule="nonzero"
          xlinkHref="#path-check-icon"
        />
      </g>
    </svg>
  )
}

export default Check
