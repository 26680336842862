import React, { FC } from 'react'

import { CheckboxInput } from '@components'
import styles from './styles.module.scss'

type MultiSelectProps = {
  label?: string
  dataId: string
  context?: string
  options: string[]
  values: string[]
  selections: string[]
  handleChange: (value: any, dataId?: string) => void
  error?: boolean
  errorMessage?: string
  onFocus: () => void
  automationFieldId?: string
}

const MultiSelect: FC<MultiSelectProps> = ({
  label = '',
  dataId,
  context,
  options,
  values = [],
  selections = [],
  handleChange,
  onFocus,
  automationFieldId,
  error,
  errorMessage
}) => {
  const id = context || 'multiselect'

  const hasError = () => {
    return error ? 'error' : ''
  }

  const handleCheckboxChange = (selected: boolean, val: string) => {
    const previousSelections = [...selections]

    if (selected) {
      previousSelections.push(val)
      return handleChange(previousSelections, dataId)
    }

    return handleChange(
      previousSelections.filter((item) => item !== val),
      dataId
    )
  }

  const renderOptions = () => {
    return (
      <div className={`${styles.options_container}`} onFocus={onFocus}>
        {options.map((item, index) => {
          const value = values[index]
          return (
            <div key={item} className={styles.check_option}>
              <CheckboxInput
                context={value?.toLowerCase()}
                dataId={value}
                label={item}
                checked={selections.includes(value)}
                handleChange={handleCheckboxChange}
                automationFieldId={[automationFieldId, 'Check', index + 1].join(
                  ''
                )}
                error={error}
              />
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div
      id={`${id}-selectgroup`}
      className={`field ${styles.check_group_container} ${hasError()}`}
    >
      <label id={`${id}-label`} htmlFor="input">
        {label}
      </label>
      {renderOptions()}
      {error && errorMessage && (
        <div className={styles.error_message}>{errorMessage}</div>
      )}
    </div>
  )
}

export default MultiSelect
