type Configs = {
  analytics: {
    viewed: string
    submitted: string
  }
  form: {
    [field: string]: {
      maxLength?: number
      values?: { [key: string]: string }
      errorTypes:
        | Record<string, never>
        | { [key: string]: string | boolean | number }
    }
  }
}

const configs = {
  analytics: {
    viewed: 'Screen_viewed',
    submitted: 'SMSOptIn_submitted'
  },
  form: {
    mobileNumber: {
      maxLength: 10,
      errorTypes: {
        type: 'phone',
        required: true,
        minChar: 10,
        maxChar: 10
      }
    }
  }
} as Configs

export default configs
