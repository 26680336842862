import React, { FC } from 'react'

import { ContentSeparator } from '@components'
import { ReactComponent as Logo } from './assets/akili_logo.svg'
import { ReactComponent as LogoT01A } from './assets/logo-EndeavorOTC-stacked.svg'
import styles from './styles.module.scss'

export enum HeaderDescriptionTypeEnum {
  String,
  NewLine,
  Link
}
type DescriptionType = {
  type: HeaderDescriptionTypeEnum
  automationLegendId?: string
  context?: string
  content?: string
  href?: string
  target?: string
  className?: string
}
type HeaderProps = {
  title?: string
  description: string | DescriptionType[]
  automationTitleId?: string
  automationLegendId?: string
  context?: string
  showSeparator?: boolean
  brandVersion?: string
  clientNameId?: string
}

const Description = (
  descriptionType: DescriptionType,
  index: number,
  brandVersion: string
) => {
  let result
  const brandStyleName = `brand_${brandVersion}`

  switch (descriptionType.type) {
    case HeaderDescriptionTypeEnum.Link:
      result = (
        <a
          data-aid={descriptionType.automationLegendId}
          key={index}
          id={descriptionType.context || 'link'}
          href={descriptionType.href}
          target={descriptionType.target}
          className={`${descriptionType.className} ${styles[brandStyleName]}`}
        >
          {descriptionType.content}
        </a>
      )
      break
    case HeaderDescriptionTypeEnum.NewLine:
      result = <br key={index} className={descriptionType.className} />
      break
    default:
      result = (
        <span
          data-aid={descriptionType.automationLegendId}
          key={index}
          id={descriptionType.context || 'header-description'}
          className={descriptionType.className}
        >
          {descriptionType.content}
        </span>
      )
      break
  }
  return result
}

const Header: FC<HeaderProps> = ({
  title,
  description,
  automationTitleId,
  automationLegendId,
  context,
  showSeparator = true,
  brandVersion = 'v1',
  clientNameId = ''
}) => {
  const id = context || 'header'

  const renderLogo = (appId) => {
    switch (appId) {
      case 'T01A':
        return <LogoT01A />
      default:
        return <Logo />
    }
  }

  return (
    <div id={`${id}-container`} className={styles.header}>
      <div id={`${id}-logo-container`}>{renderLogo(clientNameId)}</div>
      <div
        data-aid={automationTitleId}
        id={`${id}-title`}
        className={styles.title}
      >
        {title}
      </div>
      <div
        data-aid={typeof description === 'string' ? automationLegendId : null}
        id={`${id}-description`}
        className={styles.description}
      >
        {typeof description === 'string'
          ? description
          : description.map((type, i) => Description(type, i, brandVersion))}
      </div>
      {showSeparator && (
        <ContentSeparator
          extraClassName={styles.separator}
          automationSeparatorId="SeparationLine"
        />
      )}
    </div>
  )
}

export default Header
