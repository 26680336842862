import React, { FC } from 'react'
import styles from '../styles.module.scss'

type EmailInputProps = {
  placeholder: string
  value: string
  maxLength?: number
  context?: string
  isUsername?: boolean
  onChange: (value: string, id?: string) => void
  onFocus: () => void
  automationFieldId?: string
  brandVersion?: string
}

const EmailInput: FC<EmailInputProps> = ({
  placeholder,
  value,
  maxLength = 192,
  context,
  isUsername = false,
  onChange,
  onFocus,
  automationFieldId,
  brandVersion = 'v1'
}) => {
  const id = context || 'email'
  const brandStyleName = `brand_${brandVersion}`

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(val, id)
  }

  return (
    <input
      data-aid={automationFieldId}
      id={`${id}-input`}
      type="email"
      autoComplete={isUsername ? 'username' : 'off'}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      maxLength={maxLength}
      onFocus={onFocus}
      className={`${styles[brandStyleName]}`}
    />
  )
}

export default EmailInput
