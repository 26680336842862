import React, { FC } from 'react'

import ZipcodeInput from './ZipcodeInput'
import DateInput from './DateInput'
import CurrencyInput from './CurrencyInput'
import NumberInput from './NumberInput'
import PasswordInput from './PasswordInput'
import EmailInput from './EmailInput'
import ActivationCodeInput from './ActivationCodeInput'
import PhoneNumberInput from './PhoneNumberInput'
import styles from './styles.module.scss'

type TextInputProps = {
  style?: React.CSSProperties
  fluid?: boolean
  label?: string
  placeholder: string
  value: string
  context?: string
  dataId?: string
  handleChange: (value: string, context?: string) => void
  onFocus: () => void
  type?:
    | 'default'
    | 'date'
    | 'zipcode'
    | 'phone-number'
    | 'number'
    | 'email'
    | 'username'
    | 'password'
    | 'new-password'
    | 'currency'
    | 'activation-code'
    | 'alphanumeric'
  maxLength?: number
  error: boolean
  errorMessage: string
  automationLabelId?: string
  automationFieldId?: string
  automationIconId?: string
  automationValidationId?: string
  brandVersion?: string
}

const TextInput: FC<TextInputProps> = ({
  style,
  label = '',
  placeholder = '',
  value,
  context,
  dataId,
  handleChange,
  onFocus,
  type = 'default',
  maxLength = 32,
  error = false,
  errorMessage = '',
  automationLabelId = '',
  automationFieldId = '',
  automationIconId = '',
  automationValidationId = '',
  brandVersion = 'v1'
}) => {
  const id = context || dataId || type
  const brandStyleName = `brand_${brandVersion}`

  const hasError = () => {
    return error ? 'error' : ''
  }

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value: val } = e.target
    // trim string start as user types incase they hit submit and the blur doesn't have time to execute.
    // Note white space within A-Z-0-9 text is allowed
    let trimmedVal = val.trimStart()

    if (type === 'alphanumeric') {
      trimmedVal = trimmedVal.replace(/[^a-zA-Z0-9\s-]+/gi, '')
    }

    handleChange(trimmedVal, dataId || context || '')
  }

  const trimValue = () => {
    // trim white space (mostly for the string end)
    handleChange(value.trim(), dataId || context || '')
  }

  return (
    <div
      id={`${id}-container`}
      className={`field ${styles.text_input} ${hasError()}`}
    >
      <label data-aid={automationLabelId} id={`${id}-label`} htmlFor="input">
        {label}
      </label>
      <div className="ui input">
        {type === 'zipcode' && (
          <ZipcodeInput
            automationFieldId={automationFieldId}
            context={context}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'number' && (
          <NumberInput
            automationFieldId={automationFieldId}
            placeholder={placeholder}
            value={value}
            context={context}
            dataId={dataId}
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'email' && (
          <EmailInput
            automationFieldId={automationFieldId}
            placeholder={placeholder}
            value={value}
            context={context}
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={onFocus}
            brandVersion={brandVersion}
          />
        )}
        {type === 'username' && (
          <EmailInput
            automationFieldId={automationFieldId}
            placeholder={placeholder}
            value={value}
            context={context}
            isUsername
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={onFocus}
            brandVersion={brandVersion}
          />
        )}
        {type === 'password' && (
          <PasswordInput
            automationFieldId={automationFieldId}
            placeholder={placeholder}
            value={value}
            context={context}
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'new-password' && (
          <PasswordInput
            automationFieldId={automationFieldId}
            placeholder={placeholder}
            value={value}
            context={context}
            isNewPassword
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'currency' && (
          <CurrencyInput
            automationFieldId={automationFieldId}
            placeholder={placeholder}
            value={value}
            context={context}
            dataId={dataId}
            maxLength={maxLength}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'phone-number' && (
          <PhoneNumberInput
            automationFieldId={automationFieldId}
            context={context}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'date' && (
          <DateInput
            automationFieldId={automationFieldId}
            context={context}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {type === 'activation-code' && (
          <ActivationCodeInput
            automationFieldId={automationFieldId}
            context={context}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
          />
        )}
        {(type === 'default' || type === 'alphanumeric') && (
          <input
            data-aid={automationFieldId}
            id={`${id}-input`}
            className={`${styles.text_input} ${styles[brandStyleName]}`}
            style={style}
            placeholder={placeholder}
            type="text"
            maxLength={maxLength}
            value={value}
            onChange={onTextChange}
            onFocus={onFocus}
            onBlur={trimValue}
          />
        )}
        {error && (
          <i
            data-aid={automationIconId}
            id={`${id}-error-icon`}
            className="fa fa-warning"
          />
        )}
      </div>
      {error && errorMessage && (
        <div
          data-aid={automationValidationId}
          id={`${id}-error-label`}
          className={styles.error_message}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default TextInput
