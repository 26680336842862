import { History } from 'history'
import QueryString from 'query-string'

export const routes = {
  onboardUser: 'onboard-user',
  accountUnblock: 'account-unblock',
  emailVerification: 'user-email-verification',
  legacyVerifyEmailStatus: 'email-verification',
  verifyEmailOtc: 'email-verification-otc',
  setupCaregiverProfile: 'setup-caregiver-profile',
  setupPatientProfile: 'setup-patient-profile',
  smsOptIn: 'sms',
  activateTreatment: 'activate-treatment',
  onboardingComplete: 'onboarding-complete',
  demographics: 'demographics',
  logout: 'logout',
  login: 'login'
}

export function navigateToUrl(
  history: History,
  location: string,
  query?: string,
  state?: { [key: string]: string }
): void {
  if (!history || !location) {
    return
  }

  const currentURL = QueryString.pick(window.location.href, ['app', 'action'])
  const defaultQuery = currentURL.split('?')[1]

  history.push(
    {
      pathname: `/${location}`,
      search: query || defaultQuery
    },
    state
  )
}
