type Configs = {
  analytics: {
    viewed: string
    submitted: string
  }
  form: {
    [field: string]: {
      maxLength?: number
      errorTypes:
        | Record<string, never>
        | { [key: string]: string | boolean | number }
    }
  }
}

const configs = {
  analytics: {
    viewed: 'Screen_viewed',
    submitted: 'CaregiverProfile_submitted'
  },
  form: {
    firstName: {
      maxLength: 30,
      errorTypes: {
        required: true,
        minChar: 1,
        maxChar: 30,
        minAZaz: 1
      }
    },
    lastName: {
      maxLength: 30,
      errorTypes: {
        required: true,
        minChar: 1,
        maxChar: 30,
        minAZaz: 1
      }
    },
    zipcode: {
      errorTypes: {
        required: true,
        minChar: 5,
        maxChar: 5
      }
    }
  }
} as Configs

export default configs
