import { createAkiliApi } from '../../utils/api/api-methods'

/*
  401	- unauthorized used
  404	- data not found
*/

type RequestData = {
  url: string
}

type ErrorResponse = {
  success: false
  status: 401 | 404
  requestParams: RequestData
  localizedErrorMessage: string
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
  responsePayload: {
    familyName: string | undefined
    givenName: string | undefined
    zipCode: string | undefined
    phoneNumber?: string | undefined
  }
}

interface ConfigType {
  id: string
}

const url = '/caregivers/'
const name = 'getCaregiverProfile'

const getCaregiverProfileAPI = (
  authToken: string,
  config: ConfigType
): Promise<ErrorResponse | SuccessResponse> => {
  const { id } = config

  return createAkiliApi(authToken, `${url}${id}`, 'GET', name)
}

export default getCaregiverProfileAPI
