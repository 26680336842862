import React, { FC, useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import QueryString from 'query-string'
import { useAuth0 } from '@auth0/auth0-react'

import { getCareteamAPI } from '@apiRoutes'
import { Layout, Loading, ErrorBanner } from '@components'
import { useAuthToken, navigateToUrl, routes, Branch, Analytics } from '@utils'
import styles from './styles.module.scss'

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const OnboardUser: FC<RouteComponentProps & CustomProps> = ({
  history,
  location,
  handleInvalidUser
}) => {
  const screenId = 'onboard-user-screen'

  const { loginWithRedirect, user } = useAuth0()

  const { app, action } = QueryString.parse(location.search)

  const { authToken, tokenPending } = useAuthToken()
  const [{ patientFetching }, setPatient] = useState({
    patientFetching: true
  })

  const [screenError, setScreenError] = useState('' as string)

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  const addScreenError = (errorText: string) => {
    setScreenError(errorText)
  }

  useEffect(() => {
    if (tokenPending || !patientFetching) {
      return
    }

    if (!authToken) {
      const redirectConfig = { initial_screen: '', app_origin: app }

      redirectConfig.initial_screen = action === 'signup' ? 'signup' : 'login'

      loginWithRedirect(redirectConfig)
      return
    }

    if (user) {
      if (!user?.email_verified) {
        navigateToUrl(history, routes.emailVerification)
        return
      }
    }

    const fetchData = async () => {
      setScreenError('')
      const response = await getCareteamAPI(authToken || '').catch((e) =>
        handleInvalidUser(e)
      )

      if (!response) {
        return
      }

      if (!response.success) {
        addScreenError(response.localizedErrorMessage)
        return
      }

      const ptt = response.responsePayload.careTeams[0]?.patient
      const treatmentCode =
        (app as string) || (process.env.REACT_APP_TO1_PRODUCT_CODE as string)

      setPatient({
        patientFetching: false
      })

      if (
        response.responsePayload.careTeams[0] &&
        response.responsePayload.careTeams[0].caregivers &&
        response.responsePayload.careTeams[0].caregivers.length > 0
      ) {
        const caregiver = response.responsePayload.careTeams[0]?.caregivers[0]
        // Recording with the caregiver.profileId temporarily
        Branch.setIdentity(caregiver.profileId)
        Branch.recordEvent('LOGIN', {
          profile_uuid: caregiver.profileId
        })
        Analytics.recordEvent('Login_authenticated_user', authToken, 'MIXPANEL')
      } else {
        Branch.recordEvent('LOGIN')
      }

      const treatments = ptt?.treatments?.[treatmentCode] || []
      if (treatments.length) {
        navigateToUrl(history, routes.onboardingComplete)
        return
      }

      // when: !careteam || careteam but !patient || careteam patient but !active treatment
      navigateToUrl(history, routes.setupCaregiverProfile)
    }
    fetchData()
  }, [
    authToken,
    tokenPending,
    app,
    action,
    history,
    loginWithRedirect,
    user,
    patientFetching
  ])

  /** ************* */
  const renderErrorBanner = () => {
    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  return (
    <Layout context={screenId}>
      <div className={styles.container}>
        {screenError ? renderErrorBanner() : <Loading fullScreen />}
      </div>
    </Layout>
  )
}

export default OnboardUser
