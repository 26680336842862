import React, { FC } from 'react'
import InputMask from 'react-input-mask'
import styles from './styles.module.scss'

type DateProps = {
  placeholder: string
  value: string
  onChange: (value: string) => void
  onFocus: () => void
  context?: string
  automationFieldId?: string
}

const DateInput: FC<DateProps> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  context,
  automationFieldId
}) => {
  const id = context || 'date'
  const refs: {
    [key: string]: InputMask | null
  } = {
    main: null
  }

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(val)
    refs.main?.setValue(val)
  }

  return (
    <InputMask
      data-aid={automationFieldId}
      id={`${id}-input`}
      className={styles.text_input}
      type="text"
      value={value}
      mask="99 | 9999"
      placeholder={placeholder}
      maskChar="_"
      onChange={handleChange}
      onFocus={onFocus}
    />
  )
}

export default DateInput
