import React, { FC } from 'react'

type CheckInCircleProps = {
  fill?: string
  width?: number
  height?: number
}

const CheckInCircle: FC<CheckInCircleProps> = ({
  width = 24,
  height = 24,
  fill = 'white'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <path
          d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M9.61151126,16.2928932 L6.60892514,13.2903071 C6.27262507,12.954007 6.27262507,12.4087569 6.60892514,12.0724568 L6.60892514,12.0724568 C6.94536251,11.7360195 7.49071784,11.7356391 7.82762411,12.071607 L10.318618,14.5556622 L16.263192,8.61108827 C16.5994962,8.27478399 17.1447532,8.27478399 17.4810575,8.61108827 C17.4817747,8.6118055 17.4824907,8.612524 17.4832054,8.61324376 L17.4832054,8.61324376 C17.8198529,8.9522789 17.8188857,9.49973233 17.4810423,9.83757579 L11.0257248,16.2928932 C10.6352005,16.6834175 10.0020356,16.6834175 9.61151126,16.2928932 Z"
          id="path-check-in-circle-icon"
        />
      </defs>
      <g id="g_icons_check_in_circle" fill="none" fillRule="evenodd">
        <use
          id="u_icon_check_in_circle"
          fill={fill}
          fillRule="nonzero"
          href="#path-check-in-circle-icon"
          xlinkHref="#path-check-in-circle-icon"
        />
      </g>
    </svg>
  )
}

export default CheckInCircle
