import React, { FC } from 'react'

type ZipcodeProps = {
  placeholder: string
  value: string
  onChange: (value: string) => void
  onFocus: () => void
  context?: string
  automationFieldId?: string
}

const ZipcodeInput: FC<ZipcodeProps> = ({
  placeholder,
  value,
  onChange,
  onFocus,
  context,
  automationFieldId
}) => {
  const id = context || 'zipcode'

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const nextValue = val.replace(/[^\d]/g, '')

    onChange(nextValue)
  }

  return (
    <input
      data-aid={automationFieldId}
      id={`${id}-input`}
      type="text"
      inputMode="numeric"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      maxLength={5}
      onFocus={onFocus}
    />
  )
}

export default ZipcodeInput
