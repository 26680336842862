// export const uRLQueryStrings = {
//   verification_expired: 'Access expired.',
//   verification_already: 'This account is already verified.',
//   verification_used_once: 'This URL can be used only once',
//   token_expired: 'token_expired'
// }

export type ScreensMessagesContent = {
  analytics: {
    viewed_event: string
    error_event: {
      name: string
      segment: {
        [err: string]: string
      }
    }
  }
  types: {
    [field: string]: {
      hasIcon: boolean
      success: boolean
      hasSupportLink: boolean
      hasLogin: boolean
      hasErrorDetails: boolean
    }
  }
}

const screensMessagesContent = {
  analytics: {
    viewed_event: 'Screen_viewed',
    error_event: {
      name: 'Auth0Status',
      segment: {
        code: 'auth0_code',
        message: 'auth0_message',
        success: 'auth0_success'
      }
    }
  },
  types: {
    generic_error: {
      hasIcon: true,
      success: false,
      hasSupportLink: true,
      hasLogin: false,
      hasErrorDetails: true
    },
    verification_expired: {
      hasIcon: false,
      success: false,
      hasSupportLink: true,
      hasLogin: false,
      hasErrorDetails: true
    },
    verification_already: {
      hasIcon: true,
      success: false,
      hasSupportLink: false,
      hasLogin: false,
      hasErrorDetails: false
    },
    verification_used_once: {
      hasIcon: true,
      success: false,
      hasSupportLink: false,
      hasLogin: false,
      hasErrorDetails: false
    },
    resetPage_error: {
      hasIcon: true,
      success: false,
      hasPasswordReset: true,
      hasSupportLink: true,
      hasLogin: false,
      hasErrorDetails: true
    },
    unblocked_account_expired: {
      hasIcon: true,
      success: false,
      hasSupportLink: true,
      hasLogin: false,
      hasErrorDetails: true
    },
    invalid_invitation: {
      hasIcon: false,
      success: false,
      hasSupportLink: false,
      hasLogin: false,
      hasErrorDetails: false
    }
  }
}
export default screensMessagesContent
