import React, { FC, useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Form } from 'semantic-ui-react'

import { getCareteamAPI, setActivationCodeAPI } from '@apiRoutes'
import {
  Layout,
  Header,
  TextInput,
  ErrorBanner,
  FloatingButton
} from '@components'
import {
  activationCodeRegex,
  validateFormField,
  useAuthToken,
  navigateToUrl,
  routes,
  Analytics
} from '@utils'
import styles from './styles.module.scss'
import _model from './model.en.json'
import configs from './configs'

const PRODUCT_CODE = process.env.REACT_APP_WEB_PRODUCT_CODE
const TREATMENT_ID = process.env.REACT_APP_TO1_PRODUCT_CODE

type Localization = {
  title: string
  description: string
  form: {
    [field: string]: {
      label: string
      placeholder: string
      error: string
    }
  }
}

type State = {
  value: string
  hasError: boolean
  error: string
}

type RequestData = {
  treatmentId: string
  patientId: string
  activationCode: string
}

const model = _model as Localization

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const ActivateTreatment: FC<RouteComponentProps & CustomProps> = ({
  history,
  handleInvalidUser
}) => {
  /** ************* */
  /* General data handlers */
  const SCREEN_NAME = 'Enter Activation Code'

  const [isLoading, setLoading] = useState(false)
  const [screenError, setScreenError] = useState('' as string)

  const addScreenError = (errorText: string) => {
    if (errorText) {
      setScreenError(errorText)
    }
  }

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  /** ************* */
  /* Form data handlers */

  const [activationCode, setActivationCode] = useState({
    value: '',
    hasError: false,
    error: ''
  } as State)

  const updateActivationCode = (val: string): void => {
    setActivationCode({ ...activationCode, value: val })
  }

  const clearError = () => {
    const { hasError } = activationCode

    if (!hasError) return

    setActivationCode({ ...activationCode, hasError: false, error: '' })
  }

  const [{ patientId, patientIdFetching }, setPatient] = useState({
    patientId: '',
    patientIdFetching: true
  })
  const { authToken, tokenPending } = useAuthToken()

  /** ************* */
  /* Record the viewed event to Mixpanel the first time */
  useEffect(() => {
    Analytics.recordEvent(configs.analytics.viewed, authToken, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  /** ************* */
  /* Fetch existing data */

  useEffect(() => {
    if (!patientIdFetching || tokenPending || !authToken || patientId) {
      return
    }

    const fetchData = async () => {
      const response = await getCareteamAPI(authToken).catch((e) =>
        handleInvalidUser(e)
      )

      if (!response) {
        return
      }

      if (!response.success) {
        addScreenError(response.localizedErrorMessage)
        return
      }

      const pttId = response.responsePayload.careTeams[0]?.patient?.patientId

      setPatient({
        patientId: pttId || '',
        patientIdFetching: false
      })

      const activeCode =
        response.responsePayload?.careTeams[0]?.patient?.treatments

      const ProductCode = process.env.REACT_APP_TO1_PRODUCT_CODE
      if (
        activeCode !== undefined &&
        ProductCode in activeCode &&
        activeCode[ProductCode].length !== 0
      ) {
        activeCode[process.env.REACT_APP_TO1_PRODUCT_CODE].map((code) => {
          if (
            code.activatedWithCode !== '' &&
            Date.parse(code.activationCodeExpiresUtc) > Date.now()
          ) {
            navigateToUrl(history, routes.onboardingComplete)
          }
        })
      }
    }

    fetchData()
  }, [tokenPending, authToken, patientIdFetching, patientId])

  /** ************* */
  /* Form authentication handlers */

  let validForm = true

  const verifyFormField = (
    name: string,
    state: State,
    updateState: (state: State) => void
  ): void => {
    const hasValidationError = validateFormField(name, configs, state)
    if (!hasValidationError) return

    validForm = false
    const {
      form: {
        [name]: { error }
      }
    } = model

    updateState({ ...state, hasError: true, error })
  }

  const validateForm = () => {
    validForm = true
    const fields = [
      {
        name: 'activationCode',
        state: activationCode,
        updateState: setActivationCode
      }
    ]

    fields.forEach(({ name, state, updateState }) => {
      verifyFormField(name, state, updateState)
    })
  }

  const activateCode = async () => {
    const data = {
      treatmentId: TREATMENT_ID,
      patientId,
      activationCode: activationCodeRegex(activationCode.value)
    } as RequestData

    const response = await setActivationCodeAPI(
      authToken as string,
      data
    ).catch((e) => handleInvalidUser(e))

    if (!response) {
      return
    }

    setLoading(false)

    if (!response.success) {
      addScreenError(response.localizedErrorMessage)
      return
    }

    /* Record the submitted event to Mixpanel */
    Analytics.recordEvent(configs.analytics.submitted, authToken, 'MIXPANEL', {
      product: PRODUCT_CODE,
      app_code: TREATMENT_ID
    })

    navigateToUrl(history, routes.onboardingComplete)
  }

  const handleSubmit = () => {
    if (isLoading || !authToken || !patientId) {
      return
    }

    setScreenError('')
    setLoading(true)

    validateForm()

    if (!validForm) {
      setLoading(false)
      window.scrollTo(0, 0)
      return
    }

    activateCode()
  }

  /** ************* */
  /* UI Content */

  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  const renderForm = () => {
    const {
      form: {
        activationCode: { label, placeholder }
      }
    } = model

    return (
      <div className={styles.form}>
        <Form>
          <Form.Group widths="equal">
            <TextInput
              automationFieldId="CodeField"
              automationLabelId="CodeLabel"
              type="activation-code"
              label={label}
              placeholder={placeholder}
              value={activationCode.value}
              handleChange={updateActivationCode}
              error={activationCode.hasError}
              errorMessage={activationCode.error}
              onFocus={clearError}
            />
          </Form.Group>
        </Form>
      </div>
    )
  }

  const renderSubmitContent = () => {
    return (
      <div className={styles.buttonContainer}>
        <FloatingButton
          automationButtonId="ArrowButton"
          context="submit"
          onClick={handleSubmit}
          disabled={!authToken || !patientId}
          isLoading={isLoading}
        />
      </div>
    )
  }
  const screenId = 'activate-treatment-screen'

  return (
    <Layout context={screenId} hasFooter>
      <div className={styles.container}>
        <Header
          automationTitleId="ActivationTitle"
          automationLegendId="ActivationLegend"
          title={model.title}
          description={model.description}
        />
        {renderErrorBanner()}
        {renderForm()}
        {renderSubmitContent()}
      </div>
    </Layout>
  )
}

export default ActivateTreatment
