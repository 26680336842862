import React, { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import QueryString from 'query-string'

import { navigateToUrl, routes } from '@utils'

type AuxProps = {
  children: ReactElement
}

const Auth0ProviderWithHistory = ({ children }: AuxProps): JSX.Element => {
  const history = useHistory()
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  const initialURL = QueryString.pick(window.location.href, ['app', 'action'])
  const queryParams = initialURL.split('?')[1]
  const redirectUri = `${window.location.origin}/${routes.onboardUser}${
    queryParams ? `?${queryParams}` : ''
  }`

  const onRedirectCallback = (): void => {
    navigateToUrl(history, routes.onboardUser)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
