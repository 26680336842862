/* eslint-disable no-useless-escape */
import React, { FC, useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Layout,
  Header,
  ErrorBanner,
  Paragraph,
  ParagraphContentTypeEnum,
  TextInput,
  PrimaryButton,
  SecondaryButton
} from '@components'
import {
  validateFormField,
  useAuthToken,
  navigateToUrl,
  routes,
  Analytics
} from '@utils'
import {
  getCaregiverProfileAPI,
  updateCaregiverApi,
  setSmsOptingApi
} from '@apiRoutes'
import { useAuth0 } from '@auth0/auth0-react'
import styles from './styles.module.scss'
import model from './model.en.json'
import configs from './configs'

type TextState = {
  value: string
  hasError: boolean
  error: string
}

type RequestData = {
  firstName: string
  lastName: string
  zipcode: string
  phone: string
}

type CaregiverResponse = {
  familyName: string | undefined
  givenName: string | undefined
  zipCode: string | undefined
  phoneNumber?: string | undefined
}

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

type IdType = { profile_uuid: string }

const SMSOptIn: FC<RouteComponentProps & CustomProps> = ({
  history,
  handleInvalidUser
}) => {
  const screenId = 'sms-opt-in'
  const SCREEN_NAME = 'SMS Opt in'

  // Get all values from the model json

  const {
    form: {
      mobileNumber: { error }
    }
  } = model

  /** ************* */
  /* General data handlers */

  const [cancelInProgress, setCancelInProgress] = useState(false)
  const [optInInProgress, setOptInInProgress] = useState(false)
  const [screenError, setScreenError] = useState('' as string)

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  /** ************* */
  /* Form data handlers */
  const clearError = (
    state: TextState,
    updateState: (state: TextState) => void
  ) => {
    const { hasError } = state
    if (!hasError) return
    updateState({ ...state, hasError: false, error: '' })
  }

  /** ************* */
  /* Mobile Number field */
  const [mobileNumber, setMobileNumber] = useState({
    value: '',
    hasError: false,
    error: model.form.mobileNumber.error
  } as TextState)

  const [caregiverData, setCaregiverData] = useState({} as CaregiverResponse)

  const updateMobileNumber = (val: string): void => {
    setMobileNumber({
      ...mobileNumber,
      value: val
        .replace(/\+1/g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/\-/g, '')
        .replace(/_/g, '')
        .replace(/\s/g, '')
    })
  }

  const clearMobileNumberError = (): void => {
    clearError(mobileNumber, setMobileNumber)
  }

  const [{ dataFetching }, updateDataFetching] = useState({
    dataFetching: true
  })

  const { authToken, tokenPending } = useAuthToken()
  const { user } = useAuth0()

  const getCaregiverId = () => {
    if (!user) return ''
    return (user[process.env.REACT_APP_AUTH0_USER_KEY] as IdType)?.profile_uuid
  }

  /** ************* */
  /* Record the viewed event to Mixpanel the first time */
  useEffect(() => {
    Analytics.recordEvent(configs.analytics.viewed, authToken, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  /** ************* */
  /* Preparing view */
  useEffect(() => {
    if (tokenPending || !authToken || !user || !dataFetching) {
      return
    }

    const fetchData = async () => {
      const id = getCaregiverId()
      const getCaregiverResponse = await getCaregiverProfileAPI(authToken, {
        id
      }).catch((e) => handleInvalidUser(e))

      if (!getCaregiverResponse) {
        return
      }

      if (!getCaregiverResponse.success) {
        // fetch api returns 404 during first time user experience
        if (getCaregiverResponse.status !== 404) {
          setScreenError(getCaregiverResponse.localizedErrorMessage)
        }

        updateDataFetching({ dataFetching: false })
        return
      }

      setCaregiverData(getCaregiverResponse.responsePayload)
      if (!caregiverData) {
        updateDataFetching({ dataFetching: false })
        return
      }

      updateMobileNumber(
        getCaregiverResponse.responsePayload?.phoneNumber || ''
      )
      updateDataFetching({ dataFetching: false })
    }
    fetchData()
  }, [tokenPending, authToken, user, dataFetching])

  /** ************* */
  /* Form validation handlers */

  let validForm = true

  const verifyFormField = (
    name: string,
    state: TextState,
    updateState: (state: TextState) => void
  ): void => {
    const hasValidationError = validateFormField(name, configs, state)
    if (!hasValidationError) return

    validForm = false

    const newState = { ...state, hasError: true }
    if (error) {
      newState.error = error
    }
    updateState(newState)
  }

  const validateForm = () => {
    validForm = true
    const fields = [
      {
        name: 'mobileNumber',
        state: mobileNumber,
        updateState: setMobileNumber
      }
    ]

    fields.forEach(({ name, state, updateState }) => {
      verifyFormField(name, state, updateState)
    })
  }

  const submitSMSOptIn = async () => {
    const data = {
      firstName: caregiverData.givenName || '',
      lastName: caregiverData.familyName || '',
      zipcode: caregiverData.zipCode || '',
      phone: `+1${mobileNumber.value}`
    } as RequestData

    let response
    const caregiverId = getCaregiverId()
    response = await updateCaregiverApi(
      authToken as string,
      caregiverId,
      data
    ).catch((e) => handleInvalidUser(e))

    if (!response) {
      return
    }

    if (!response.success) {
      setScreenError(response.localizedErrorMessage)
      setOptInInProgress(false)
      return
    }

    response = await setSmsOptingApi(authToken as string, true).catch((e) =>
      handleInvalidUser(e)
    )

    if (!response) {
      return
    }

    setOptInInProgress(false)

    if (!response.success) {
      setScreenError(response.localizedErrorMessage)
      return
    }

    Analytics.recordEvent(configs.analytics.submitted, authToken, 'MIXPANEL', {
      sms_opt_in: true
    })
    navigateToUrl(history, routes.setupPatientProfile)
  }

  const cancelSMSOptIn = async () => {
    const response = await setSmsOptingApi(authToken as string, false).catch(
      (e) => handleInvalidUser(e)
    )

    if (!response) {
      return
    }

    setCancelInProgress(false)

    if (!response.success) {
      setScreenError(response.localizedErrorMessage)
      return
    }

    Analytics.recordEvent(configs.analytics.submitted, authToken, 'MIXPANEL', {
      sms_opt_in: false
    })
    navigateToUrl(history, routes.setupPatientProfile)
  }

  const handleCancel = () => {
    if (optInInProgress || cancelInProgress || !authToken) {
      return
    }

    setScreenError('')
    setCancelInProgress(true)

    cancelSMSOptIn()
  }

  const handleSubmit = () => {
    if (optInInProgress || cancelInProgress || !authToken) {
      return
    }

    setScreenError('')
    setOptInInProgress(true)

    validateForm()

    if (!validForm) {
      setOptInInProgress(false)
      window.scrollTo(0, 0)
      return
    }

    submitSMSOptIn()
  }

  /** ************* */
  /* UI Content */
  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  const termsOfUseUrl = process.env.REACT_APP_AKILI_TOU_LINK
  const privacyNoticeUrl = process.env.REACT_APP_AKILI_PP_LINK
  const renderContent = () => {
    const contentData = [
      {
        type: ParagraphContentTypeEnum.String,
        content: model.content[0]
      },
      {
        type: ParagraphContentTypeEnum.Link,
        content: model.content[1],
        href: termsOfUseUrl,
        target: '_blank',
        context: 'tou-link',
        automationLegendId: 'TermsLegendLink'
      },
      {
        type: ParagraphContentTypeEnum.String,
        content: model.content[2]
      },
      {
        type: ParagraphContentTypeEnum.Link,
        content: model.content[3],
        href: privacyNoticeUrl,
        target: '_blank',
        context: 'pn-link',
        automationLegendId: 'PrivacyLegendLink'
      }
    ]
    return contentData
  }

  const renderForm = () => {
    const {
      form: {
        mobileNumber: {
          label: mobileNumberLabel,
          placeholder: mobileNumberPlaceholder
        }
      }
    } = model

    return (
      <div className={styles.form}>
        <Form>
          <Form.Group widths="equal">
            <TextInput
              automationFieldId="MobileNumberField"
              automationLabelId="MobileNumberLabel"
              automationIconId="MobileNumberIcon"
              automationValidationId="MobileNumberValidation"
              context="mobileNumber"
              type="phone-number"
              aria-describedby="paragraph-container"
              label={mobileNumberLabel}
              placeholder={mobileNumberPlaceholder || ''}
              value={mobileNumber.value}
              handleChange={updateMobileNumber}
              error={mobileNumber.hasError}
              errorMessage={mobileNumber.error}
              onFocus={clearMobileNumberError}
            />
          </Form.Group>
        </Form>
        <Paragraph
          automationLegendId="MobileNumberLegend"
          context="info"
          content={renderContent()}
        />
      </div>
    )
  }

  const renderSubmitContent = () => {
    return (
      <div className={styles.buttonContainer}>
        <SecondaryButton
          automationButtonId="NoThanksButton"
          automationSpinnerButtonId="NoThanksSpinnerButton"
          context="cancel"
          isLoading={cancelInProgress}
          caption={model.cancelButtonCaption}
          onClick={handleCancel}
        />
        <PrimaryButton
          automationButtonId="OptInButton"
          automationSpinnerButtonId="OptInSpinnerButton"
          context="submit"
          isLoading={optInInProgress}
          caption={model.continueButtonCaption}
          onClick={handleSubmit}
        />
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter>
      <div className={styles.container}>
        <Header
          title={model.title}
          description={model.description}
          automationTitleId="SMSTitle"
          automationLegendId="SMSLegend"
        />
        {renderErrorBanner()}
        {renderForm()}
        {renderSubmitContent()}
      </div>
    </Layout>
  )
}

export default SMSOptIn
