/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Loading } from '../components/index'

type ProtectedRouteType = {
  component: any
  exact: boolean
  path: string
  handleInvalidUser: (err?: Error) => void
}

const ProtectedRoute = ({
  component: Component,
  handleInvalidUser,
  ...args
}: ProtectedRouteType) => {
  const ExtendedComponent = (props: RouteComponentProps) => (
    <Component handleInvalidUser={handleInvalidUser} {...props} />
  )

  return (
    <Route
      component={withAuthenticationRequired(ExtendedComponent, {
        onRedirecting: () => <Loading />
      })}
      {...args}
    />
  )
}

export default ProtectedRoute
