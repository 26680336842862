import APIService from '../../utils/api/api-service'

type Data = {
  email: string
}

type Response = {
  success: boolean
  status: any
  requestParams: any
  responsePayload: any
  localizedErrorMessage: any
}

const passwordResetApi = (data: Data, clientId: string): Promise<Response> => {
  const Auth0API = new APIService(process.env.REACT_APP_AUTH0_BASE_URL)
  const url = '/dbconnections/change_password'
  const name = 'passwordReset'
  const payload = {
    client_id: clientId || process.env.REACT_APP_AUTH0_CLIENT_ID,
    email: data.email,
    connection: process.env.REACT_APP_AUTH0_CONNECTION
  }

  return Auth0API.post(url, name, payload)
}
export default passwordResetApi
