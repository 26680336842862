import { createAkiliApi } from '../../utils/api/api-methods'

type Data = {
  nickname: string
  dateOfBirth: string
  gender: string
  relationship?: string
}

type RequestData = {
  nickname: string
  birthdate: string
  gender: string | null
  relationship_to_patient?: string
}

/*
  400	- bad request errors / request validation error
  401	- unauthorized
*/

type ErrorResponse = {
  success: false
  status: 400 | 401
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
  responsePayload: {
    profileId: string
    auth_id: string
    email: string
  }
}

const url = '/signup/patient'
const name = 'registerPatient'

const registerPatientAPI = (
  authToken: string,
  data: Data
): Promise<ErrorResponse | SuccessResponse> => {
  const payload = {
    nickname: data.nickname,
    birthdate: data.dateOfBirth,
    gender: data.gender
  } as RequestData

  if (data.relationship) {
    payload.relationship_to_patient = data.relationship
  }

  if (data.gender === 'P') {
    payload.gender = null
  }

  return createAkiliApi(authToken, url, 'POST', name, payload)
}

export default registerPatientAPI
