import { createAkiliApi } from '../../utils/api/api-methods'

type DemographicsPayloadType = {
  patient_id?: string
  patient_ethnicity?: string
  patient_race?: string[]
  household_income?: number
  household_size?: number
}

/*
  // documentation: https://gitlab.com/akiliinteractive/activation-code-service/-/blob/develop/DEVELOPER-API-SWAGGER.yml
  400	- bad request errors
  404	- activation code not found
  409	- conflict with the existing activation code
  410	- activation code expired
  429	- Too Many Requests
  500	- Internal Server Error
*/

type ErrorResponse = {
  success: false
  status: 400 | 401 | 503
  localizedErrorMessage: string
  requestParams: DemographicsPayloadType
  responsePayload: {
    code: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: DemographicsPayloadType
  responsePayload: { treatment_config: string }
}

const url = '/demographics'
const name = 'saveDemographics'

const saveDemographicsAPI = (
  authToken: string,
  payload: DemographicsPayloadType
): Promise<ErrorResponse | SuccessResponse> => {
  return createAkiliApi(authToken, url, 'POST', name, payload)
}

export default saveDemographicsAPI
