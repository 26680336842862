import React, { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Layout } from '@components'
import { routes } from '@utils'

const Logout: FC = () => {
  const screenId = 'logout-screen'

  const { logout } = useAuth0()

  logout({
    returnTo: `${window.location.origin}/${routes.login}`
  })

  return <Layout context={screenId} />
}

export default Logout
