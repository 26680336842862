import React, { FC } from 'react'

type AkiliLogoProps = {
  fill?: string
  width?: number
  height?: number
}

const AssistLogo: FC<AkiliLogoProps> = ({
  fill = '#1140e9',
  width = 24,
  height = 24
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <path
          d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm6.084-6.73l-4.262-9.14c-.417-.895-1.448-1.358-2.377-1.02A1.895 1.895 0 008.37 5.693l4.279 9.177a1.895 1.895 0 103.435-1.601m4.687 2.17c1.404 0 2.514 1.138 2.514 2.541a2.508 2.508 0 01-2.514 2.523c-1.394 0-2.486-1.128-2.486-2.523 0-1.403 1.092-2.541 2.486-2.541zm0 .66c-1.018 0-1.807.835-1.807 1.881s.789 1.853 1.807 1.853c1.028 0 1.826-.807 1.826-1.853 0-1.046-.798-1.88-1.826-1.88zm.11.67c.496 0 .982.22.982.817 0 .334-.16.567-.388.705l-.09.047.542.734h-.706l-.44-.633h-.313v.633h-.623V16.77h1.036zm-.11.542h-.303v.568h.303c.321 0 .459-.082.459-.293 0-.16-.105-.251-.347-.271l-.112-.004zM5.908 12.183c1.094 0 1.973.921 1.904 2.03a1.917 1.917 0 01-1.782 1.782 1.908 1.908 0 11-.122-3.812z"
          id="path-akili-accout-icon"
        />
      </defs>
      <g id="g_icons_akili_account" fill="none" fillRule="evenodd">
        <use
          id="u_icon_akili_account"
          fill={fill}
          href="#path-akili-accout-icon"
          xlinkHref="#path-akili-accout-icon"
        />
      </g>
    </svg>
  )
}
export default AssistLogo
