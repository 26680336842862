import { createAkiliApi } from '../../utils/api/api-methods'

type RequestData = Record<string, never>
type ErrorResponse = {
  success: false
  status: 400 | 401
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
}

const url = '/auth0/verification-email?app=webreg'
const name = 'sendEmailVerification'

const sendEmailVerificationAPI = (
  authToken: string
): Promise<ErrorResponse | SuccessResponse> => {
  return createAkiliApi(authToken, `${url}`, 'POST', name)
}

export default sendEmailVerificationAPI
