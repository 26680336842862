import React, { Component } from 'react'

import { Analytics } from '../../utils'
import { SecondaryButton, Iconography, Maze, Footer } from '../../components'

import PageMessages from './model.en.json'
import styles from './styles.module.scss'

const SetAnimationMethod = (domElement, styling, typeOfAnimation, timeOut) => {
  setTimeout(() => {
    if (domElement) {
      domElement.classList.add(styling[typeOfAnimation])
    }
  }, timeOut)
}

const TypesOfAnimation = {
  rotation: 'rotation_animation'
}
const TimeOutForAnimations = {
  rotation_animation: 1000
}
const TypesOfMinimalIcon = {
  leftArrow: 'leftArrow'
}

const SCREEN_NAME = 'Invalid Route'
export default class RouteNotFound extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    mazeWidth: 0
  }

  componentDidMount() {
    Analytics.recordEvent('Screen_viewed', null, 'MIXPANEL', {
      name: SCREEN_NAME,
      path: window.location.pathname,
      search: window.location.search?.substring(1)
    })

    SetAnimationMethod(
      this.backgroundContainer,
      styles,
      TypesOfAnimation.rotation,
      TimeOutForAnimations[TypesOfAnimation.rotation]
    )

    this.setGameContainerWidth()
  }

  setGameContainerWidth = () => {
    if (!this.mazeContainer) {
      return
    }

    const { mazeWidth } = this.state

    if (mazeWidth === this.mazeContainer.clientWidth) {
      return
    }

    this.setState({
      mazeWidth: this.mazeContainer.clientWidth || 0
    })
  }

  handleReturnHomeClick = () => {
    window.open('/', '_self')
  }

  renderGame = () => {
    const { mazeWidth } = this.state

    return (
      <div
        className={styles.game_container}
        ref={(element) => {
          this.mazeContainer = element
        }}
      >
        <Maze width={mazeWidth} onMazeComplete={this.handleReturnHomeClick} />
      </div>
    )
  }

  renderIcon = () => {
    return (
      <div className={styles.icon_container}>
        <Iconography
          id="secondary-button"
          name={TypesOfMinimalIcon.leftArrow}
          fill="#ffffff"
          // fill={Colors.white}
          width={24}
          height={24}
        />
      </div>
    )
  }

  renderText = () => {
    const { header, subheader, link } = PageMessages

    return (
      <div className={styles.text_container}>
        <div className={styles.header}>{header}</div>
        <div className={styles.subheader}>{subheader}</div>
        <div className={styles.home_button_container}>
          {this.renderIcon()}
          <SecondaryButton
            context="return_home"
            caption={link}
            className={styles.return_home_link}
            onClick={() => window.open('/', '_self')}
          />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div
        className={styles.not_found_page}
        // eslint-disable-next-line no-return-assign
        ref={(el) => (this.backgroundContainer = el)}
      >
        <div className={styles.page_container}>
          <div className={`${styles.grid_item} ${styles.left_margin}`} />
          <div className={`${styles.grid_item} ${styles.page_content}`}>
            {this.renderGame()}
            {this.renderText()}
          </div>
          <div className={`${styles.grid_item} ${styles.right_margin}`} />
        </div>
        <Footer placeholderURLs={false} theme="light" overlayContent />
      </div>
    )
  }
}
