import {
  setWithObjectProperties,
  setValue,
  getValue,
  removeValue
} from './storageUtils'

// eslint-disable-next-line import/prefer-default-export
export class SessionStorage {
  static setObjectProperties(paramObject) {
    return setWithObjectProperties(sessionStorage, paramObject)
  }

  static set(key, value) {
    return setValue(sessionStorage, key, value)
  }

  static get(key) {
    return getValue(sessionStorage, key)
  }

  static remove(key) {
    return removeValue(sessionStorage, key)
  }
}
