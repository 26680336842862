import React, { FC } from 'react'

type RightArrowProps = {
  fill?: string
  width?: number
  height?: number
  stroke?: string
  scale?: number
  extraClassName?: string
}

const RightArrow: FC<RightArrowProps> = ({
  fill = 'white',
  width = 24,
  height = 24
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <path
          d="M12.7099466,4.70994663 C12.3178539,4.31785393 11.6870753,4.3129247 11.295,4.705 L11.295,4.705 C10.9056393,5.09436075 10.9059928,5.72655912 11.2967109,6.11797736 L16.17,11 L4.99592218,11 C4.44588955,11 4,11.4438648 4,12 L4,12 C4,12.5522847 4.44756998,13 4.99592218,13 L16.17,13 L11.2967109,17.8820226 C10.9064052,18.2730277 10.9029247,18.9029247 11.295,19.295 L11.295,19.295 C11.6843607,19.6843607 12.3235178,19.6764822 12.7099466,19.2900534 L19.2900534,12.7099466 C19.6821461,12.3178539 19.6764822,11.6764822 19.2900534,11.2900534 L12.7099466,4.70994663 Z"
          id="path-right-arrow-icon"
        />
      </defs>
      <g id="g_icons_right_arrow" fill="none" fillRule="evenodd">
        <use
          id="u_icon_right_arrow"
          fill={fill}
          href="#path-right-arrow-icon"
          xlinkHref="#path-right-arrow-icon"
        />
      </g>
    </svg>
  )
}

export default RightArrow
