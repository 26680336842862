import cleanBirthDate from './clean-birth-date'

const validateFormTextField = (
  field: string,
  configs: { form: { [field: string]: { [fieldKey: string]: any } } },
  state: { value: string; hasError: boolean; error: string },
  isDate?: boolean
): boolean => {
  let validationError = false

  const {
    form: {
      [field]: { errorTypes }
    }
  } = configs

  const { value } = state
  let fixValue = value
  if (isDate) {
    fixValue = cleanBirthDate(value)
  }

  if (errorTypes.required && !fixValue) {
    validationError = true
  } else if (errorTypes.minChar && fixValue.length < errorTypes.minChar) {
    validationError = true
  } else if (errorTypes.maxChar && fixValue.length > errorTypes.maxChar) {
    validationError = true
  } else if (
    errorTypes.minAZaz &&
    fixValue.replace(/[^a-zA-Z]+/g, '').length < errorTypes.minAZaz
  ) {
    validationError = true
  }

  return validationError
}

const validateFormPhoneField = (
  field: string,
  configs: { form: { [field: string]: { [fieldKey: string]: any } } },
  state: { value: string; hasError: boolean; error: string }
): boolean => {
  let validationError = false

  const {
    form: {
      [field]: { errorTypes }
    }
  } = configs

  const { value } = state
  const phone = value

  if (errorTypes.required && !phone) {
    validationError = true
  } else if (phone && phone.length < 10) {
    validationError = true
  } else if (phone && phone.length > 10) {
    validationError = true
  }

  return validationError
}

const validateDate = (date: (string | number)[]): boolean => {
  const year = Number(date[0])
  const month = Number(date[1]) - 1 // 0 index month
  const day = Number(date[2])

  const d = new Date(year, month, day, 12, 0, 0, 0)

  const yearMatches = d.getFullYear() === year
  const monthMatches = d.getMonth() === month
  const dayMatches = d.getDate() === day

  return yearMatches && monthMatches && dayMatches
}

const validateFormBirthDateField = (
  field: string,
  configs: { form: { [field: string]: { [fieldKey: string]: any } } },
  state: { value: string; hasError: boolean; error: string }
): boolean => {
  let validationError = false
  const { value } = state

  const {
    form: {
      [field]: { errorTypes }
    }
  } = configs

  const fixValue = cleanBirthDate(value, true)
  const month = fixValue.split('|')[0]
  const year = fixValue.split('|')[1]

  const date = new Date(`${year}-${month}-01T00:00:00`)
  const currentDate = new Date()

  if (validateFormTextField(field, configs, state, true)) {
    validationError = true
  } else if (Number.isNaN(date.valueOf()) || !validateDate([year, month, 1])) {
    // not a valid date
    validationError = true
  } else if (
    year < errorTypes.minYear ||
    date.valueOf() > currentDate.setHours(0, 0, 0, 0).valueOf()
  ) {
    validationError = true
  }

  return validationError
}

const validateFormEmailField = (
  field: string,
  configs: { form: { [field: string]: { [fieldKey: string]: any } } },
  state: { value: string; hasError: boolean; error: string }
): boolean => {
  let validationError = false
  const { value } = state

  const email = value

  if (validateFormTextField(field, configs, state)) {
    validationError = true
  } else if (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,63}))$/
      )
  ) {
    // a valid email

    // {username}@{domain}.{extension} auth0 length limitations
    // note: extension subpart length check is in regex
    const segments = value.split('@')
    const username = segments[0]
    const domain = segments[1].split('.')[0]

    if (username.length > 64 || domain.length > 63) {
      validationError = true
    } else {
      validationError = false
    }
  } else {
    validationError = true
  }

  return validationError
}

const validateFormField = (
  field: string,
  configs: { form: { [field: string]: { [fieldKey: string]: any } } },
  state: { value: string; hasError: boolean; error: string }
): boolean => {
  const {
    form: {
      [field]: {
        errorTypes: { type }
      }
    }
  } = configs

  if (type === 'phone') {
    return validateFormPhoneField(field, configs, state)
  }

  if (type === 'birthdate') {
    return validateFormBirthDateField(field, configs, state)
  }

  if (type === 'email') {
    return validateFormEmailField(field, configs, state)
  }

  return validateFormTextField(field, configs, state)
}

export default validateFormField
