import { createAkiliApi } from '../../utils/api/api-methods'

type Data = {
  nickname: string
  dateOfBirth: string
  gender: string
  relationship?: string
}

type RequestData = {
  nickname: string
  birthdate: string
  gender: string | null
  relationship_to_patient?: string
}

/*
  400	- bad request errors / request validation error
  401	- unauthorized
*/

type ErrorResponse = {
  success: false
  status: 400 | 401
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
}

const updatePatientAPI = (
  authToken: string,
  patientId: string,
  data: Data
): Promise<ErrorResponse | SuccessResponse> => {
  const url = `/patients/${patientId}`
  const name = 'updatePatient'

  const payload = {
    nickname: data.nickname,
    birthdate: data.dateOfBirth,
    gender: data.gender,
    relationship_to_patient: data.relationship || null
  } as RequestData

  if (data.relationship) {
    payload.relationship_to_patient = data.relationship
  }

  return createAkiliApi(authToken, url, 'PATCH', name, payload)
}

export default updatePatientAPI
