import React, { FC } from 'react'
import styles from './styles.module.scss'

type ContentSeparatorProps = {
  extraClassName?: string
  context?: string
  automationSeparatorId?: string
}

const ContentSeparator: FC<ContentSeparatorProps> = ({
  extraClassName = '',
  context,
  automationSeparatorId
}) => {
  const id = context || 'separator'

  return (
    <div
      id={`${id}-container`}
      className={`${styles.content_divider} ${extraClassName}`}
    >
      <div
        id={id}
        className={styles.separator}
        data-aid={automationSeparatorId}
      />
    </div>
  )
}

export default ContentSeparator
