import { createDispensaryApi } from '../../utils/api/api-methods'

type RequestData = {
  treatmentId: string
  patientId: string
  activationCode: string
}

/*
  // documentation: https://gitlab.com/akiliinteractive/activation-code-service/-/blob/develop/DEVELOPER-API-SWAGGER.yml
  400	- bad request errors
  404	- activation code not found
  409	- conflict with the existing activation code
  410	- activation code expired
  429	- Too Many Requests
  500	- Internal Server Error
*/

type ErrorResponse = {
  success: false
  status: 400 | 404 | 409 | 410 | 429 | 500
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    code: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
  responsePayload: { treatment_config: string }
}

const url = '/activate'
const name = 'setActivationCode'

const setActivationCodeAPI = (
  authToken: string,
  data: RequestData
): Promise<ErrorResponse | SuccessResponse> => {
  const payload = {
    activation_code: data.activationCode,
    treatment_id: data.treatmentId,
    patient_uuid: data.patientId
  }

  return createDispensaryApi(authToken, url, name, payload)
}

export default setActivationCodeAPI
