import { createAkiliApi } from '../../utils/api/api-methods'

type RequestData = {
  sms_marketing_opt_in: boolean
}

/*
  400	- bad request errors / request validation error
*/

type ErrorResponse = {
  success: false
  status: 400
  localizedErrorMessage: string
  requestParams: RequestData
  responsePayload: {
    message: string
    description: string
  }
}

type SuccessResponse = {
  success: true
  status: 200
  requestParams: RequestData
  responsePayload: Record<string, unknown>
}

const url = '/legal/marketing'
const name = 'setSmsOpting'

const setSmsOptingApi = (
  authToken: string,
  opting: boolean
): Promise<ErrorResponse | SuccessResponse> => {
  const payload = {
    sms_marketing_opt_in: opting
  } as RequestData

  return createAkiliApi(authToken, url, 'POST', name, payload)
}

export default setSmsOptingApi
