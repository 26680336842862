import React, { FC, SyntheticEvent } from 'react'
import { Form, DropdownProps } from 'semantic-ui-react'

import styles from './styles.module.scss'

type SelectInputProps = {
  style?: React.CSSProperties
  label: string
  placeholder: string
  options: { value: string; text: string; id: string }[]
  value: string
  handleChange: (value: string) => void
  onFocus: () => void
  error: boolean
  errorMessage: string
  context?: string
  automationLabelId?: string
  automationFieldId?: string
  automationValidationId?: string
}

const SelectInput: FC<SelectInputProps> = ({
  style,
  label = '',
  placeholder = '',
  options,
  value,
  handleChange,
  onFocus,
  error = false,
  errorMessage = '',
  context,
  automationLabelId = '',
  automationFieldId = '',
  automationValidationId = ''
}) => {
  const id = context || 'selectInputGroup'

  const hasError = () => {
    return error ? 'error' : ''
  }

  const onSelect = (
    _e: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ): void => {
    handleChange((data.value as string) || '')
  }

  return (
    <div
      id={`${id}-container`}
      className={`field ${styles.text_input} ${hasError()}`}
    >
      <label data-aid={automationLabelId} id={`${id}-label`} htmlFor="input">
        {label}
      </label>
      <Form.Select
        data-aid={automationFieldId}
        style={style}
        fluid
        options={options}
        placeholder={placeholder}
        value={value}
        onChange={onSelect}
        onFocus={onFocus}
      />
      {error && errorMessage && (
        <div data-aid={automationValidationId} className={styles.error_message}>
          {errorMessage}
        </div>
      )}
    </div>
  )
}

export default SelectInput
