import React, { FC, useState, useEffect } from 'react'
import { useLocation, RouteComponentProps } from 'react-router-dom'

import {
  Layout,
  Loading,
  Iconography,
  TransferUserActionButtons
} from '@components'
import { Analytics } from '@utils'

import styles from './styles.module.scss'
import _model from './model.en.json'
import _configs, { ScreensMessagesContent } from './configs'

const configs = _configs as ScreensMessagesContent
type Localization = {
  [status: string]: {
    header: string
    subheader: string
  }
}

const model = _model as Localization

const PasswordUpdateStatus: FC<RouteComponentProps> = () => {
  const screenId = 'password-update-status-screen'
  const SCREEN_NAME = 'Password Update'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(true)
  const [uiConfigs, setUiConfigs] = useState({
    hasIcon: false,
    success: false,
    hasSupportLink: false,
    hasPasswordReset: false,
    hasLogin: false
  })

  const [appContext, setAppContext] = useState({
    hasT01A: false,
    hasT01C: false
  })
  const [resetClientContext, seResetClientContext] = useState('webreg')

  const [uiText, setUiText] = useState({
    header: '',
    subheader: ''
  })

  const [brandVersion, setBrandVersion] = useState(
    process.env.REACT_APP_BRAND_T02
  )

  /** ************* */
  /* UI Content */
  const location = useLocation()
  const T01A_APP = 'T01A-client'

  useEffect(() => {
    const query = new URLSearchParams(location.search)

    const clientId = query.get('context_name') || ''
    let brand =
      process.env[
        `REACT_APP_BRAND_${clientId?.includes(T01A_APP) ? 'T01A' : 'DEFAULT'}`
      ]
    if (!clientId) {
      brand = process.env.REACT_APP_BRAND_T01A
    }
    setBrandVersion(brand)

    const successStatus = query.get('success') as string
    const queryMessage = query.get('message') as string
    const pageVariant = successStatus === 'true' ? 'success' : 'fail'
    if (pageVariant === 'fail') {
      if (!clientId) {
        setAppContext({
          hasT01A: true,
          hasT01C: true
        })
      } else {
        setAppContext({
          hasT01A: clientId?.includes(T01A_APP),
          hasT01C: !clientId?.includes(T01A_APP)
        })

        const resetContext = clientId?.includes(T01A_APP) ? 'T01A' : 'webreg'
        seResetClientContext(resetContext)
      }
    }

    setUiConfigs(configs.status[pageVariant])
    if (pageVariant === 'success') {
      setUiText(model[pageVariant])
    } else if (!clientId) {
      setUiText(model.fail)
    } else if (clientId?.includes(T01A_APP)) {
      setUiText(model.failT01A)
    } else {
      setUiText(model.failT01C)
    }

    setLoading(false)

    Analytics.recordEvent(configs.analytics.viewed_event, null, 'MIXPANEL', {
      name: SCREEN_NAME
    })

    Analytics.recordEvent(
      configs.analytics.error_event.name,
      null,
      'MIXPANEL',
      {
        [configs.analytics.error_event.segment.success]:
          successStatus === 'true',
        [configs.analytics.error_event.segment.code]: null,
        [configs.analytics.error_event.segment.message]: queryMessage
      }
    )
  }, [])

  const renderTransferButtons = () => {
    const { hasLogin, hasSupportLink } = uiConfigs

    if (!hasLogin && !hasSupportLink) {
      return null
    }

    if (appContext.hasT01A && !appContext.hasT01C) {
      return (
        <div>
          <div className={styles.contact}>
            {process.env.REACT_APP_ENDEAVOROTC_SUPPORT}
          </div>
        </div>
      )
    }

    return (
      <TransferUserActionButtons
        context={screenId}
        displayLogin={hasLogin}
        displayAkiliAssist={appContext.hasT01C}
        displayOtcSupport={appContext.hasT01A}
        brandVersion={brandVersion}
      />
    )
  }

  const renderErrorDetails = () => {
    const query = new URLSearchParams(location.search)
    const errorMessage = query.get('message') as string

    if (!errorMessage) {
      return null
    }

    const { header, subheader } = model.error_details

    return (
      <div className={styles.errorDetails}>
        <div className={styles.title}>&#x1F41E; {header}</div>
        {errorMessage && <div>{`${subheader} ${errorMessage}`}</div>}
      </div>
    )
  }

  const renderBody = () => {
    const brandingColor =
      process.env[`REACT_APP_BRAND_COLOR_${brandVersion?.toUpperCase()}`]

    const resetLink = `/password-reset?client_name_id=${resetClientContext}`
    return (
      <div className={styles.body}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            {uiConfigs.hasIcon && (
              <div className={styles.icon_container}>
                <Iconography
                  width={64}
                  height={64}
                  name={uiConfigs.success ? 'checkInCircle' : 'warning'}
                  fill={brandingColor}
                />
              </div>
            )}
            <div className={styles.header}>{uiText.header}</div>
            <TransferUserActionButtons
              context={screenId}
              displayPasswordReset={uiConfigs.hasPasswordReset}
              passwordResetURL={resetLink}
              brandVersion={brandVersion}
            />
            <div className={styles.subheader}>{uiText.subheader}</div>
            {renderTransferButtons()}
          </div>
          {!uiConfigs.success && renderErrorDetails()}
        </div>
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter brandVersion={brandVersion}>
      <div className={styles.container}>
        {isLoading && <Loading fullScreen />}
        {!isLoading && renderBody()}
      </div>
    </Layout>
  )
}

export default PasswordUpdateStatus
