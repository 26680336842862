export type ScreensMessagesContent = {
  analytics: {
    viewed_event: string
    error_event: {
      name: string
      segment: {
        [err: string]: string
      }
    }
  }
  typesOfScreens: { [key: string]: string }
  uRLQueryStrings: { [key: string]: string }
  status: {
    [types: string]: {
      hasIcon: boolean
      success: boolean
      hasLogin: boolean
      hasAkiliAssist: boolean
    }
  }
}

// for local dev testing
// localhost:9000/user-email-verification?context_name=dev-X01-mobile-client&supportSignUp=true&supportForgotPassword=true&email=cotter%2B0810_blockacc%40akiliinteractive.com&message=Your+email+was+verified.+You+can+continue+using+the+application.&success=true&code=success

const screensMessagesContent = {
  analytics: {
    viewed_event: 'Screen_viewed',
    error_event: {
      name: 'Auth0Status',
      segment: {
        code: 'auth0_code',
        message: 'auth0_message',
        success: 'auth0_success'
      }
    }
  },
  typesOfScreens: {
    success: 'success',
    expired: 'expired',
    already: 'already',
    used_once: 'used_once',
    generic: 'generic'
  },
  uRLQueryStrings: {
    success: 'Your email was verified. You can continue using the application.',
    expired: 'Access expired.',
    already: 'This account is already verified.',
    used_once: 'This URL can be used only once'
  },
  status: {
    success: {
      hasIcon: true,
      success: true,
      hasAkiliAssist: false,
      hasLogin: false
    },
    expired: {
      hasIcon: true,
      success: false,
      hasAkiliAssist: true,
      hasLogin: false
    },
    already: {
      hasIcon: true,
      success: true,
      hasAkiliAssist: false,
      hasLogin: false
    },
    used_once: {
      hasIcon: true,
      success: true,
      hasAkiliAssist: false,
      hasLogin: false
    },
    generic: {
      hasIcon: true,
      success: false,
      hasAkiliAssist: true,
      hasLogin: false
    }
  }
}
export default screensMessagesContent
