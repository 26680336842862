type Configs = {
  analytics: {
    viewed: string
    submitted: string
  }
  form: {
    [field: string]: {
      maxLength?: number
      errorTypes:
        | Record<string, never>
        | { [key: string]: string | boolean | number }
    }
  }
}

const configs = {
  analytics: {
    viewed: 'Screen_viewed',
    submitted: 'PasswordReset_submitted'
  },
  form: {
    email: {
      maxLength: 192,
      errorTypes: {
        required: true,
        minChar: 1,
        maxChar: 192,
        type: 'email'
      }
    }
  }
} as Configs

export default configs
