import React, { FC } from 'react'

type NumberInputProps = {
  placeholder: string
  value: string
  maxLength?: number
  context?: string
  dataId?: string
  onChange: (value: string, id?: string) => void
  onFocus: () => void
  automationFieldId?: string
}

const NumberInputInput: FC<NumberInputProps> = ({
  placeholder,
  value,
  maxLength,
  context,
  dataId,
  onChange,
  onFocus,
  automationFieldId
}) => {
  const id = context || 'number'

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(val.replace(/[^\d]/g, ''), dataId || '')
  }

  return (
    <input
      data-aid={automationFieldId}
      id={`${id}-input`}
      type="text"
      inputMode="numeric"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      maxLength={maxLength ?? 12}
      onFocus={onFocus}
    />
  )
}

export default NumberInputInput
