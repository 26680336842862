import React, { FC } from 'react'

type CurrencyInputProps = {
  placeholder: string
  value: string
  maxLength?: number
  context?: string
  dataId?: string
  onChange: (value: string, id?: string) => void
  onFocus: () => void
  automationFieldId?: string
}

const CurrencyInput: FC<CurrencyInputProps> = ({
  placeholder,
  value,
  maxLength,
  context,
  dataId,
  onChange,
  onFocus,
  automationFieldId
}) => {
  const id = context || 'currency'

  const formattedCurrency = (string: string) => {
    const numberString = string.replace(/[^\d]/g, '')
    let formattedValue = ''
    if (numberString.length > 0) {
      const number = parseInt(numberString, 10)
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      })
      formattedValue = formatter.format(number)
    }
    return formattedValue
  }

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(val.replace(/[^\d]/g, ''), dataId || '')
  }

  return (
    <input
      data-aid={automationFieldId}
      id={`${id}-input`}
      type="text"
      inputMode="numeric"
      placeholder={placeholder}
      value={formattedCurrency(value)}
      onChange={handleChange}
      maxLength={maxLength ?? 12}
      onFocus={onFocus}
    />
  )
}

export default CurrencyInput
