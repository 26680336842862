const ObjectPropertiesToCamelCase = (source, includeSpaces = false) => {
  if (source === undefined) {
    return
  }

  const object = JSON.parse(JSON.stringify(source))
  let origKey
  let newKey
  let value

  if (object instanceof Array) {
    // eslint-disable-next-line consistent-return
    return object.map((val) => {
      let newVal
      if (typeof val === 'object') {
        newVal = ObjectPropertiesToCamelCase(val)
      }
      return newVal
    })
  }

  const newObject = {}

  const regex = includeSpaces ? /[-_\s]+(.)?/g : /[-_]+(.)?/g

  // eslint-disable-next-line no-restricted-syntax
  for (origKey in object) {
    if (object.hasOwnProperty(origKey)) {
      newKey = origKey.replace(regex, function (match, chr) {
        return chr ? chr.toUpperCase() : ''
      })

      if (includeSpaces) {
        newKey = newKey.substr(0, 1).toLowerCase() + newKey.substr(1)
      }

      value = object[origKey]

      if (
        value instanceof Array ||
        (value !== null && value.constructor === Object)
      ) {
        value = ObjectPropertiesToCamelCase(value)
      }

      newObject[newKey] = value
    }
  }

  // eslint-disable-next-line consistent-return
  return newObject
}

export default ObjectPropertiesToCamelCase
