import React, { FC, useState, useEffect } from 'react'
import { useLocation, RouteComponentProps } from 'react-router-dom'

import { Layout, Loading, Iconography } from '@components'
import { Analytics } from '@utils'

import styles from './styles.module.scss'
import _model from './model.en.json'
import _configs, { ScreensMessagesContent } from './configs'

const configs = _configs as ScreensMessagesContent
type Localization = {
  errors: {
    [status: string]: {
      header: string
      subheader: string
    }
  }
  error_details: {
    header: string
    subheader: string
  }
  success: {
    description: string
  }
}

const model = _model as Localization

const VerifyEmailOtc: FC<RouteComponentProps> = () => {
  const screenId = 'verify-email-otc-screen'
  const SCREEN_NAME = 'Verify Email OTC'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(true)
  const [verifyIsSuccess, setVerifyIsSuccess] = useState(false)

  const [uiErrConfigs, setUiErrConfigs] = useState({
    hasIcon: false,
    hasSupportLink: false,
    hasLogin: false
  })
  const [uiErrText, setUiErrText] = useState({
    header: '',
    subheader: ''
  })

  const brandVersion = process.env.REACT_APP_BRAND_T01A

  /** ************* */
  /* UI Content */
  const location = useLocation()

  const getPageVariant = (message: string) => {
    let type = configs.typesOfScreens.generic

    if (message === configs.uRLQueryStrings.expired) {
      type = configs.typesOfScreens.expired
    } else if (message === configs.uRLQueryStrings.already) {
      type = configs.typesOfScreens.already
    } else if (message === configs.uRLQueryStrings.used_once) {
      type = configs.typesOfScreens.used_once
    }

    return type
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const successStatus = query.get('success') as string
    const queryMessage = query.get('message') as string

    let showSuccessUi = successStatus === 'true'
    let errConfig = uiErrConfigs

    if (!showSuccessUi) {
      const pageVariant = getPageVariant(queryMessage)

      showSuccessUi = configs.successScreenStatus[pageVariant]

      if (!showSuccessUi) {
        errConfig = configs.errorStatus[pageVariant]
        setUiErrConfigs(errConfig)
        setUiErrText(model.errors[pageVariant])
      }
    }
    setVerifyIsSuccess(showSuccessUi)

    setLoading(false)

    Analytics.recordEvent(configs.analytics.viewed_event, null, 'MIXPANEL', {
      name: SCREEN_NAME
    })

    Analytics.recordEvent(
      configs.analytics.error_event.name,
      null,
      'MIXPANEL',
      {
        [configs.analytics.error_event.segment.success]:
          successStatus === 'true',
        [configs.analytics.error_event.segment.code]: null,
        [configs.analytics.error_event.segment.message]: queryMessage
      }
    )
  })

  const renderErrorDetails = () => {
    const query = new URLSearchParams(location.search)
    const errorMessage = query.get('message') as string

    if (!errorMessage) {
      return null
    }

    const { header, subheader } = model.error_details

    return (
      <div className={styles.errorDetails}>
        <div className={styles.title}>&#x1F41E; {header}</div>
        {errorMessage && <div>{`${subheader} ${errorMessage}`}</div>}
      </div>
    )
  }

  const renderSuccessUiVariant = () => {
    const logo = `${process.env.REACT_APP_STATIC_CONTENT}/auth0resources/logo-EndeavorOTC-stacked.png`
    return (
      <div className={styles.body}>
        <div className={styles.success}>
          <img width="350px" src={logo} />
          <div className={styles.description}>{model.success.description}</div>
        </div>
      </div>
    )
  }

  const renderFailUiVariant = () => {
    const brandingColor = process.env.REACT_APP_BRAND_COLOR_V2

    return (
      <div className={styles.body}>
        <div className={styles.failContentContainer}>
          <div className={styles.failContent}>
            {uiErrConfigs.hasIcon && (
              <div className={styles.icon_container}>
                <Iconography
                  width={64}
                  height={64}
                  name="warning"
                  fill={brandingColor}
                />
              </div>
            )}
            <div className={styles.header}>{uiErrText.header}</div>
            <div className={styles.subheader}>{uiErrText.subheader}</div>
            <div className={styles.contact}>
              {process.env.REACT_APP_ENDEAVOROTC_SUPPORT}
            </div>
          </div>
          {renderErrorDetails()}
        </div>
      </div>
    )
  }

  const renderBody = () => {
    return verifyIsSuccess ? renderSuccessUiVariant() : renderFailUiVariant()
  }

  return (
    <Layout context={screenId} hasFooter brandVersion={brandVersion}>
      <div className={styles.container}>
        {isLoading && <Loading fullScreen />}
        {!isLoading && renderBody()}
      </div>
    </Layout>
  )
}

export default VerifyEmailOtc
