import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  useDecision,
  withOptimizely,
  WithOptimizelyProps
} from '@optimizely/react-sdk'

import {
  Layout,
  Header,
  Iconography,
  ContentSeparator,
  MediaVideoEmbed,
  Loading
} from '@components'
import { Analytics } from '@utils'
import { HeaderDescriptionTypeEnum } from '../../components/Header'
import styles from './styles.module.scss'
import model from './model.en.json'
import configs from './configs'
import staticMediaImage from './assets/StaticMediaImage.png'
import iOSStoreImg from './assets/iOS_store.png'
import androidStoreImg from './assets/android_store.png'

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const PRODUCT_CODE_T01 = process.env.REACT_APP_TO1_PRODUCT_CODE
const PRODUCT_CODE_C01 = process.env.REACT_APP_C01_PRODUCT_CODE
const PRODUCT_CODE_WEB = process.env.REACT_APP_WEB_PRODUCT_CODE

const OnboardingComplete: FC<
  RouteComponentProps & CustomProps & WithOptimizelyProps
> = ({ optimizely }) => {
  const screenId = 'onboarding-complete-screen'
  const SCREEN_NAME = 'Onboarding Complete'

  const [stepsFormat, setStepsFormat] = useState({
    displayDefault: true,
    isLoading: true
  })
  const [downloadOrder, setDownloadOrder] = useState({
    displayDefault: true,
    isLoading: true
  })

  /** ************* */
  /* Record the viewed event to mixpanel the first time */
  useEffect(() => {
    Analytics.recordEvent(configs.analytics.viewed, null, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  const [stepsFormatFormat, stepsFormatClientReady, stepsFormatDidTimeout] =
    useDecision(
      process.env
        .REACT_APP_EXPERIMENTATION_ONBOARDING_COMPLETE_STEPS_FORMAT as string,
      { autoUpdate: true }
    )

  if (
    stepsFormat.isLoading &&
    (stepsFormatClientReady || stepsFormatDidTimeout) &&
    stepsFormatFormat
  ) {
    if (stepsFormatDidTimeout || stepsFormatFormat.variationKey === null) {
      // TODO - MP log optimizely failed to make a decision
      setStepsFormat({
        displayDefault: true,
        isLoading: false
      })
    } else {
      const displayDefaultListFormat = !stepsFormatFormat.enabled
      setStepsFormat({
        displayDefault: displayDefaultListFormat,
        isLoading: false
      })
    }
  }

  const [
    reverseDownloadOrderDecision,
    reverseDownloadOrderClientReady,
    reverseDownloadOrderDidTimeout
  ] = useDecision(
    process.env
      .REACT_APP_EXPERIMENTATION_ONBOARDING_COMPLETE_APP_ORDER as string,
    { autoUpdate: true }
  )

  if (
    downloadOrder.isLoading &&
    (reverseDownloadOrderClientReady || reverseDownloadOrderDidTimeout) &&
    reverseDownloadOrderDecision
  ) {
    if (
      reverseDownloadOrderDidTimeout ||
      reverseDownloadOrderDecision.variationKey === null
    ) {
      setDownloadOrder({
        displayDefault: true,
        isLoading: false
      })
    } else {
      const displayDefaultDownloadOrder = !reverseDownloadOrderDecision.enabled
      setDownloadOrder({
        displayDefault: displayDefaultDownloadOrder,
        isLoading: false
      })
    }
  }

  /** ************* */
  /* Record the click event to mixpanel */
  const handleEndeavorRxClick = (os: string) => {
    Analytics.recordEvent(configs.analytics.clicked, null, 'MIXPANEL', {
      platform: os,
      product: PRODUCT_CODE_WEB,
      app_code: PRODUCT_CODE_T01
    })
    optimizely?.track('click_download_T01')
  }

  /** ************* */
  const renderMediaContainer = () => {
    const mediaId = process.env.REACT_APP_ONBOARDING_COMPLETE_VIDEO
    const mediaPlaceholder = staticMediaImage

    const className = stepsFormat.displayDefault
      ? styles.media_container_default
      : styles.media_container_variant

    const onClick = () => {
      optimizely?.track('click_video_thumbnail')
    }

    return (
      <div className={className}>
        <MediaVideoEmbed
          embedId={mediaId}
          placeholderImg={mediaPlaceholder}
          automationVideoId="PlayVideo"
          onClick={onClick}
        />
      </div>
    )
  }

  /* Record the click event to mixpanel */
  const handleEndeavorRxInsightClick = (os: string) => {
    Analytics.recordEvent(configs.analytics.clicked, null, 'MIXPANEL', {
      platform: os,
      product: PRODUCT_CODE_WEB,
      app_code: PRODUCT_CODE_C01
    })
    optimizely?.track('click_download_C01')
  }

  const renderStoreBadges = (
    appleUrl: string,
    androidUrl: string,
    extraClassName: string,
    automationAppleStoreId: string,
    automationGoogleStoreId: string,
    onClick: (a: string) => void
  ) => {
    return (
      <div className={styles[`${extraClassName}_container`]}>
        <div className={`${styles.icons}`}>
          <div className={styles.store_icons}>
            <a
              id="ios-store-link"
              data-aid={automationAppleStoreId}
              className={styles.link}
              href={appleUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                onClick('iOS')
              }}
            >
              <img src={iOSStoreImg} />
            </a>
            <a
              id="android-store-link"
              data-aid={automationGoogleStoreId}
              className={styles.link}
              href={androidUrl}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                onClick('Android')
              }}
            >
              <img src={androidStoreImg} />
            </a>
          </div>
        </div>
      </div>
    )
  }
  const renderEndeavorRxSection = () => {
    const appleT01Url = process.env.REACT_APP_T01_IOS_URL
    const androidT01Url = process.env.REACT_APP_T01_ANDROID_URL

    return (
      <>
        <div id="logo-container" className={styles.logo_container}>
          <Iconography
            context="t01-logo"
            name="t01Logo"
            automationIconId="EndeavorLogo"
            extraClassName={styles.logo}
          />
          <div className={styles.appstores_details}>
            <div>
              <span className={styles.title} data-aid="EndeavorTitle">
                {model.downloadEndeavorRxTitle}
              </span>
              <span data-aid="EndeavorLegend">
                {model.downloadEndeavorRxDescription}
              </span>
            </div>
            {renderStoreBadges(
              appleT01Url,
              androidT01Url,
              'logos_inline',
              'EndeavorAppleButton',
              'EndeavorGoogleButton',
              handleEndeavorRxClick
            )}
          </div>
        </div>
        {renderStoreBadges(
          appleT01Url,
          androidT01Url,
          'logos_separate',
          'EndeavorAppleButton',
          'EndeavorGoogleButton',
          handleEndeavorRxClick
        )}
      </>
    )
  }

  const renderEndeavorRxInsightSection = () => {
    const appleC01Url = process.env.REACT_APP_C01_IOS_URL
    const androidC01Url = process.env.REACT_APP_C01_ANDROID_URL

    return (
      <>
        <div id="logo-container" className={styles.logo_container}>
          <Iconography
            context="c01-logo"
            name="c01Logo"
            automationIconId="C01Logo"
            extraClassName={styles.logo}
          />
          <div className={styles.appstores_details}>
            <div>
              <span className={styles.title} data-aid="C01Title">
                {model.downloadEndeavorRxInsightTitle}
              </span>
              <span data-aid="C01Legend">
                {model.downloadEndeavorRxInsightDescription}
              </span>
            </div>
            {renderStoreBadges(
              appleC01Url,
              androidC01Url,
              'logos_inline',
              'C01AppleButton',
              'C01GoogleButton',
              handleEndeavorRxInsightClick
            )}
          </div>
        </div>
        {renderStoreBadges(
          appleC01Url,
          androidC01Url,
          'logos_separate',
          'C01AppleButton',
          'C01GoogleButton',
          handleEndeavorRxInsightClick
        )}
      </>
    )
  }

  const renderTextSection = () => {
    const supportUrl = process.env.REACT_APP_AKILI_ASSIST_URL

    return (
      <div id="assist-container" className={styles.body_text}>
        <span id="assist-description-start" data-aid="HelpLegend">
          {model.bodyInfoStart}
        </span>
        <a
          id="assist-link"
          data-aid="AkiliAssistLink"
          className={styles.link}
          href={supportUrl}
          target="_blank"
          rel="noreferrer"
        >
          {model.bodyInfoLink}
        </a>
        <span id="assist-description-start">{model.bodyInfoEnd}</span>
      </div>
    )
  }

  const renderDefaultContent = () => {
    const { displayDefault } = downloadOrder
    return (
      <div id="experiment-default-content" className={styles.container}>
        <Header
          title=""
          showSeparator={false}
          description={[
            {
              type: HeaderDescriptionTypeEnum.String,
              content: model.headerDescription,
              className: styles.bold,
              automationLegendId: 'DownloadLegend'
            }
          ]}
        />
        <div className={styles.body}>
          {renderMediaContainer()}
          <ContentSeparator
            extraClassName={styles.content_divider}
            automationSeparatorId="SeparationLine1"
          />
          {displayDefault
            ? renderEndeavorRxSection()
            : renderEndeavorRxInsightSection()}
          <ContentSeparator
            extraClassName={styles.content_divider}
            automationSeparatorId="SeparationLine2"
          />
          {displayDefault
            ? renderEndeavorRxInsightSection()
            : renderEndeavorRxSection()}
          <ContentSeparator
            extraClassName={styles.content_divider}
            automationSeparatorId="SeparationLine3"
          />
          {renderTextSection()}
        </div>
      </div>
    )
  }

  const renderStep = (n: number) => {
    return (
      <div className={styles.stepsContainer}>
        <div className={styles.stepsTitle} data-aid={`Step${n}`}>
          {model[`step${n}Title`]}
        </div>
        {n === 1 && (
          <div className={styles.stepsInfo} data-aid={`InfoStep${n}`}>
            {model[`step${n}Info`]}
          </div>
        )}
      </div>
    )
  }

  const renderVariantContent = () => {
    const { displayDefault } = downloadOrder
    return (
      <div id="experiment-test-content" className={styles.container}>
        <Header
          title=""
          showSeparator={false}
          description={[
            {
              type: HeaderDescriptionTypeEnum.String,
              content: model.headerDescription,
              className: styles.bold,
              automationLegendId: 'DownloadLegend'
            }
          ]}
        />
        <div className={styles.body}>
          {renderStep(1)}
          {renderMediaContainer()}
          <ContentSeparator
            extraClassName={styles.content_divider}
            automationSeparatorId="SeparationLine1"
          />
          {renderStep(2)}
          {displayDefault
            ? renderEndeavorRxSection()
            : renderEndeavorRxInsightSection()}
          <ContentSeparator
            extraClassName={styles.content_divider}
            automationSeparatorId="SeparationLine2"
          />
          {renderStep(3)}
          {displayDefault
            ? renderEndeavorRxInsightSection()
            : renderEndeavorRxSection()}
          <ContentSeparator
            extraClassName={styles.content_divider}
            automationSeparatorId="SeparationLine3"
          />
          {renderTextSection()}
        </div>
      </div>
    )
  }

  const renderContent = () => {
    if (stepsFormat.isLoading || downloadOrder.isLoading) {
      return (
        <div
          id="experiment-undecided-content"
          className={styles.loadingContainer}
        >
          <Loading fullScreen />
        </div>
      )
    }

    if (stepsFormat.displayDefault) {
      return renderDefaultContent()
    }
    return renderVariantContent()
  }

  return (
    <Layout context={screenId} hasFooter>
      {renderContent()}
    </Layout>
  )
}

export default withOptimizely(OnboardingComplete)
