import React, { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { Layout } from '@components'

const Login: FC = () => {
  const screenId = 'login-screen'

  const { loginWithRedirect } = useAuth0()

  const redirectConfig = { initial_screen: 'login' }

  loginWithRedirect(redirectConfig)

  return <Layout context={screenId} />
}

export default Login
