import _model from './model.en.json'
import _configs from './configs'

type ErrorTypes = { [errType: string]: string }

const configs = _configs as ErrorTypes

type Err = { status: number; problem: string }

const getRequestErrorType = (err: Err) => {
  if (!err) {
    return ''
  }

  return configs[err.problem]
}

/* **************** */
type Localization = {
  universal: {
    requestError: string
    notRequestError: string
    [errorType: string]: string
  }
  targeted: {
    [apiName: string]: {
      [errorType: string]: string
    }
  }
}

type NormalizedErr = 'notRequestError' | 'requestError'

const model = _model as Localization

const getLocalizedRequestError = (err: Err, apiName: string): string => {
  if (!err) {
    return ''
  }

  if (apiName && model.targeted[apiName]?.[err.status]) {
    return model.targeted[apiName][err.status]
  }

  if (model.universal?.[err.status]) {
    return model.universal[err.status]
  }

  const normalizedError = getRequestErrorType(err) as NormalizedErr

  if (apiName && model.targeted[apiName]?.[normalizedError]) {
    return model.targeted[apiName][normalizedError]
  }

  return model.universal[normalizedError] || ''
}

export { getRequestErrorType, getLocalizedRequestError }
