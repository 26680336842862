import React, { FC, MouseEventHandler } from 'react'
import styles from './styles.module.scss'

type PrimaryButtonProps = {
  caption: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  isLoading?: boolean
  className?: string
  context?: string
  automationButtonId?: string
  automationSpinnerButtonId?: string
}

const PrimaryButton: FC<PrimaryButtonProps> = ({
  caption,
  onClick,
  disabled,
  isLoading,
  className,
  context,
  automationButtonId,
  automationSpinnerButtonId
}) => {
  const id = context || 'primaryButton'

  if (isLoading) {
    return (
      <button
        id={id}
        className={[styles.primaryButton, className].join(' ')}
        data-testid="primary-button"
        type="button"
        disabled={isLoading || disabled}
        onClick={onClick}
      >
        <div className={styles.buttonContentLoading}>
          <div
            data-aid={automationSpinnerButtonId}
            id={`${id}-spinner`}
            className={styles.loadingIcon}
          />
        </div>
      </button>
    )
  }

  return (
    <button
      id={id}
      className={[styles.primaryButton, className].join(' ')}
      data-testid="primary-button"
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <span data-aid={automationButtonId} id={`${id}-label`}>
        {caption}
      </span>
    </button>
  )
}

export default PrimaryButton
