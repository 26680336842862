type Configs = {
  analytics: {
    viewed: string
    submitted: string
  }
  form: {
    [field: string]: {
      errorTypes: { [key: string]: boolean | number }
    }
  }
}

const configs = {
  analytics: {
    viewed: 'Screen_viewed',
    submitted: 'EnterActivationCode_submitted'
  },
  form: {
    activationCode: {
      errorTypes: {
        required: true,
        minChar: 25,
        maxChar: 25
      }
    }
  }
} as Configs

export default configs
