/* eslint-disable indent */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-bitwise */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */

import React, { Component } from 'react'
import Arrow from './assets/Arrow.gif'
import styles from './styles.module.scss'
import Pulse from './Pulse'

const Colors = {
  brand_v1: '#1140e9'
}

export default class Maze extends Component {
  // static defaultProps = {
  //   width: 600
  // }

  constructor(props) {
    super(props)

    this.setInitialValues()
  }

  componentDidUpdate(prevProps) {
    const { width: prevWidth } = prevProps
    const { width } = this.props

    if (!prevWidth && width) {
      this.setInitialValues()
      this.startGame()
    }
  }

  componentWillUnmount() {
    this.clearMethods()
  }

  clearMethods = () => {
    window.removeEventListener('keydown', this.keyboardDetector)

    if (this.playerRequestFrame) {
      window.cancelAnimationFrame(this.playerRequestFrame)
    }

    if (this.playerRequestFrameHelper) {
      window.cancelAnimationFrame(this.playerRequestFrameHelper)
    }
  }

  setInitialValues = () => {
    this.north = 1 << 0
    this.south = 1 << 1
    this.west = 1 << 2
    this.east = 1 << 3

    this.playerRequestFrame = null
    this.playerRequestFrameHelper = null

    this.cellWidth = null
    this.cellHeight = null
    this.cells = null
    this.frontier = []

    this.maxY = null
    this.maxX = null

    this.cellSize = 50
    this.cellSpacing = 4

    this.context = null
    this.game = null

    this.layout = []

    this.gameWidth = null
    this.gameHeight = null

    this.currentPosition = null
  }

  startGame = () => {
    const { width: widthProp } = this.props

    /** Set Game values */
    const browserHeight = Math.max(
      window.innerHeight ||
        document.documentElement.clientHeight ||
        document.getElementsByTagName('body')[0].clientHeight,
      700
    )

    this.gameWidth = widthProp - 50
    this.gameHeight = browserHeight - 500

    // eslint-disable-next-line radix
    const totalColumns = parseInt(this.gameWidth / this.cellSize)
    // eslint-disable-next-line radix
    const totalRows = parseInt(this.gameHeight / this.cellSize)

    this.gameWidth =
      totalColumns * this.cellSize + (totalColumns + 1) * this.cellSpacing
    this.gameHeight =
      totalRows * this.cellSize + (totalRows + 1) * this.cellSpacing

    this.cellWidth = Math.floor(
      (this.gameWidth - this.cellSpacing) / (this.cellSize + this.cellSpacing)
    )
    this.cellHeight = Math.floor(
      (this.gameHeight - this.cellSpacing) / (this.cellSize + this.cellSpacing)
    )

    this.cells = new Array(this.cellWidth * this.cellHeight)

    this.maxY =
      Math.floor(
        (this.gameHeight - this.cellSpacing) /
          (this.cellSize + this.cellSpacing)
      ) - 1

    this.maxX =
      Math.floor(
        (this.gameWidth - this.cellSpacing) / (this.cellSize + this.cellSpacing)
      ) - 1

    /** Draw Canvas */

    this.mazeGameContainer.style.width = `${this.gameWidth}px`

    this.mazeCanvas.setAttribute('width', this.gameWidth)
    this.mazeCanvas.setAttribute('height', this.gameHeight)

    this.context = this.mazeCanvas.getContext('2d')

    // eslint-disable-next-line react/destructuring-assignment
    this.context.translate(
      Math.round(
        (this.gameWidth -
          this.cellWidth * this.cellSize -
          (this.cellWidth + 1) * this.cellSpacing) /
          2
      ),
      Math.round(
        (this.gameHeight -
          this.cellHeight * this.cellSize -
          (this.cellHeight + 1) * this.cellSpacing) /
          2
      )
    )

    this.playersCanvas.setAttribute('width', this.gameWidth)
    this.playersCanvas.setAttribute('height', this.gameHeight)

    this.game = this.playersCanvas.getContext('2d')

    this.game.translate(
      Math.round(
        (this.gameWidth -
          this.cellWidth * this.cellSize -
          (this.cellWidth + 1) * this.cellSpacing) /
          2
      ),
      Math.round(
        (this.gameHeight -
          this.cellHeight * this.cellSize -
          (this.cellHeight + 1) * this.cellSpacing) /
          2
      )
    )

    // color of the maze
    this.context.fillStyle = Colors.brand_v1

    // Add a random cell and two initial edges.
    const start = (this.cellHeight - 1) * this.cellWidth
    this.cells[start] = 0

    this.fillCell(start)

    this.frontier.push({
      index: start,
      direction: this.north
    })
    this.frontier.push({
      index: start,
      direction: this.east
    })

    window.addEventListener('keydown', this.keyboardDetector)
    ;(function () {
      let lastTime = 0
      const vendors = ['ms', 'moz', 'webkit', 'o']

      for (
        let x = 0;
        x < vendors.length && !window.requestAnimationFrame;
        // eslint-disable-next-line no-plusplus
        ++x
      ) {
        window.requestAnimationFrame =
          window[`${vendors[x]}RequestAnimationFrame`]
        window.cancelAnimationFrame =
          window[`${vendors[x]}CancelAnimationFrame`] ||
          window[`${vendors[x]}CancelRequestAnimationFrame`]
      }

      if (!window.requestAnimationFrame)
        window.requestAnimationFrame = (callback) => {
          const currTime = new Date().getTime()
          const timeToCall = Math.max(0, 16 - (currTime - lastTime))

          const id = window.setTimeout(() => {
            callback(null, currTime + timeToCall)
          }, timeToCall)

          lastTime = currTime + timeToCall

          return id
        }

      if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = (id) => {
          clearTimeout(id)
        }
    })()

    this.animateGame()
  }

  keyboardDetector = (e) => {
    const value = e.which

    if (value === 37) {
      this.moveWest()
      e.preventDefault()
    }

    if (value === 38) {
      this.moveNorth()
      e.preventDefault()
    }

    if (value === 39) {
      this.moveEast()
      e.preventDefault()
    }

    if (value === 40) {
      this.moveSouth()
      e.preventDefault()
    }

    return false
  }

  moveWest = () => {
    const newY = this.currentPosition.y
    const newX = this.currentPosition.x - 1
    let newPosition

    if (newX < 0) return false

    // eslint-disable-next-line no-plusplus
    for (let i = this.layout.length - 1; i >= 0; i--) {
      if (this.layout[i].x === newX && this.layout[i].y === newY) {
        newPosition = this.layout[i]
      }
    }

    if (newPosition.x === this.maxX && newPosition.y === 0) {
      this.gameComplete()
    }

    if (this.currentPosition.d1 === this.west || newPosition.d1 === this.east) {
      this.drawPlayer(newPosition)
    }
  }

  moveEast = () => {
    const newY = this.currentPosition.y
    const newX = this.currentPosition.x + 1
    let newPosition

    if (newX > this.maxX) return false

    // eslint-disable-next-line no-plusplus
    for (let i = this.layout.length - 1; i >= 0; i--) {
      if (this.layout[i].x === newX && this.layout[i].y === newY) {
        newPosition = this.layout[i]
      }
    }

    if (newPosition.x === this.maxX && newPosition.y === 0) {
      this.gameComplete()
    }

    if (this.currentPosition.d1 === this.east || newPosition.d1 === this.west) {
      this.drawPlayer(newPosition)
    }
  }

  moveNorth = () => {
    const newY = this.currentPosition.y - 1
    const newX = this.currentPosition.x
    let newPosition

    if (newY < 0) return false

    // eslint-disable-next-line no-plusplus
    for (let i = this.layout.length - 1; i >= 0; i--) {
      if (this.layout[i].x === newX && this.layout[i].y === newY) {
        newPosition = this.layout[i]
      }
    }

    if (newPosition.x === this.maxX && newPosition.y === 0) {
      this.gameComplete()
    }

    if (
      this.currentPosition.d1 === this.north ||
      newPosition.d1 === this.south
    ) {
      this.drawPlayer(newPosition)
    }
  }

  moveSouth = () => {
    const newY = this.currentPosition.y + 1
    const newX = this.currentPosition.x
    let newPosition

    if (newY > this.maxY) return false

    // eslint-disable-next-line no-plusplus
    for (let i = this.layout.length - 1; i >= 0; i--) {
      if (this.layout[i].x === newX && this.layout[i].y === newY) {
        newPosition = this.layout[i]
      }
    }

    if (newPosition.x === this.maxX && newPosition.y === 0) {
      this.gameComplete()
    }

    if (
      this.currentPosition.d1 === this.south ||
      newPosition.d1 === this.north
    ) {
      this.drawPlayer(newPosition)
    }
  }

  gameComplete = () => {
    const { onMazeComplete } = this.props

    onMazeComplete()
  }

  drawPlayerFrame = (position) => () => {
    if (!this.pulseContainer) {
      return
    }

    const svg = this.pulseContainer.pulsePlayer
    const img = new Image()
    const xml = new XMLSerializer().serializeToString(svg)
    const svg64 = btoa(xml)
    const b64Start = 'data:image/svg+xml;base64,'
    const image64 = b64Start + svg64

    img.onload = () => {
      this.game.clearRect(0, 0, this.gameWidth, this.gameHeight)

      this.currentPosition = position

      const playerX =
        position.x * this.cellSize + (position.x + 1) * this.cellSpacing
      const playerY =
        position.y * this.cellSize + (position.y + 1) * this.cellSpacing

      this.game.drawImage(img, playerX, playerY)

      this.game.fillStyle = 'black'
      this.game.fill()
    }

    img.src = image64

    this.playerRequestFrameHelper = window.requestAnimationFrame(
      this.drawPlayerFrame(position)
    )
  }

  drawPlayer = (position) => {
    if (this.playerRequestFrame) {
      window.cancelAnimationFrame(this.playerRequestFrame)
    }

    if (this.playerRequestFrameHelper) {
      window.cancelAnimationFrame(this.playerRequestFrameHelper)
    }

    this.playerRequestFrame = window.requestAnimationFrame(
      this.drawPlayerFrame(position)
    )
  }

  fillCell = (index) => {
    const i = index % this.cellWidth
    const j = (index / this.cellWidth) | 0

    // eslint-disable-next-line react/destructuring-assignment
    this.context.fillRect(
      i * this.cellSize + (i + 1) * this.cellSpacing,
      j * this.cellSize + (j + 1) * this.cellSpacing,
      this.cellSize,
      this.cellSize
    )
  }

  fillEast = (index) => {
    const i = index % this.cellWidth
    const j = (index / this.cellWidth) | 0

    // eslint-disable-next-line react/destructuring-assignment
    this.context.fillRect(
      (i + 1) * (this.cellSize + this.cellSpacing),
      j * this.cellSize + (j + 1) * this.cellSpacing,
      this.cellSpacing,
      this.cellSize
    )
  }

  fillSouth = (index) => {
    const i = index % this.cellWidth
    const j = (index / this.cellWidth) | 0

    // eslint-disable-next-line react/destructuring-assignment
    this.context.fillRect(
      i * this.cellSize + (i + 1) * this.cellSpacing,
      (j + 1) * (this.cellSize + this.cellSpacing),
      this.cellSize,
      this.cellSpacing
    )
  }

  popRandom = (array) => {
    if (!array.length) {
      return
    }

    const n = array.length
    const i = (Math.random() * n) | 0
    const t = array[i]

    // eslint-disable-next-line no-param-reassign
    array[i] = array[n - 1]
    // eslint-disable-next-line no-param-reassign
    array[n - 1] = t

    return array.pop()
  }

  exploreFrontier = () => {
    let edge

    // eslint-disable-next-line no-cond-assign
    if ((edge = this.popRandom(this.frontier)) == null) {
      this.layout.push({ x: 0, y: this.maxY, d1: 0, d0: 0 })

      // eslint-disable-next-line no-plusplus
      for (let i = this.layout.length - 1; i >= 0; i--) {
        if (this.layout[i].x === 0 && this.layout[i].y === this.maxY) {
          this.drawPlayer(this.layout[i])
        }
      }
      return true
    }

    const i0 = edge.index
    const d0 = edge.direction
    const i1 =
      i0 +
      (d0 === this.north
        ? -this.cellWidth
        : d0 === this.south
        ? this.cellWidth
        : d0 === this.west
        ? -1
        : +1)

    const x0 = i0 % this.cellWidth
    const y0 = (i0 / this.cellWidth) | 0

    let x1
    let y1
    let d1
    const open = this.cells[i1] == null

    this.context.fillStyle = open ? Colors.brand_v1 : 'transparent'

    if (d0 === this.north) {
      this.fillSouth(i1)
      x1 = x0
      y1 = y0 - 1
      d1 = this.south
    } else if (d0 === this.south) {
      this.fillSouth(i0)
      x1 = x0
      y1 = y0 + 1
      d1 = this.north
    } else if (d0 === this.west) {
      this.fillEast(i1)
      x1 = x0 - 1
      y1 = y0
      d1 = this.east
    } else {
      this.fillEast(i0)
      x1 = x0 + 1
      y1 = y0
      d1 = this.west
    }

    if (open) {
      this.fillCell(i1)
      this.cells[i0] |= d0
      this.cells[i1] |= d1
      this.context.fillStyle = 'transparent'

      if (y1 > 0 && this.cells[i1 - this.cellWidth] == null) {
        this.fillSouth(i1 - this.cellWidth)
        this.frontier.push({
          index: i1,
          direction: this.north
        })
      }

      if (y1 < this.cellHeight - 1 && this.cells[i1 + this.cellWidth] == null) {
        this.fillSouth(i1)
        this.frontier.push({
          index: i1,
          direction: this.south
        })
      }

      if (x1 > 0 && this.cells[i1 - 1] == null) {
        this.fillEast(i1 - 1)
        this.frontier.push({
          index: i1,
          direction: this.west
        })
      }

      if (x1 < this.cellWidth - 1 && this.cells[i1 + 1] == null) {
        this.fillEast(i1)
        this.frontier.push({
          index: i1,
          direction: this.east
        })
      }
    }

    this.layout.push({
      open,
      x: x1,
      y: y1,
      d0,
      d1
    })
  }

  runGame = () => {
    let done
    let k = 0

    // eslint-disable-next-line no-cond-assign, no-plusplus
    while (++k < 50 && !(done = this.exploreFrontier()));
    return done
  }

  animateGame = () => {
    window.requestAnimationFrame(() => {
      if (!this.runGame()) {
        this.animateGame()
      }
    })
  }

  render() {
    const { width } = this.props

    if (!width) {
      return null
    }

    return (
      <div className={styles.maze_container} id="maze_container">
        <div className={styles.arrow_container}>
          <img src={Arrow} height="60px" />
        </div>
        <div
          className={styles.maze_game}
          id="maze_game"
          ref={(element) => {
            this.mazeGameContainer = element
          }}
        >
          <canvas
            id="maze_canvas"
            // eslint-disable-next-line no-return-assign
            ref={(element) => (this.mazeCanvas = element)}
          />
          <canvas
            id="players_canvas"
            className={styles.players}
            // eslint-disable-next-line no-return-assign
            ref={(element) => (this.playersCanvas = element)}
          />
        </div>
        <div className={styles.pulse_container}>
          <Pulse
            // eslint-disable-next-line no-return-assign
            ref={(element) => (this.pulseContainer = element)}
          />
        </div>
      </div>
    )
  }
}
