import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

interface State {
  authToken: null | string
  tokenPending: boolean
}

const useAuthToken = (): State => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const [{ authToken, tokenPending }, setAuthToken] = useState<State>({
    authToken: null,
    tokenPending: true
  })

  useEffect(() => {
    if (authToken || !tokenPending) {
      return
    }

    if (!isAuthenticated) {
      setAuthToken({ authToken: null, tokenPending: false })
      return
    }

    const fetchData = async () => {
      const token = await getAccessTokenSilently()

      setAuthToken({ authToken: token || null, tokenPending: false })
    }
    fetchData()
  }, [getAccessTokenSilently, isAuthenticated, authToken, tokenPending])

  return { authToken, tokenPending }
}

export default useAuthToken
