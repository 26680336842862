import React, { FC } from 'react'
import styles from './styles.module.scss'
import Iconography from '../Iconography'

type ErrorBannerProps = {
  message?: string
  context?: string
  automationId?: string
}

const ErrorBanner: FC<ErrorBannerProps> = ({
  message,
  context,
  automationId
}) => {
  if (!message) return null
  const id = context || 'errorBanner'

  return (
    <div id={id} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <Iconography name="circleExclamation" />
        </div>
        <div
          data-aid={automationId || 'BlackBoxMessage'}
          id={`${id}-label`}
          className={styles.messageContainer}
        >
          {message}
        </div>
      </div>
    </div>
  )
}

export default ErrorBanner
