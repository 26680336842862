import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Analytics } from '@utils'

interface Props {
  videoId: string
  width?: number
  height?: number
  startPlay?: boolean
  autoPlay?: boolean
}

interface Player {
  playVideo(): void
  getCurrentTime(): number
  getDuration(): number
  getPlaybackRate(): number
}

enum VideoStatus {
  UNSTARTED = -1,
  ENDED,
  PLAYING,
  PAUSED,
  BUFFERING,
  VIDEO_CUED
}

const YoutubeEmbed: FC<Props> = ({
  videoId,
  width = 560,
  height = 315,
  startPlay = false,
  autoPlay = false
}) => {
  const playerId = `youtube-player-${videoId}`
  const player = useRef<Player | null>(null)

  const logEvent = (actionCode) => {
    if (!player || !actionCode) return

    const videoName = 'Welcome to EndeavorRx'

    const currentTime = player?.current?.getCurrentTime() || 0
    const currentTimeRound = Math.round(currentTime || 0)

    const playbackPct =
      (player?.current?.getCurrentTime() || NaN) /
        (player?.current?.getDuration() || NaN) || 0
    const playbackPctRound = Math.round(playbackPct * 100)

    Analytics.recordEvent('Video_interacted', null, 'MIXPANEL', {
      video_name: videoName,
      action_type: actionCode,
      current_time_sec: currentTimeRound,
      percentage_watched: playbackPctRound
    })
  }

  const onPlayerReady = (event) => {
    if (autoPlay) {
      event.target.playVideo()
      logEvent('start')
    }
  }

  const onPlayerStateChange = ({
    data
  }: {
    data: VideoStatus
    target: Player
  }) => {
    switch (data) {
      case VideoStatus.PLAYING:
        logEvent('play')
        break
      case VideoStatus.PAUSED:
        logEvent('pause')
        break
      case VideoStatus.ENDED:
        logEvent('end')
        break
      default:
        break
    }
  }

  const loadVideo = useCallback(() => {
    // the Player object is created uniquely based on the id in props
    player.current = new window.YT.Player(playerId, {
      videoId,
      width,
      height,
      enablejsapi: true,
      origin: window.location.origin,
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange
      }
    })
  }, [playerId])

  useEffect(() => {
    const scriptId = 'YoutubeEmbedComponentScript'
    const scriptSrc = 'https://www.youtube.com/iframe_api'

    if (document.querySelectorAll(`[src="${scriptSrc}"]`).length) {
      loadVideo()
    } else {
      const tag = document.createElement('script')
      tag.src = scriptSrc
      tag.id = scriptId

      // check if script is already loaded from a previous instance of this component
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)

      // onYouTubeIframeAPIReady will load the video after the script is loaded
      window.onYouTubeIframeAPIReady = loadVideo
    }
  }, [])

  useEffect(() => {
    if (startPlay && player) {
      player.current?.playVideo()
      logEvent('start')
    }
  }, [startPlay])

  return (
    <div className="VideoContainer">
      <div id={playerId} className="VideoPlayer" />
    </div>
  )
}

export default YoutubeEmbed
