type Config = {
  analytics: {
    viewed: string
    resend: string
    expireViewed: string
  }
}

const config = {
  analytics: {
    viewed: 'Screen_viewed',
    resend: 'VerifyEmail_resend',
    expireViewed: 'VerifyEmailExpire_viewed'
  }
} as Config

export default config
