type Configs = {
  analytics: {
    viewed: string
    submitted: string
  }
  form: {
    [field: string]: {
      maxLength?: number
      values?: { [key: string]: string }
      errorTypes:
        | Record<string, never>
        | { [key: string]: string | boolean | number }
    }
  }
}

const configs = {
  analytics: {
    viewed: 'Screen_viewed',
    submitted: 'PatientProfile_submitted'
  },
  form: {
    nickname: {
      maxLength: 30,
      errorTypes: {
        required: true,
        minChar: 1,
        maxChar: 30,
        minAZaz: 1
      }
    },
    dateOfBirth: {
      errorTypes: {
        required: true,
        minChar: 6,
        maxChar: 6,
        type: 'birthdate',
        minYear: 1900
      }
    },
    gender: {
      values: {
        male: 'M',
        female: 'F',
        other: 'U',
        preferNotToSay: 'N'
      },
      errorTypes: {
        required: true
      }
    },
    relationship: {
      values: {
        mother: 'mother',
        father: 'father',
        legalGuardian: 'legal_guardian',
        other: 'other'
      },
      errorTypes: {
        required: false
      }
    }
  }
} as Configs

export default configs
