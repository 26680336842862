import React, { FC, MouseEventHandler } from 'react'
import { Button } from 'semantic-ui-react'
import Iconography from '../Iconography'
import styles from './styles.module.scss'

type ButtonContentType = {
  isLoading?: boolean
}
type FloatingButtonProps = {
  context?: string
  automationButtonId?: string
  className?: string
  disabled?: boolean
  isLoading?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  brandVersion?: string
}
const DEFAULT_ICON_NAME = 'RightArrow'
const DEFAULT_ICON_COLOR = 'white'
const DEFAULT_ICON_WIDTH = 24
const DEFAULT_ICON_HEIGHT = 24

const ButtonContent = (
  buttonContentType: ButtonContentType,
  automationButtonId: string
) => {
  let result
  if (buttonContentType.isLoading) {
    result = (
      <div
        data-aid={automationButtonId}
        id="spinner"
        className={`${styles.loading_state} ${styles.is_fab_button}`}
      />
    )
  } else {
    result = (
      <Iconography
        data-aid={automationButtonId}
        name={DEFAULT_ICON_NAME}
        fill={DEFAULT_ICON_COLOR}
        width={DEFAULT_ICON_WIDTH}
        height={DEFAULT_ICON_HEIGHT}
      />
    )
  }
  return result
}

const FloatingButton: FC<FloatingButtonProps> = ({
  context,
  automationButtonId,
  className,
  disabled,
  onClick,
  isLoading,
  brandVersion = 'v1'
}) => {
  const id = context || 'floatingActionButton'
  const brandStyleName = `brand_${brandVersion}`

  return (
    <Button
      data-aid={automationButtonId}
      id={id}
      key={`button-${id}`}
      className={[
        styles.floating_button,
        className || '',
        styles[brandStyleName]
      ].join(' ')}
      disabled={disabled}
      onClick={onClick}
    >
      <ButtonContent isLoading={isLoading} />
    </Button>
  )
}

export default FloatingButton
