import React, { FC, useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router-dom'

import {
  Layout,
  Header,
  TextInput,
  SelectInput,
  ErrorBanner,
  FloatingButton
} from '@components'
import {
  validateFormField,
  useAuthToken,
  navigateToUrl,
  routes,
  Analytics,
  cleanBirthDate
} from '@utils'
import {
  getCareteamAPI,
  registerPatientApi,
  updatePatientAPI
} from '@apiRoutes'
import styles from './styles.module.scss'
import _model from './model.en.json'
import configs from './configs'

type Localization = {
  title: string
  description: string
  form: {
    [field: string]: {
      label: string
      placeholder: string
      error: string
      options?: { [key: string]: string }
    }
  }
}

type TextState = {
  value: string
  hasError: boolean
  error: string
}

type RequestData = {
  nickname: string
  dateOfBirth: string
  gender: string
  relationship: string
}

const model = _model as Localization

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const SetupPatientProfile: FC<RouteComponentProps & CustomProps> = ({
  history,
  handleInvalidUser
}) => {
  const screenId = 'setup-patient-screen'
  const SCREEN_NAME = 'Patient Profile'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(false)
  const [screenError, setScreenError] = useState('' as string)

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  const addScreenError = (errorText: string) => {
    setScreenError(errorText)
  }
  const [{ patientId, patientIdFetching }, setPatient] = useState({
    patientId: '',
    patientIdFetching: true
  })
  const { tokenPending, authToken } = useAuthToken()

  /** ************* */
  /* Form data handlers */

  const clearError = (
    state: TextState,
    updateState: (state: TextState) => void
  ) => {
    const { hasError } = state
    if (!hasError) return
    updateState({ ...state, hasError: false, error: '' })
  }

  /** ************* */
  /* Nickname field */
  const [nickname, setNickname] = useState({
    value: '',
    hasError: false,
    error: model.form.nickname.error
  } as TextState)

  const updateNickname = (val: string): void => {
    setNickname({ ...nickname, value: val })
  }

  const clearNicknameError = (): void => {
    clearError(nickname, setNickname)
  }

  /** ************* */
  /* Date of birth field */
  const [dateOfBirth, setDateOfBirth] = useState({
    value: '',
    hasError: false,
    error: model.form.dateOfBirth.error
  } as {
    value: string
    hasError: boolean
    error: string
  })

  const updateDateOfBirth = (val: string): void => {
    setDateOfBirth({ ...dateOfBirth, value: val })
  }

  const clearDateOfBirthError = (): void => {
    clearError(dateOfBirth, setDateOfBirth)
  }

  /** ************* */
  /* Gender field */
  const [gender, setGender] = useState({
    value: '',
    hasError: false,
    error: model.form.gender.error
  } as {
    value: string
    hasError: boolean
    error: string
  })

  const updateGender = (value: string): void => {
    setGender({ ...gender, value })
  }

  const clearGenderError = (): void => {
    clearError(gender, setGender)
  }

  /** ************* */
  /* Relationship field */
  const [relationship, setRelationship] = useState({
    value: '',
    hasError: false,
    error: model.form.relationship.error
  } as {
    value: string
    hasError: boolean
    error: string
  })

  const updateRelationship = (value: string): void => {
    setRelationship({ ...relationship, value })
  }

  const clearRelationshipError = (): void => {
    clearError(relationship, setRelationship)
  }

  /** ************* */
  /* Record the viewed event to Mixpanel the first time */
  useEffect(() => {
    Analytics.recordEvent(configs.analytics.viewed, authToken, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  /** ************* */
  /* Fetch existing data */

  useEffect(() => {
    if (!patientIdFetching || tokenPending || !authToken || patientId) {
      return
    }
    const fetchData = async () => {
      const response = await getCareteamAPI(authToken).catch((e) =>
        handleInvalidUser(e)
      )

      if (!response) {
        return
      }

      if (!response.success) {
        setPatient({ patientId, patientIdFetching: false })
        addScreenError(response.localizedErrorMessage)
        return
      }

      const patientInfo = response.responsePayload?.careTeams[0]?.patient
      const caregiverInfo = (response.responsePayload?.careTeams[0]
        ?.caregivers || [])[0]
      setPatient({
        patientId: patientInfo?.patientId || '',
        patientIdFetching: false
      })

      if (!patientInfo || !patientInfo.patientId) {
        return
      }

      updateNickname(patientInfo?.nickname || '')
      const dateOfBirthFormat =
        `${patientInfo?.birthdate?.split('-')[1]}|${
          patientInfo?.birthdate?.split('-')[0]
        }}` || ''
      updateDateOfBirth(dateOfBirthFormat)
      updateGender(patientInfo?.gender || 'N')
      updateRelationship(caregiverInfo?.relationshipToPatient || '')
    }

    fetchData()
  }, [tokenPending, authToken, patientIdFetching, patientId])

  /** ************* */
  /* Form authentication handlers */

  let validForm = true

  const verifyFormField = (
    name: string,
    state: TextState,
    updateState: (state: TextState) => void
  ): void => {
    const hasValidationError = validateFormField(name, configs, state)
    if (!hasValidationError) return

    validForm = false
    const {
      form: {
        [name]: { error }
      }
    } = model

    const newState = { ...state, hasError: true }
    if (error) {
      newState.error = error
    }
    updateState(newState)
  }

  const validateForm = () => {
    validForm = true
    const fields = [
      {
        name: 'nickname',
        state: nickname,
        updateState: setNickname
      },
      {
        name: 'dateOfBirth',
        state: dateOfBirth,
        updateState: setDateOfBirth
      },
      {
        name: 'gender',
        state: gender,
        updateState: setGender
      },
      {
        name: 'relationship',
        state: relationship,
        updateState: setRelationship
      }
    ]

    fields.forEach(({ name, state, updateState }) => {
      verifyFormField(name, state, updateState)
    })
  }

  const submitProfile = async () => {
    const fixValue = cleanBirthDate(dateOfBirth.value, true)
    const month = fixValue.split('|')[0]
    const year = fixValue.split('|')[1]
    const dateOfBirthValue = `${year}-${month}-01`
    const data = {
      nickname: nickname.value,
      dateOfBirth: dateOfBirthValue,
      gender: gender.value,
      relationship: relationship.value
    } as RequestData

    let response
    if (patientId) {
      response = await updatePatientAPI(
        authToken as string,
        patientId,
        data
      ).catch((e) => handleInvalidUser(e))
    } else {
      response = await registerPatientApi(authToken as string, data).catch(
        (e) => handleInvalidUser(e)
      )
    }

    if (!response) {
      return
    }

    setLoading(false)

    if (response?.success) {
      /* Record the submitted event to Mixpanel */
      Analytics.recordEvent(configs.analytics.submitted, authToken, 'MIXPANEL')

      navigateToUrl(history, routes.demographics)
    } else {
      setScreenError(response.localizedErrorMessage)
    }
  }

  const handleSubmit = () => {
    if (isLoading || !authToken) {
      return
    }

    setScreenError('')
    setLoading(true)

    validateForm()

    if (!validForm) {
      setLoading(false)
      window.scrollTo(0, 0)
      return
    }

    submitProfile()
  }

  /** ************* */
  /* UI Content */
  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  const renderForm = () => {
    const {
      form: {
        nickname: { label: nicknameLabel, placeholder: nicknamePlaceholder },
        dateOfBirth: {
          label: dateOfBirthLabel,
          placeholder: dateOfBirthPlaceholder
        },
        gender: {
          label: genderLabel,
          placeholder: genderPlaceholder,
          options: genderOptions
        },
        relationship: {
          label: relationshipLabel,
          placeholder: relationshipPlaceholder,
          options: relationshipOptions
        }
      }
    } = model

    const optionsToGender: {
      value: string
      text: string
      id: string
    }[] = [
      {
        value: configs.form.gender.values?.male || '',
        id: `option-${configs.form.gender.values?.male}` || '',
        text: genderOptions?.male || ''
      },
      {
        value: configs.form.gender.values?.female || '',
        id: `option-${configs.form.gender.values?.female}` || '',
        text: genderOptions?.female || ''
      },
      {
        value: configs.form.gender.values?.other || '',
        id: `option-${configs.form.gender.values?.other}` || '',
        text: genderOptions?.other || ''
      },
      {
        value: configs.form.gender.values?.preferNotToSay || '',
        id: `option-${configs.form.gender.values?.preferNotToSay}` || '',
        text: genderOptions?.preferNotToSay || ''
      }
    ]
    const optionsToRelationship: {
      value: string
      text: string
      id: string
    }[] = [
      {
        value: configs.form.relationship.values?.mother || '',
        id: `option-${configs.form.relationship.values?.mother}` || '',
        text: relationshipOptions?.mother || ''
      },
      {
        value: configs.form.relationship.values?.father || '',
        id: `option-${configs.form.relationship.values?.father}` || '',
        text: relationshipOptions?.father || ''
      },
      {
        value: configs.form.relationship.values?.legalGuardian || '',
        id: `option-${configs.form.relationship.values?.legalGuardian}` || '',
        text: relationshipOptions?.legalGuardian || ''
      },
      {
        value: configs.form.relationship.values?.other || '',
        id: `option-${configs.form.relationship.values?.other}` || '',
        text: relationshipOptions?.other || ''
      }
    ]
    return (
      <div className={styles.form}>
        <Form>
          <Form.Group widths="equal">
            <TextInput
              automationFieldId="NicknameField"
              automationLabelId="NicknameLabel"
              automationIconId="NicknameIcon"
              automationValidationId="NicknameValidation"
              context="nickname"
              type="alphanumeric"
              label={nicknameLabel}
              placeholder={nicknamePlaceholder}
              value={nickname.value}
              handleChange={updateNickname}
              maxLength={configs.form.nickname.maxLength}
              error={nickname.hasError}
              errorMessage={nickname.error}
              onFocus={clearNicknameError}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <TextInput
              automationFieldId="DoBField"
              automationLabelId="DoBLabel"
              automationIconId="DoBIcon"
              automationValidationId="DoBValidation"
              context="dob"
              type="date"
              label={dateOfBirthLabel}
              placeholder={dateOfBirthPlaceholder}
              value={dateOfBirth.value}
              handleChange={updateDateOfBirth}
              error={dateOfBirth.hasError}
              errorMessage={dateOfBirth.error}
              onFocus={clearDateOfBirthError}
            />
            <SelectInput
              automationFieldId="GenderField"
              automationLabelId="GenderLabel"
              automationValidationId="GenderValidation"
              context="gender"
              label={genderLabel}
              placeholder={genderPlaceholder}
              options={optionsToGender}
              value={gender.value}
              handleChange={updateGender}
              error={gender.hasError}
              errorMessage={gender.error}
              onFocus={clearGenderError}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <SelectInput
              automationFieldId="RelationshipField"
              automationLabelId="RelationshipLabel"
              automationValidationId="RelationshipValidation"
              context="relationship"
              label={relationshipLabel}
              placeholder={relationshipPlaceholder}
              options={optionsToRelationship}
              value={relationship.value}
              handleChange={updateRelationship}
              error={relationship.hasError}
              errorMessage={relationship.error}
              onFocus={clearRelationshipError}
            />
          </Form.Group>
        </Form>
      </div>
    )
  }

  const renderSubmitContent = () => {
    return (
      <div className={styles.buttonContainer}>
        <FloatingButton
          automationButtonId="ArrowButton"
          context="submit"
          onClick={handleSubmit}
          disabled={!authToken || patientIdFetching}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter>
      <div className={styles.container}>
        <Header
          title={model.title}
          description={model.description}
          automationTitleId="PatientProfileTitle"
          automationLegendId="PatientProfileLegend"
        />
        {renderErrorBanner()}
        {renderForm()}
        {renderSubmitContent()}
      </div>
    </Layout>
  )
}

export default SetupPatientProfile
