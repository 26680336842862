import React, { FC, MouseEventHandler } from 'react'
import styles from './styles.module.scss'

type LinkButtonProps = {
  context?: string
  caption: string
  onClick: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  isLoading?: boolean
  className?: string
}
type ButtonContentType = {
  caption: string
  isLoading?: boolean
}
const ButtonContent = (buttonContentType: ButtonContentType) => {
  let result
  if (buttonContentType.isLoading) {
    result = (
      <div
        id="spinner"
        className={`${styles.loading_state} ${styles.is_fab_button}`}
      />
    )
  } else {
    result = <span id="button-label">{buttonContentType.caption}</span>
  }
  return result
}

const LinkButton: FC<LinkButtonProps> = ({
  caption,
  onClick,
  disabled,
  isLoading,
  className,
  context
}) => {
  const id = context || 'linkButton'

  return (
    <button
      id={id}
      className={[styles.linkButton, className].join(' ')}
      data-testid="link-button"
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <ButtonContent caption={caption} isLoading={isLoading} />
    </button>
  )
}

export default LinkButton
