import React, { FC } from 'react'

type NumberInputProps = {
  placeholder: string
  value: string
  maxLength?: number
  context?: string
  isNewPassword?: boolean
  onChange: (value: string, id?: string) => void
  onFocus: () => void
  automationFieldId?: string
}

const NumberInputInput: FC<NumberInputProps> = ({
  placeholder,
  value,
  maxLength = 50,
  context,
  isNewPassword = false,
  onChange,
  onFocus,
  automationFieldId
}) => {
  const id = context || 'password'

  const handleChange = ({
    target: { value: val = '' }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(val.replace(/[^\d]/g, ''), id)
  }

  return (
    <input
      data-aid={automationFieldId}
      id={`${id}-input`}
      type="password"
      autoComplete={isNewPassword ? 'new-password' : 'current-password'}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      maxLength={maxLength ?? 255}
      onFocus={onFocus}
    />
  )
}

export default NumberInputInput
