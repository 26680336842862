import React, { FC } from 'react'
import styles from './style.module.scss'

type LoadingProps = {
  fullScreen?: boolean
  context?: string
}

const Loading: FC<LoadingProps> = ({ fullScreen = false, context }) => {
  const id = context || 'loading'

  if (fullScreen) {
    return (
      <div
        id={`${id}-container`}
        className={styles.fullScreen}
        data-testid="loading-spinner"
      >
        <div id="spinner" className={styles.spinner} />
      </div>
    )
  }

  return <div id="spinner" className={styles.spinner} />
}

export default Loading
