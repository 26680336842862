import React from 'react'

export default class Pulse extends React.Component {
  render() {
    return (
      <svg
        ref={(el) => {
          this.pulsePlayer = el
        }}
        width="50px"
        height="50px"
        viewBox="0 0 50 50"
      >
        <g
          id="TTS-Web"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="404-Page/Desktop"
            transform="translate(-573.000000, -702.000000)"
            fill="#F8E71C"
            fillRule="nonzero"
          >
            <g id="Group-2" transform="translate(573.000000, 702.000000)">
              <circle id="circle_3" fillOpacity="0.5" cx="25" cy="25" r="0">
                <animate
                  begin="circle2.begin + 100ms"
                  dur="2s"
                  attributeName="r"
                  values="0; 25; 0"
                  repeatCount="indefinite"
                />
              </circle>
              <circle id="circle_2" fillOpacity="0.5" cx="25" cy="25" r="18">
                <animate
                  id="circle2"
                  dur="2s"
                  attributeName="r"
                  values="0; 18; 0"
                  repeatCount="indefinite"
                />
              </circle>
              <circle id="circle_1" cx="25" cy="25" r="11" />
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
