import React, { FC, useEffect, useState } from 'react'
import { Redirect, RouteComponentProps, useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import * as $ from 'jquery'

import { sendEmailVerificationAPI } from '@apiRoutes'
import {
  LinkButton,
  Header,
  Layout,
  PrimaryButton,
  ErrorBanner
} from '@components'
import { Analytics, navigateToUrl, routes, useAuthToken } from '@utils'
import airplaneImage from './assets/airplane.png'
import backgroundAirplaneImage from './assets/background_airplane.png'
import model from './model.en.json'
import querystring from './querystring.values.json'
import styles from './styles.module.scss'
import config from './config'

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const LEGACY_INSIGHT_APP = 'X01-mobile-client'
const T01A_APP = 'T01A-client'
const SUBSCRIPTION_APP = 'subscription-portal'

const EmailVerification: FC<RouteComponentProps & CustomProps> = ({
  history,
  handleInvalidUser
}) => {
  const screenId = 'email-verification-screen'
  const SCREEN_NAME = 'Verify Email'

  // Get all values from model json
  const {
    backgroundAirplaneAlternative,
    airplaneAlternative,
    title,
    titleDescription,
    resendEmailText,
    resendEmailTextLink,
    resendEmailTextDot,
    resendEmailTextSuccess,
    titleEmailVerificationLinkExpired,
    descriptionEmailVerificationLinkExpiredSignIn,
    descriptionEmailVerificationLinkExpired,
    captionLoginButton,
    captionContinueWithRegistration,
    captionResendVerificationEmailLink,
    titleVerificationSuccess,
    descriptionVerificationSuccess,
    descriptionVerificationSuccessSignIn,
    titleVerificationAlready,
    descriptionVerificationAlreadySignIn
  } = model

  // Get analytics model
  const { analytics } = config

  const { emailVerificationLink } = querystring
  // State hook to show success message
  const [isLoading, setIsLoading] = useState(false)

  // State hook to show success message
  const [showMessage, setShowMessage] = useState(false)

  // Get current token
  const { tokenPending, authToken } = useAuthToken()

  // Get current user
  const { user, isAuthenticated, loginWithRedirect } = useAuth0()

  const [screenError, setScreenError] = useState('' as string)

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  const location = useLocation()

  /** Record the viewed event into mixpanel just the first time (On viewing the first page) */
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const message = query.get('message')
    if (!message) {
      Analytics.recordEvent(analytics.viewed, authToken, 'MIXPANEL', {
        name: SCREEN_NAME
      })
    }
  }, [])

  const addScreenError = (errorText: string) => {
    setScreenError(errorText)
  }

  // Handle resend email event the resend email event
  const handleResendEmailClick = async () => {
    // Record the resend email click event
    Analytics.recordEvent(analytics.resend, authToken, 'MIXPANEL')

    // Verify the token and user are availables
    if (tokenPending || !authToken || !user) return

    setIsLoading(true)
    setScreenError('')
    setShowMessage(false)

    // Invoke send email verification API
    const response = await sendEmailVerificationAPI(authToken).catch((e) =>
      handleInvalidUser(e)
    )

    if (!response) {
      return
    }

    setIsLoading(false)

    if (!response.success) {
      addScreenError(response.localizedErrorMessage)
      return
    }

    // Show success message
    setShowMessage(true)
  }

  // Success message
  const Message: FC<{ show: boolean; message: string }> = ({
    show,
    message
  }) => {
    if (!show) {
      return null
    }
    return (
      <p className={styles.message}>
        <span className={styles.success}>{message}</span>
      </p>
    )
  }

  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} />
      </div>
    )
  }

  // Resend email verification
  const ResendEmailVerification: FC = () => {
    return (
      <div className={styles.container}>
        <Header title={title} description={titleDescription} />
        <div className={styles.body}>
          {renderErrorBanner()}
          <div className={styles.airplaneImageContainer}>
            <img
              className={styles.backgroundAirplane}
              src={backgroundAirplaneImage}
              alt={backgroundAirplaneAlternative}
              srcSet={backgroundAirplaneImage}
            />
            <img
              className={styles.airplane}
              src={airplaneImage}
              alt={airplaneAlternative}
              srcSet={airplaneImage}
            />
          </div>
          <p>
            <span>{resendEmailText}</span>
            <LinkButton
              context="resend-email"
              className={styles.linkButton}
              caption={resendEmailTextLink}
              onClick={handleResendEmailClick}
              isLoading={isLoading}
            />
            {resendEmailTextDot}
          </p>
          <Message message={resendEmailTextSuccess} show={showMessage} />
        </div>
      </div>
    )
  }

  // Email verification link message
  type EmailVerificationLinkMessageProps = {
    isUserAuthenticated: boolean
    success: boolean
    message: string | null
  }

  const EmailVerificationLinkMessage: FC<EmailVerificationLinkMessageProps> = ({
    isUserAuthenticated,
    success,
    message
  }) => {
    let content = <div />
    if (success) {
      content = (
        <div className={styles.container}>
          <Header
            title={titleVerificationSuccess}
            description={
              isUserAuthenticated
                ? descriptionVerificationSuccess
                : descriptionVerificationSuccessSignIn
            }
          />
          <div className={styles.body}>
            {renderErrorBanner()}
            <PrimaryButton
              context="actionButton"
              className={styles.primaryButton}
              caption={
                isUserAuthenticated
                  ? captionContinueWithRegistration
                  : captionLoginButton
              }
              onClick={
                isUserAuthenticated
                  ? () => navigateToUrl(history, routes.onboardUser)
                  : () => loginWithRedirect({ initial_screen: 'login' })
              }
            />
          </div>
        </div>
      )
    } else {
      if (
        message?.includes(emailVerificationLink.verificationAlready) ||
        message?.includes(emailVerificationLink.verificationUsedOnce)
      ) {
        if (isUserAuthenticated) {
          // Continue with the registration
          content = <Redirect to={routes.onboardUser} />
        } else {
          // Account already verified screen with login button
          content = (
            <div className={styles.container}>
              <Header
                title={titleVerificationAlready}
                description={descriptionVerificationAlreadySignIn}
              />
              <div className={styles.body}>
                {renderErrorBanner()}
                <PrimaryButton
                  context="actionButton"
                  className={styles.primaryButton}
                  caption={captionLoginButton}
                  onClick={() => loginWithRedirect({ initial_screen: 'login' })}
                />
              </div>
            </div>
          )
        }
      }
      if (message?.includes(emailVerificationLink.verificationExpired)) {
        // Auth0 message with login button or with resend email button according to sigin status
        content = (
          <div className={styles.container}>
            <Header
              title={titleEmailVerificationLinkExpired}
              description={
                isUserAuthenticated
                  ? descriptionEmailVerificationLinkExpired
                  : descriptionEmailVerificationLinkExpiredSignIn
              }
            />
            <div className={styles.body}>
              {renderErrorBanner()}
              <PrimaryButton
                context="actionButton"
                className={styles.primaryButton}
                caption={
                  isUserAuthenticated
                    ? captionResendVerificationEmailLink
                    : captionLoginButton
                }
                disabled={showMessage}
                isLoading={isLoading}
                onClick={
                  isUserAuthenticated
                    ? handleResendEmailClick
                    : () => loginWithRedirect({ initial_screen: 'login' })
                }
              />
              <Message message={resendEmailTextSuccess} show={showMessage} />
            </div>
          </div>
        )
      }
    }
    // TODO: request a default content when success=false
    return content
  }

  const MainContent = () => {
    // Get parameter
    const query = new URLSearchParams(location.search)
    const success = query.get('success')?.toLocaleLowerCase()
    const message = query.get('message')
    const signupOrigin = query.get('context_name')

    let content = <div />

    const checkValue = (target: string | void, expected: string): boolean =>
      target === expected

    if (tokenPending) {
      content = <div>Checking Authentication ...</div>
    } else if (signupOrigin && signupOrigin.includes(LEGACY_INSIGHT_APP)) {
      // redirect to legacy verify email outcome templates
      // Note: window.open will cause a popup blocker to appear

      $.ajax({
        url: 'url',
        dataType: 'text',
        cache: false,
        success() {
          window.open(
            `${routes.legacyVerifyEmailStatus}?${query.toString()}`,
            '_self'
          )
        }
      })
    } else if (
      signupOrigin &&
      (signupOrigin.includes(T01A_APP) ||
        signupOrigin.includes(SUBSCRIPTION_APP))
    ) {
      // redirect to verify email for OTC users.

      $.ajax({
        url: 'url',
        dataType: 'text',
        cache: false,
        success() {
          window.open(`${routes.verifyEmailOtc}?${query.toString()}`, '_self')
        }
      })
    } else if (
      (checkValue(success, 'true') && isAuthenticated) ||
      (checkValue(success, 'false') &&
        message &&
        !message.includes(emailVerificationLink.verificationExpired) &&
        isAuthenticated)
    ) {
      content = <Redirect to={routes.onboardUser} />
    } else if (
      (checkValue(success, 'true') && !isAuthenticated) ||
      (checkValue(success, 'false') && message)
    ) {
      content = (
        <EmailVerificationLinkMessage
          isUserAuthenticated={
            (isAuthenticated && user && authToken) as boolean
          }
          success={checkValue(success, 'true')}
          message={message}
        />
      )
    } else if (isAuthenticated && user && authToken) {
      if (user?.email_verified) {
        // Redirect To Setup Caregiver Account if user has email verified
        content = <Redirect to={routes.onboardUser} />
      } else {
        // Render resend email verification link
        content = <ResendEmailVerification />
      }
    } else if (!isAuthenticated && !tokenPending) {
      // user is not authenticated (safari issue)
      loginWithRedirect({ initial_screen: 'login' })
    } else {
      // TODO: missing empty state
    }
    return content
  }

  return (
    <Layout context={screenId} hasFooter>
      <MainContent />
    </Layout>
  )
}

export default EmailVerification
