import React, { FC } from 'react'
import styles from './style.module.scss'
import * as Icons from './Icons'

type IconographyProps = {
  context?: string
  name: string
  fill?: string
  stroke?: string
  scale?: number
  type?: string
  width?: number
  height?: number
  extraClassName?: string
  automationIconId?: string
}

const Iconography: FC<IconographyProps> = ({
  extraClassName = '',
  fill,
  stroke,
  scale,
  name,
  context,
  width,
  height,
  automationIconId
}) => {
  const id = context || name

  const getIconpath = () => {
    const formattedName = name[0].toUpperCase() + name.substring(1)

    const IconName = Icons[formattedName as keyof typeof Icons]

    if (!IconName) {
      return null
    }

    return (
      <IconName
        fill={fill}
        stroke={stroke}
        scale={scale}
        width={width}
        height={height}
        extraClassName={extraClassName}
      />
    )
  }

  return (
    <div
      id={`${id}-icon`}
      data-aid={automationIconId}
      className={`${styles.icon_container} ${extraClassName}`}
    >
      {getIconpath()}
    </div>
  )
}

export default Iconography
