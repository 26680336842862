import React, { FC } from 'react'

type LeftArrowProps = {
  fill?: string
  width?: number
  height?: number
  stroke?: string
  scale?: number
  extraClassName?: string
}

const LeftArrow: FC<LeftArrowProps> = ({
  fill = 'blue',
  width = 24,
  height = 24
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <defs>
        <path
          d="M3.82997 6.99997L8.71199 2.11795C9.103 1.72694 9.10483 1.09705 8.70997 0.704972C8.31785 0.315611 7.67645 0.323489 7.29002 0.709918L0.709918 7.29002C0.317825 7.68212 0.323489 8.32349 0.709918 8.70992L7.29002 15.29C7.68212 15.6821 8.3129 15.687 8.70497 15.295C9.09433 14.9056 9.09398 14.2734 8.70326 13.882L3.82997 8.99997H15.004C15.5541 8.99997 16 8.55611 16 7.99997C16 7.44769 15.5524 6.99997 15.004 6.99997H3.82997Z"
          fill={fill}
          id="path-left-arrow-icon"
        />
      </defs>
      <g id="g_icons_left_arrow" fill="none" fillRule="evenodd">
        <use
          id="u_icon_left_arrow"
          fill={fill}
          href="#path-left-arrow-icon"
          xlinkHref="#path-left-arrow-icon"
        />
      </g>
    </svg>
  )
}

export default LeftArrow
