import React, { FC } from 'react'

type WarningProps = {
  fill?: string
  width?: number
  height?: number
}

const Warning: FC<WarningProps> = ({
  fill = 'white',
  width = 24,
  height = 24
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24">
    <defs>
      <path
        d="M4.93745742,21.4913953 L19.9996683,21.4913953 C21.1042378,21.4913953 21.9996683,20.5959648 21.9996683,19.4913953 C21.9996683,19.1395101 21.9068281,18.7938534 21.7305209,18.4893227 L14.1994154,5.48104971 C13.645986,4.52512623 12.4224138,4.1988403 11.4664903,4.75226968 C11.1640673,4.9273567 10.9127973,5.17862667 10.7377103,5.48104971 L3.20660485,18.4893227 C2.65317547,19.4452462 2.9794614,20.6688185 3.93538488,21.2222478 C4.23991557,21.3985551 4.58557224,21.4913953 4.93745742,21.4913953 Z M12.4371257,19.4827905 L12.4371257,19.4827905 C11.6086986,19.4827905 10.9371257,18.8112177 10.9371257,17.9827905 L10.9371257,17.9827905 C10.9371257,17.1543634 11.6086986,16.4827905 12.4371257,16.4827905 L12.4371257,16.4827905 C13.2655528,16.4827905 13.9371257,17.1543634 13.9371257,17.9827905 L13.9371257,17.9827905 C13.9371257,18.8112177 13.2655528,19.4827905 12.4371257,19.4827905 Z M12.4685629,14.5 L12.4685629,14.5 C11.9162781,14.5 11.4685629,14.0522847 11.4685629,13.5 L11.4685629,9.49139527 C11.4685629,8.93911052 11.9162781,8.49139527 12.4685629,8.49139527 L12.4685629,8.49139527 C13.0208476,8.49139527 13.4685629,8.93911052 13.4685629,9.49139527 L13.4685629,13.5 C13.4685629,14.0522847 13.0208476,14.5 12.4685629,14.5 Z"
        id="path-warning-icon"
      />
    </defs>
    <g id="g_icons_warning" fill="none" fillRule="evenodd">
      <use
        id="u_icon_warning"
        fill={fill}
        fillRule="nonzero"
        href="#path-warning-icon"
        xlinkHref="#path-warning-icon"
      />
    </g>
  </svg>
)

export default Warning
