import React, { FC, useState, useEffect } from 'react'
import { useLocation, RouteComponentProps } from 'react-router-dom'

import {
  Layout,
  Loading,
  Iconography,
  TransferUserActionButtons
} from '@components'
import { Analytics } from '@utils'
import styles from './styles.module.scss'
import _model from './model.en.json'
import _configs, { ScreensMessagesContent } from './configs'

const configs = _configs as ScreensMessagesContent
type Localization = {
  error_types: {
    [type: string]: {
      header: string
      subheader: string
    }
  }
  error_details: {
    header: string
    code: string
    details: string
  }
}

const model = _model as Localization

const InvalidAuthentication: FC<RouteComponentProps> = () => {
  const screenId = 'invalid-authentication-screen'
  const SCREEN_NAME = 'Invalid Authentication'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(true)
  const [uiConfigs, setUiConfigs] = useState({
    hasIcon: false,
    success: false,
    hasSupportLink: false,
    hasLogin: false,
    hasErrorDetails: false
  })

  const [appContext, setAppContext] = useState({
    hasT01A: false,
    hasT01C: false
  })

  const [uiText, setUiText] = useState({
    header: '',
    subheader: ''
  })

  const [brandVersion, setBrandVersion] = useState(
    process.env.REACT_APP_BRAND_T02
  )

  /** ************* */
  /* UI Content */

  const getClientType = (appId): { hasT01A: boolean; hasT01C: boolean } => {
    let context = appContext
    switch (appId) {
      case process.env.REACT_APP_AUTH0_CLIENT_ID:
      case process.env.REACT_APP_AUTH0_CLIENT_ID_C01:
      case process.env.REACT_APP_AUTH0_CLIENT_ID_T01C:
      case process.env.REACT_APP_AUTH0_CLIENT_ID_T01:
        context = { ...context, hasT01C: true }
        break
      case process.env.REACT_APP_AUTH0_CLIENT_ID_T01A:
        context = { ...context, hasT01A: true }
        break
      default:
        context = { hasT01C: true, hasT01A: true }
        break
    }

    return context
  }

  const getBrandType = (appId): string => {
    let brand = process.env.REACT_APP_BRAND_T01A

    switch (appId) {
      case process.env.REACT_APP_AUTH0_CLIENT_ID:
        brand = process.env.REACT_APP_BRAND_DEFAULT
        break
      case process.env.REACT_APP_AUTH0_CLIENT_ID_T01C:
      case process.env.REACT_APP_AUTH0_CLIENT_ID_T01:
        brand = process.env.REACT_APP_BRAND_T01C
        break
      case process.env.REACT_APP_AUTH0_CLIENT_ID_C01:
        brand = process.env.REACT_APP_BRAND_C01
        break
      case process.env.REACT_APP_AUTH0_CLIENT_ID_T01A:
        brand = process.env.REACT_APP_BRAND_T01A
        break
      default:
        brand = process.env.REACT_APP_BRAND_T01A
        break
    }
    return brand as string
  }

  const location = useLocation()
  useEffect(() => {
    const query = new URLSearchParams(location.search)

    const clientId = query.get('client_id') || ''
    const clientType = getClientType(clientId)
    setAppContext({ ...clientType })

    const brand = getBrandType(clientId)
    setBrandVersion(brand)

    const errorCode = query.get('error') || ''

    const errorMessage =
      (query.get('error_description') as string) ||
      (query.get('message') as string)

    const defaultErr = 'generic_error'
    let errorType = defaultErr

    if (errorCode) {
      if (model.error_types[errorCode]) {
        errorType = errorCode
      }
    }
    setUiConfigs(configs.types[errorType])

    if (
      (clientType.hasT01A || clientType.hasT01C) &&
      clientType.hasT01A !== clientType.hasT01C
    ) {
      if (clientType.hasT01A && model.error_types[`${errorType}_T01A`]) {
        setUiText(model.error_types[`${errorType}_T01A`])
      } else if (clientType.hasT01C && model.error_types[`${errorType}_T01C`]) {
        setUiText(model.error_types[`${errorType}_T01C`])
      }
    } else {
      setUiText(model.error_types[errorType])
    }
    setLoading(false)

    Analytics.recordEvent(configs.analytics.viewed_event, null, 'MIXPANEL', {
      name: SCREEN_NAME
    })

    Analytics.recordEvent(
      configs.analytics.error_event.name,
      null,
      'MIXPANEL',
      {
        [configs.analytics.error_event.segment.code]: errorCode || null,
        [configs.analytics.error_event.segment.message]: errorMessage,
        [configs.analytics.error_event.segment.success]: false
      }
    )
  }, [])

  const renderTransferButtons = () => {
    const { hasLogin, hasSupportLink } = uiConfigs

    if (!hasLogin && !hasSupportLink) {
      return null
    }

    if (appContext.hasT01A && !appContext.hasT01C) {
      return (
        <div className={styles.contact}>
          {process.env.REACT_APP_ENDEAVOROTC_SUPPORT}
        </div>
      )
    }

    return (
      <TransferUserActionButtons
        context={screenId}
        displayLogin={hasLogin}
        displayAkiliAssist={appContext.hasT01C}
        displayOtcSupport={appContext.hasT01A}
        brandVersion={brandVersion}
      />
    )
  }

  const renderErrorDetails = () => {
    const { hasErrorDetails } = uiConfigs

    if (!hasErrorDetails) return null

    const query = new URLSearchParams(location.search)
    const errorCode = query.get('error') as string
    const errorMessage = query.get('error_description') as string

    if (!errorCode && !errorMessage) {
      return null
    }

    const { header, code, details } = model.error_details

    return (
      <div className={styles.errorDetails}>
        <div className={styles.title}>&#x1F41E; {header}</div>
        {errorCode && (
          <div className={styles.code}>{`${code} ${errorCode}`}</div>
        )}
        {errorMessage && <div>{`${details} ${errorMessage}`}</div>}
      </div>
    )
  }

  const renderBody = () => {
    const brandingColor =
      process.env[`REACT_APP_BRAND_COLOR_${brandVersion?.toUpperCase()}`]

    return (
      <div className={styles.body}>
        <div className={styles.failContentContainer}>
          <div className={styles.failContent}>
            {uiConfigs.hasIcon && (
              <div className={styles.icon_container}>
                <Iconography
                  width={64}
                  height={64}
                  name="warning"
                  fill={brandingColor}
                />
              </div>
            )}
            <div className={styles.header}>{uiText.header}</div>
            <div className={styles.subheader}>{uiText.subheader}</div>
            {renderTransferButtons()}
          </div>
          {renderErrorDetails()}
        </div>
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter brandVersion={brandVersion}>
      <div className={styles.container}>
        {isLoading && <Loading fullScreen />}
        {!isLoading && renderBody()}
      </div>
    </Layout>
  )
}

export default InvalidAuthentication
