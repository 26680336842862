import React, { FC, useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import {
  Layout,
  Header,
  TextInput,
  ErrorBanner,
  FloatingButton
} from '@components'
import {
  validateFormField,
  useAuthToken,
  navigateToUrl,
  routes,
  Analytics
} from '@utils'
import {
  getCaregiverProfileAPI,
  registerCaregiverApi,
  updateCaregiverApi
} from '@apiRoutes'
import styles from './styles.module.scss'
import _model from './model.en.json'
import configs from './configs'

type Localization = {
  title: string
  description: string
  form: {
    [field: string]: {
      label: string
      placeholder: string
      error: string
    }
  }
}

type IdType = { profile_uuid: string }

type TextState = {
  value: string
  hasError: boolean
  error: string
}

type RequestData = {
  firstName: string
  lastName: string
  zipcode: string
  phone: string
}

const model = _model as Localization

type CustomProps = {
  handleInvalidUser: (err?: Error) => void
}

const SetupCaregiverProfile: FC<RouteComponentProps & CustomProps> = ({
  history,
  handleInvalidUser
}) => {
  const screenId = 'setup-caregiver-screen'
  const SCREEN_NAME = 'Caregiver Profile'

  /** ************* */
  /* General data handlers */

  const [isLoading, setLoading] = useState(false)
  const [screenError, setScreenError] = useState('' as string)

  useEffect(() => {
    if (screenError) {
      window.scrollTo(0, 0)
    }
  }, [screenError])

  /** ************* */
  /* Form data handlers */

  const clearError = (
    state: TextState,
    updateState: (state: TextState) => void
  ) => {
    const { hasError } = state

    if (!hasError) return

    updateState({ ...state, hasError: false, error: '' })
  }

  /** ************* */
  const [userExists, markUserAsExisting] = useState(false)

  /** ************* */
  const [firstName, setFirstName] = useState({
    value: '',
    hasError: false,
    error: ''
  } as TextState)

  const updateFirstName = (val: string): void => {
    setFirstName({ ...firstName, value: val })
  }

  const clearFirstNameError = (): void => {
    clearError(firstName, setFirstName)
  }

  /** ************* */
  const [lastName, setLastName] = useState({
    value: '',
    hasError: false,
    error: ''
  } as TextState)

  const updateLastName = (val: string): void => {
    setLastName({ ...lastName, value: val })
  }

  const clearLastNameError = (): void => {
    clearError(lastName, setLastName)
  }

  /** ************* */
  const [zipcode, setZipcode] = useState({
    value: '',
    hasError: false,
    error: ''
  } as TextState)

  const updateZipcode = (val: string): void => {
    setZipcode({ ...zipcode, value: val })
  }

  const clearZipcodeError = (): void => {
    clearError(zipcode, setZipcode)
  }

  /** ************* */
  /* Fetch existing data */

  const [{ dataFetching }, updateDataFetching] = useState({
    dataFetching: true
  })

  const { authToken, tokenPending } = useAuthToken()
  const { user } = useAuth0()

  const getCaregiverId = () => {
    if (!user) return ''
    return (user[process.env.REACT_APP_AUTH0_USER_KEY] as IdType)?.profile_uuid
  }

  /** ************* */
  /* Record the viewed event to mixpanel the first time */
  useEffect(() => {
    Analytics.recordEvent(configs.analytics.viewed, authToken, 'MIXPANEL', {
      name: SCREEN_NAME
    })
  }, [])

  useEffect(() => {
    if (tokenPending || !authToken || !user || !dataFetching) {
      return
    }

    const fetchData = async () => {
      const id = getCaregiverId()
      const getCaregiverResponse = await getCaregiverProfileAPI(authToken, {
        id
      }).catch((e) => handleInvalidUser(e))

      if (!getCaregiverResponse) {
        return
      }

      if (!getCaregiverResponse.success) {
        // fetch api returns 404 during first time user experience
        if (getCaregiverResponse.status !== 404) {
          setScreenError(getCaregiverResponse.localizedErrorMessage)
        }

        updateDataFetching({ dataFetching: false })
        return
      }

      const caregiverData = getCaregiverResponse.responsePayload
      if (!caregiverData) {
        updateDataFetching({ dataFetching: false })
        return
      }

      markUserAsExisting(true)
      updateFirstName(caregiverData.givenName || '')
      updateLastName(caregiverData.familyName || '')
      updateZipcode(caregiverData.zipCode || '')

      updateDataFetching({ dataFetching: false })
    }
    fetchData()
  }, [tokenPending, authToken, user, dataFetching])

  /** ************* */
  /* Form authentication handlers */

  let validForm = true

  const verifyFormField = (
    name: string,
    state: TextState,
    updateState: (state: TextState) => void
  ): void => {
    const hasValidationError = validateFormField(name, configs, state)
    if (!hasValidationError) return

    validForm = false
    const {
      form: {
        [name]: { error }
      }
    } = model

    const newState = { ...state, hasError: true }
    if (error) {
      newState.error = error
    }
    updateState(newState)
  }

  const validateForm = () => {
    validForm = true
    const fields = [
      {
        name: 'firstName',
        state: firstName,
        updateState: setFirstName
      },
      {
        name: 'lastName',
        state: lastName,
        updateState: setLastName
      },
      {
        name: 'zipcode',
        state: zipcode,
        updateState: setZipcode
      }
    ]

    fields.forEach(({ name, state, updateState }) => {
      verifyFormField(name, state, updateState)
    })
  }

  const submitProfile = async () => {
    const data = {
      firstName: firstName.value,
      lastName: lastName.value,
      zipcode: zipcode.value,
      phone: ''
    } as RequestData

    let response
    if (userExists) {
      const caregiverId = getCaregiverId()
      response = await updateCaregiverApi(
        authToken as string,
        caregiverId,
        data
      ).catch((e) => handleInvalidUser(e))
    } else {
      response = await registerCaregiverApi(authToken as string, data).catch(
        (e) => handleInvalidUser(e)
      )
    }

    if (!response) {
      return
    }

    if (!response.success) {
      setScreenError(response.localizedErrorMessage)
      setLoading(false)
      return
    }

    Analytics.recordEvent(configs.analytics.submitted, authToken, 'MIXPANEL')
    navigateToUrl(history, routes.smsOptIn)
  }

  const handleSubmit = () => {
    if (isLoading || !authToken) {
      return
    }

    setScreenError('')
    setLoading(true)

    validateForm()

    if (!validForm) {
      window.scrollTo(0, 0)
      setLoading(false)
      return
    }

    submitProfile()
  }

  /** ************* */
  /* UI Content */

  const renderErrorBanner = () => {
    if (!screenError) {
      return null
    }

    return (
      <div className={styles.errorContainer}>
        <ErrorBanner message={screenError} automationId="BlackBoxMessage" />
      </div>
    )
  }

  const renderForm = () => {
    const {
      form: {
        firstName: { label: firstNameLabel, placeholder: firstNamePlaceholder },
        lastName: { label: lastNameLabel, placeholder: lastNamePlaceholder },
        zipcode: { label: zipcodeLabel, placeholder: zipcodePlaceholder }
      }
    } = model
    const {
      form: {
        firstName: { maxLength: firstNameMaxLength },
        lastName: { maxLength: lastNameMaxLength }
      }
    } = configs

    return (
      <div className={styles.form}>
        <Form>
          <Form.Group widths="equal">
            <TextInput
              automationFieldId="FirstNameField"
              automationLabelId="FirstNameLabel"
              automationIconId="FirstNameIcon"
              automationValidationId="FirstNameValidation"
              context="firstName"
              type="alphanumeric"
              label={firstNameLabel}
              placeholder={firstNamePlaceholder}
              maxLength={firstNameMaxLength}
              value={firstName.value}
              handleChange={updateFirstName}
              error={firstName.hasError}
              errorMessage={firstName.error}
              onFocus={clearFirstNameError}
            />
            <TextInput
              automationFieldId="LastNameField"
              automationLabelId="LastNameLabel"
              automationIconId="LastNameIcon"
              automationValidationId="LastNameValidation"
              context="lastName"
              type="alphanumeric"
              label={lastNameLabel}
              placeholder={lastNamePlaceholder}
              maxLength={lastNameMaxLength}
              value={lastName.value}
              handleChange={updateLastName}
              error={lastName.hasError}
              errorMessage={lastName.error}
              onFocus={clearLastNameError}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <TextInput
              automationFieldId="ZipCodeField"
              automationLabelId="ZipCodeLabel"
              automationIconId="ZipCodeIcon"
              automationValidationId="ZipCodeValidation"
              context="zipcode"
              type="zipcode"
              label={zipcodeLabel}
              placeholder={zipcodePlaceholder}
              value={zipcode.value}
              handleChange={updateZipcode}
              error={zipcode.hasError}
              errorMessage={zipcode.error}
              onFocus={clearZipcodeError}
            />
          </Form.Group>
        </Form>
      </div>
    )
  }

  const renderSubmitContent = () => {
    return (
      <div className={styles.buttonContainer}>
        <FloatingButton
          automationButtonId="ArrowButton"
          context="submit"
          onClick={handleSubmit}
          disabled={!authToken || dataFetching}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <Layout context={screenId} hasFooter>
      <div className={styles.container}>
        <Header
          title={model.title}
          description={model.description}
          automationTitleId="CaregiverProfileTitle"
          automationLegendId="CaregiverProfileLegend"
        />
        {renderErrorBanner()}
        {renderForm()}
        {renderSubmitContent()}
      </div>
    </Layout>
  )
}

export default SetupCaregiverProfile
